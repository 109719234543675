import styles from './Table.module.css'
// { title, edit, columns, children }
export function Table(props) {
  function Edit() {
    if (props?.edit) {
      return <th style={{ width: 10 }}>Ação</th>
    }
    return null
  }
  return (
    <table className={styles.table}>
      <thead>
        {props?.title ? (
          <tr>
            <th
              colSpan={
                props?.edit ? props?.columns.length + 1 : props?.columns.length
              }
            >
              {props?.title}
            </th>
          </tr>
        ) : null}
        <tr>
          <Edit />
          {props?.columns.map((values, index) => (
            <th
              className={values.hiden ? styles.hiden : null}
              key={index}
              style={values.align ? { textAlign: values.align } : null}
            >
              {values?.column}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{props?.children}</tbody>
    </table>
  )
}
