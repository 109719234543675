import styles from './Locais.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import Button from '../../../../components/components-ui/Button/Button'
import Modal from '../../../../components/Modal'
import { Grid, Col } from '../../../../components/Grid'
import { Table } from '../../../../components/Table'
import { InputMasked } from '../../../../components/components-ui/InputMask/InputMask'
import api from '../../../../services/Api'
import { ModalBrinde } from './Modal'
import * as Yup from 'yup'
import axios from 'axios'
import { Formik, Form, Field } from 'formik'
import 'react-toastify/dist/ReactToastify.css'
import { useHistory } from 'react-router'
import { ToastContainer, toast } from 'react-toastify'
import { useEffect, useState, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import 'react-alert-confirm/dist/index.css'
import popup from 'react-alert-confirm'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
//
import Coletas from './Tabs/Coletas'
import Ligacao from './Tabs/Ligacao'
import Relacionamento from './Tabs/Relacionamento'
import Materiais from './Tabs/Materiais'
//
export default function PainelLocal() {
  const { id } = useParams()
  const [dadosLocal, setDadosLocal] = useState([])
  const [cidade, setCidade] = useState([])
  const [grupo, setGrupo] = useState([])
  const [campanha, setCampanha] = useState([])
  const [estabelecimento, setEstabelecimento] = useState([])
  const [status, setStatus] = useState([])
  const [preferenciaContato, setPreferenciaContato] = useState([])
  const [tipoLogradouro, setTipoLogradouro] = useState([])
  const [entidade, setEntidade] = useState([])
  // Dados relacionado ao modal
  const [openModalBrinde, setOpenModalBrinde] = useState(false)
  const [dadosModalBrinde, setDadosModalBrinde] = useState([])
  const [dadosModalBrindeAPI, setDadosModalBrindeAPI] = useState([])
  const nomeLocalRef = useRef()
  const history = useHistory()
  // LINKS
  const API_DADOS_LOCAL = `/local/show/`
  const API_CIDADES = `/cidade/index`
  const API_GRUPOS = `/grupo/index`
  const API_CAMPANHAS = `/campanha/index`
  const API_ESTABELECIMENTO = `/estabelecimento/index`
  const API_STATUS = `/local/status/index`
  const API_LISTA_PREFERENCIA_CONTATO = `/preferenciacontato/index`
  const API_LOGRADOURO = `/logradourotipo/index`
  const API_LISTA_ENTIDADE = `/entidademtr`
  // ALERT PERSONALIZADO
  function showAlert(notify, msg) {
    if (notify === 0) {
      toast.success(msg)
    } else {
      toast.error(msg)
    }
  }
  // CARREGA LISTA DE BRINDES DE ACORDO COM O USUARIO
  async function getListaBrinde() {
    const response = await api.get(`/local/show/${id}`)
    return setDadosModalBrindeAPI(response.data.brinde)
  }
  // Renderiza dados do formulário
  useEffect(() => {
    if (id) {
      async function getApiDadosLocal() {
        const response = await api.get(`${API_DADOS_LOCAL}${id}`)
        setDadosLocal(response.data.local)
      }
      getApiDadosLocal()
    }
    // Carregar Cidades
    async function getCidade() {
      const response = await api.get(API_CIDADES)
      setCidade(response.data)
      return
    }
    getCidade()
    // Carregar Grupos
    async function getGrupo() {
      const response = await api.get(API_GRUPOS)
      setGrupo(response.data)
      return
    }
    getGrupo()
    // Carregar Campanhas
    async function getCampanha() {
      const response = await api.get(API_CAMPANHAS)
      setCampanha(response.data)
      return
    }
    getCampanha()
    // Carregar Estabelecimento
    async function getEstabelecimento() {
      const response = await api.get(API_ESTABELECIMENTO)
      setEstabelecimento(response.data)
      return
    }
    getEstabelecimento()

    // Carregar Status
    async function getStatus() {
      const response = await api.get(API_STATUS)
      setStatus(response.data)
      return
    }
    getStatus()
    // Carregar Preferência Contato
    async function getPreferenciaContato() {
      const response = await api.get(API_LISTA_PREFERENCIA_CONTATO)
      setPreferenciaContato(response.data)
      return
    }
    getPreferenciaContato()
    // Carregar Tipo Logradouro
    async function getTipoLogradouro() {
      const response = await api.get(API_LOGRADOURO)
      setTipoLogradouro(response.data)
      return
    }
    getTipoLogradouro(API_LOGRADOURO)
    // Carrega brindes
    getListaBrinde()
    //
    async function getEntidade() {
      const response = await api.get(API_LISTA_ENTIDADE)
      return setEntidade(response.data)
    }
    getEntidade()
    // nomeLocalRef.current.focus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // EDIÇÃO DE LOCAL
  async function editarLocal(values) {
    const dadosFormulario = {
      id_local: values.idLocal,
      nome_local: values.nomeLocal,
      cnpj_cpf: values.cnpjCpf,
      id_status: values.status,
      cep: values.cep,
      id_tipo_logradouro: values.tipoLogradouro,
      logradouro: values.logradouro,
      numero: values.numero,
      complemento: values.complemento,
      bairro: values.bairro,
      id_cidade: values.cidade,
      uf: values.uf,
      id_campanha: values.campanha,
      data_positivacao: values.dataPositivacao,
      bombas: values.bombas,
      id_grupo: values.grupo,
      id_captacao: values.captacao,
      id_estabelecimento: values.tEstabelecimento,
      id_preferencia_contato: values.preferenciaContato,
      Email: values.email,
      data_cadastro: values.dataCadastro,
      nome_contato: values.nomeContato,
      telefones: values.telefone,
      data_inativacao: values.dataInativacao,
      observacao: values.observacao,
      brinde: values.observacao,
    }
    const brinde = {
      ...dadosModalBrindeAPI,
    }
    const data = {
      dadosFormulario: dadosFormulario,
      dadosBrinde: brinde,
    }
    showAlert(0, 'Local Editado')
    const response = await api.put(`/local/edit/${id}`, data)
    if (response.data.error === 0) {
      showAlert(0, response.data.msg)
      return
    }
    showAlert(1, response.data.msg)
  }
  // CRIAR NOVO LOCAL
  async function criarLocal(values) {
    console.log(values)
    const dadosFormulario = {
      id_local: values.idLocal,
      nome_local: values.nomeLocal,
      cnpj_cpf: values.cnpjCpf,
      id_status: values.status,
      cep: values.cep,
      id_tipo_logradouro: values.tipoLogradouro,
      logradouro: values.logradouro,
      numero: values.numero,
      complemento: values.complemento,
      bairro: values.bairro,
      id_cidade: values.cidade,
      uf: values.uf,
      id_campanha: values.campanha,
      data_positivacao: values.dataPositivacao,
      bombas: values.bombas,
      id_grupo: values.grupo,
      id_captacao: values.captacao,
      id_estabelecimento: values.tEstabelecimento,
      id_preferencia_contato: values.preferenciaContato,
      email: values.email,
      data_cadastro: values.dataCadastro,
      nome_contato: values.nomeContato,
      telefones: values.telefone,
      data_inativacao: values.dataInativacao,
      observacao: values.observacao,
      brinde: values.observacao,
    }
    const data = {
      dadosFormulario: dadosFormulario,
      dadosBrinde: dadosModalBrinde,
    }
    // console.log(dadosModalBrinde)
    console.log(data)
    showAlert(0, 'Local Criado')
    const response = await api.put(`/campanha/edit/${id}`, {
      data,
    })
    if (response.data.error === 0) {
      return showAlert(0, response.data.msg)
    }
    showAlert(1, response.data.msg)
  }
  // PREENCHIMENTO AUTOMATICO DE ENDERECO
  async function handleCep(ev, setFieldValue) {
    setFieldValue('cep', ev.value)
    const value = ev.value.replace(/[^0-9]/g, '')
    if (value.length >= 8) {
      const response = await axios.get(
        `https://viacep.com.br/ws/${value}/json/`
      )
      setFieldValue('uf', response.data.uf)
      setFieldValue('cidade', response.data.localidade)
      setFieldValue('bairro', response.data.bairro)
      setFieldValue('logradouro', response.data.logradouro)
      return
    }
  }
  const columnsBrinde = [
    {
      column: 'Nome Brinde',
    },
  ]
  // Inserção de brinde Local
  function adicionaBrindeLocalmente(values) {
    for (let i = 0; i < dadosModalBrinde.length; i++) {
      if (dadosModalBrinde[i].brinde === values.brinde) {
        //  window.alert('Item já adicionado')
        return popup.alert({
          title: 'Brinde já está na cadastrado para esse local.',
          footer(dispatch) {
            return <Button onClick={() => dispatch('ok')}>Fechar</Button>
          },
        })
      }
    }
    popup.alert({
      title: 'Brinde cadastrado com sucesso.',
      footer(dispatch) {
        return <Button onClick={() => dispatch('ok')}>Fechar</Button>
      },
    })
    setDadosModalBrinde([...dadosModalBrinde, values])
  }
  // Deleção de brinde Local
  async function deletarBrindeLocal(id_brinde, arr) {
    let response = popup.confirm({
      title: 'Tem certeza que deseja excluir esse brinde ?',
      footer(dispatch) {
        return (
          <>
            <Button onClick={() => dispatch('ok')}>Confirmar</Button>
            <Button onClick={() => dispatch('no')} color="secondary">
              Cancelar
            </Button>
          </>
        )
      },
    })
    let res = await response
    // let response = window.confirm(
    //   'Tem certeza que deseja excluir esse brinde ?'
    // )
    if (res[0]) {
      arr.forEach((item, index) => {
        if (index === id_brinde) {
          arr.splice(id_brinde, 1)
          popup.alert({
            title: 'Brinde excluído com sucesso.',
            footer(dispatch) {
              return <Button onClick={() => dispatch('ok')}>Fechar</Button>
            },
          })
          setDadosModalBrinde([...dadosModalBrinde])
        }
      })
    }
    return
  }
  // inserção de brinde na api
  async function adicionaBrindeAPI(values) {
    const response = await api.post('/local/edit/brinde/create', values)
    // setUpdateTableBrinde(!updateTableBrinde)
    getListaBrinde()
    popup.alert({
      title: response.data.status_msg,
      footer(dispatch) {
        return <Button onClick={() => dispatch('ok')}>Fechar</Button>
      },
    })
    // window.alert(response.data.status_msg)
  }
  // Deleção direto na api
  async function deletarBrindeApi(id_local_brinde) {
    let response = popup.confirm({
      title: 'Tem certeza que deseja excluir esse brinde ?',
      footer(dispatch) {
        return (
          <>
            <Button onClick={() => dispatch('ok')}>Confirmar</Button>
            <Button onClick={() => dispatch('no')} color="secondary">
              Cancelar
            </Button>
          </>
        )
      },
    })
    let res = await response

    // let response = window.confirm('Tem certeza que deseja excluir esse brinde')
    if (res[0]) {
      const data = { id_local: id }
      const response = await api.delete(
        `/local/edit/brinde/delete/${id_local_brinde}`,
        { data }
      )

      // window.alert(response.data.status_msg)
      popup.alert({
        title: response.data.status_msg,
        footer(dispatch) {
          return <Button onClick={() => dispatch('ok')}>Fechar</Button>
        },
      })
      getListaBrinde()
      // setUpdateTableBrinde(!updateTableBrinde)
      return
    }
  }
  const ValidationSchema = Yup.object().shape({
    idLocal: Yup.string(),
    nomeLocal: Yup.string().nullable(),
    status: Yup.string().nullable(),
    cep: Yup.string().nullable(),
    tipoLogradouro: Yup.string().nullable(),
    logradouro: Yup.string().nullable(),
    numero: Yup.number().positive('*Campo Obrigatório').nullable(),
    complemento: Yup.string().nullable(),
    bairro: Yup.string().nullable(),
    cidade: Yup.string().nullable(),
    uf: Yup.string().nullable(),
    campanha: Yup.string().nullable(),
    dataPositivacao: Yup.string().nullable(),
    bombas: Yup.string().nullable(),
    grupo: Yup.string().nullable(),
    captacao: Yup.string().nullable(),
    tEstabelecimento: Yup.string().nullable(),
    preferenciaContato: Yup.string().nullable(),
    email: Yup.string().nullable(),
    dataCadastro: Yup.string().nullable(),
    nomeContato: Yup.string().nullable(),
    telefone: Yup.string().nullable(),
    dataInativacao: Yup.date().nullable(),
    observacao: Yup.string().nullable(),
    emiteMTR: Yup.bool(),
    entidadeMTR: Yup.string(),
    login: Yup.string(),
    senha: Yup.string(),
    unidade: Yup.string(),
  })
  return (
    <Container>
      <ToastContainer />
      <h3> {id ? 'Edição de Local' : 'Novo Local'} </h3>
      <section className={styles.content}>
        <Formik
          initialValues={{
            idLocal: id ? dadosLocal?.id_local : 'Novo',
            nomeLocal: dadosLocal?.nome_local || '',
            cnpjCpf: dadosLocal?.cnpj_cpf || '',
            status: dadosLocal?.id_status || '',
            cep: dadosLocal?.cep || '',
            tipoLogradouro: dadosLocal?.id_tipo_logradouro || '',
            logradouro: dadosLocal?.logradouro || '',
            numero: dadosLocal?.numero || '',
            complemento: dadosLocal?.complemento || '',
            bairro: dadosLocal?.bairro || '',
            cidade: dadosLocal?.id_cidade || '',
            uf: dadosLocal?.uf || '',
            campanha: dadosLocal?.id_campanha || '',
            dataPositivacao: dadosLocal?.data_positivacao || '',
            bombas: dadosLocal?.bombas || '',
            grupo: dadosLocal?.id_grupo || '',
            captacao: dadosLocal?.id_captacao || '',
            tEstabelecimento: dadosLocal?.id_estabelecimento || '',
            preferenciaContato: dadosLocal?.id_preferencia_contato || '',
            email: dadosLocal?.email || '',
            dataCadastro: dadosLocal?.data_cadastro || '',
            nomeContato: dadosLocal?.nome_contato || '',
            telefone: dadosLocal?.telefones || '',
            dataInativacao: dadosLocal?.data_inativacao || '',
            observacao: dadosLocal?.observacao || '',
            emitemtr: dadosLocal?.emitemtr || '',
            entidadeMTR: '',
            login: '',
            senha: '',
            unidade: '',
          }}
          enableReinitialize={true}
          validationSchema={ValidationSchema}
          onSubmit={(values) => (id ? editarLocal(values) : criarLocal(values))}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form>
              <Tabs forceRenderTabPanel={true}>
                <TabList>
                  <Tab>Dados do Local</Tab>
                  <Tab>Contrapartida | MTR</Tab>
                  <Tab>Ligação</Tab>
                  <Tab>Relacionamento</Tab>
                  <Tab>Materiais</Tab>
                  <Tab>Coletas</Tab>
                </TabList>
                <TabPanel>
                  <Grid>
                    <Col md={2}>
                      <label> ID Local </label>
                      <Field type="text" name="idLocal" disabled />
                      {errors.idLocal && touched.idLocal ? (
                        <span className={styles.alertError}>
                          {errors.idLocal}
                        </span>
                      ) : null}
                    </Col>

                    <Col md={4}>
                      <label> Nome Local </label>
                      <Field name="nomeLocal">
                        {({ field }) => (
                          <input type="text" ref={nomeLocalRef} {...field} />
                        )}
                      </Field>
                      {errors.nomeLocal && touched.nomeLocal ? (
                        <span className={styles.alertError}>
                          {errors.nomeLocal}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={4}>
                      <label> CNPJ </label>
                      <Field name="cnpjCpf">
                        {({ field }) => (
                          <InputMasked {...field} mask="99.999.999/9999-99" />
                        )}
                      </Field>
                      {errors.cnpjCpf && touched.cnpjCpf ? (
                        <span className={styles.alertError}>
                          {errors.cnpjCpf}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={2}>
                      <label> Status </label>
                      <Field component="select" name="status">
                        <option value="">Selecione</option>
                        {status.map((values) => (
                          <option
                            key={values?.id_status}
                            value={values?.id_status}
                          >
                            {values?.status}
                          </option>
                        ))}
                      </Field>
                      {errors.status && touched.status ? (
                        <span className={styles.alertError}>
                          {errors.status}
                        </span>
                      ) : null}
                    </Col>
                  </Grid>

                  <Grid>
                    <Col md={2}>
                      <label> CEP </label>
                      <Field name="cep">
                        {({ field }) => (
                          <InputMasked
                            {...field}
                            mask="99999-999"
                            onChange={(ev) =>
                              handleCep(ev.target, setFieldValue)
                            }
                          />
                        )}
                      </Field>
                      {/* <Field name="cep"/> */}
                      {errors.cep && touched.cep ? (
                        <span className={styles.alertError}>{errors.cep}</span>
                      ) : null}
                    </Col>
                    <Col md={3}>
                      <label> Tipo </label>
                      <Field component="select" name="tipoLogradouro">
                        <option value="">Selecione</option>
                        {tipoLogradouro.map((values) => (
                          <option
                            key={values?.id_tipo_logradouro}
                            value={values?.id_tipo_logradouro}
                          >
                            {values?.tipo_logradouro}
                          </option>
                        ))}
                      </Field>
                      {errors.tipoLogradouro && touched.tipoLogradouro ? (
                        <span className={styles.alertError}>
                          {errors.tipoLogradouro}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={6}>
                      <label> Logradouro </label>
                      <Field type="text" name="logradouro" />
                      {errors.logradouro && touched.logradouro ? (
                        <span className={styles.alertError}>
                          {errors.logradouro}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={1}>
                      <label> Número </label>
                      <Field type="number" name="numero" />
                      {errors.numero && touched.numero ? (
                        <span className={styles.alertError}>
                          {errors.numero}
                        </span>
                      ) : null}
                    </Col>
                  </Grid>

                  <Grid>
                    <Col md={3}>
                      <label> Complemento </label>
                      <Field type="text" name="complemento" />
                      {errors.complemento && touched.complemento ? (
                        <span className={styles.alertError}>
                          {errors.complemento}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={3}>
                      <label> Bairro </label>
                      <Field type="text" name="bairro" />
                      {errors.bairro && touched.bairro ? (
                        <span className={styles.alertError}>
                          {errors.bairro}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={5}>
                      <label> Cidade </label>
                      <Field component="select" type="text" name="cidade">
                        <option value="">Selecione</option>
                        {cidade.map((values) => (
                          <option
                            key={values?.id_cidade}
                            value={values?.cidade}
                          >
                            {values?.cidade}
                          </option>
                        ))}
                      </Field>
                      {errors.cidade && touched.cidade ? (
                        <span className={styles.alertError}>
                          {errors.cidade}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={1}>
                      <label> UF </label>
                      <Field type="text" name="uf" />
                      {errors.uf && touched.uf ? (
                        <span className={styles.alertError}>{errors.uf}</span>
                      ) : null}
                    </Col>
                  </Grid>
                  <Grid>
                    <Col md={3}>
                      <label> Campanha </label>
                      <Field component="select" name="campanha">
                        <option value="">Selecione</option>
                        {campanha.map((values) => (
                          <option key={values?.id} value={values?.id_campanha}>
                            {values?.campanha}
                          </option>
                        ))}
                      </Field>
                      {errors.campanha && touched.campanha ? (
                        <span className={styles.alertError}>
                          {errors.campanha}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={3}>
                      <label> Bombas </label>
                      <Field type="text" name="bombas" />
                      {errors.bombas && touched.bombas ? (
                        <span className={styles.alertError}>
                          {errors.bombas}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={3}>
                      <label> Grupo </label>
                      <Field component="select" name="grupo">
                        <option value="">Selecione</option>
                        {grupo.map((values) => (
                          <option key={values?.id} value={values?.id_grupo}>
                            {values?.descricao_grupo}
                          </option>
                        ))}
                      </Field>
                      {errors.grupo && touched.grupo ? (
                        <span className={styles.alertError}>
                          {errors.grupo}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={3}>
                      <label> Captação </label>
                      <Field component="select" name="captacao">
                        <option value=""> Selecione </option>
                        <option value="1"> Captação 1 </option>
                      </Field>
                      {errors.captacao && touched.captacao ? (
                        <span className={styles.alertError}>
                          {errors.captacao}
                        </span>
                      ) : null}
                    </Col>
                  </Grid>
                  <Grid>
                    <Col md={2}>
                      <label> Data Positivação </label>
                      <Field type="date" name="dataPositivacao" />
                      {errors.dataPositivacao && touched.dataPositivacao ? (
                        <span className={styles.alertError}>
                          {errors.dataPositivacao}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={4}>
                      <label> Tipo Estabelecimento </label>
                      <Field component="select" name="tEstabelecimento">
                        <option value="">Selecione</option>
                        {estabelecimento.map((values) => (
                          <option
                            key={values?.id_estabelecimento}
                            value={values?.id_estabelecimento}
                          >
                            {values?.estabelecimento}
                          </option>
                        ))}
                      </Field>
                      {errors.tEstabelecimento && touched.tEstabelecimento ? (
                        <span className={styles.alertError}>
                          {errors.tEstabelecimento}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={2}>
                      <label> Preferência de contato </label>

                      <Field component="select" name="preferenciaContato">
                        <option value="">Selecione</option>
                        {preferenciaContato.map((values) => (
                          <option
                            key={values?.id_preferencia_contato}
                            value={values?.id_preferencia_contato}
                          >
                            {values?.preferencia_contato}
                          </option>
                        ))}
                      </Field>
                      {errors.preferenciaContato &&
                      touched.preferenciaContato ? (
                        <span className={styles.alertError}>
                          {errors.preferenciaContato}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={4}>
                      <label> E-mail </label>
                      <Field type="email" name="email" />
                      {errors.email && touched.email ? (
                        <span className={styles.alertError}>
                          {errors.email}
                        </span>
                      ) : null}
                    </Col>
                  </Grid>
                  <Grid>
                    <Col md={2}>
                      <label> Data de cadastro </label>
                      <Field type="date" name="dataCadastro" />
                      {errors.dataCadastro && touched.dataCadastro ? (
                        <span className={styles.alertError}>
                          {errors.dataCadastro}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={4}>
                      <label> Nome contato </label>
                      <Field type="text" name="nomeContato" />
                      {errors.nomeContato && touched.nomeContato ? (
                        <span className={styles.alertError}>
                          {errors.nomeContato}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={4}>
                      <label> Telefone </label>
                      <Field name="telefone">
                        {({ field }) => (
                          <InputMasked {...field} mask="99999-9999" />
                        )}
                      </Field>
                      {errors.telefone && touched.telefone ? (
                        <span className={styles.alertError}>
                          {errors.telefone}
                        </span>
                      ) : null}
                    </Col>
                    {/* <Col md={2}>
                  <label> Data Inativação </label>
                  <Field type="date" name="dataInativacao" disabled />
                  {errors.dataInativacao && touched.dataInativacao ? (
                    <span className={styles.alertError}>
                      {errors.dataInativacao}
                    </span>
                  ) : null}
                </Col> */}

                    {Number(values.status) === 4 ? (
                      <Col md={2}>
                        <label> Data Inativação </label>
                        <Field type="date" name="dataInativacao" disabled />
                        {errors.dataInativacao && touched.dataInativacao ? (
                          <span className={styles.alertError}>
                            {errors.dataInativacao}
                          </span>
                        ) : null}
                      </Col>
                    ) : null}
                  </Grid>
                  <Grid>
                    <Col md={6}>
                      <Table edit columns={columnsBrinde} title="Brindes">
                        {id
                          ? dadosModalBrindeAPI.map((values) => (
                              <tr key={values?.id_local_brinde}>
                                <td>
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      deletarBrindeApi(values?.id_local_brinde)
                                    }
                                  >
                                    <i class="fas fa-trash-alt"></i>
                                  </Link>
                                </td>
                                <td> {values?.brinde} </td>
                              </tr>
                            ))
                          : dadosModalBrinde.map((values, index) => (
                              <tr key={index}>
                                <td>
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      deletarBrindeLocal(
                                        index,
                                        dadosModalBrinde
                                      )
                                    }
                                  >
                                    <i class="fas fa-trash-alt"></i>
                                  </Link>
                                </td>
                                <td> {values?.brinde} </td>
                              </tr>
                            ))}
                      </Table>

                      <Button
                        type="button"
                        title="Novo Brinde"
                        onClick={() => setOpenModalBrinde(true)}
                      />
                    </Col>
                    <Col md={6}>
                      <label> Observação </label>
                      <Field component="textarea" name="observacao"></Field>
                      {errors.observacao && touched.observacao ? (
                        <span className={styles.alertError}>
                          {errors.observacao}
                        </span>
                      ) : null}
                    </Col>
                  </Grid>

                  {/*  
                <table>
                  <thead>
                    <tr>
                      <th colSpan="6"> Contato </th>
                    </tr>
                    <tr>
                      <th style={{width: 10}}> Ação </th>
                      <th> Nome contato </th>
                      <th style={{width: 10}}> DDD </th>
                      <th> Telefone </th>
                      <th> Tipo </th>
                      <th> Operadora </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <Link to="#">
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                        </span>{' '}
                        <br />
                        <span>
                          <Link to="#">
                            <i className="fas fa-trash-alt"></i>
                          </Link>
                        </span>
                      </td>
                      <td>Augusto</td>
                      <td>+11</td>
                      <td>1111-2222</td>
                      <td>1</td>
                      <td>Vivo</td>
                    </tr>
                  </tbody>
                </table>
              */}
                </TabPanel>
                <TabPanel>
                  <Grid>
                    <Col md={2}>
                      <label> Emite MTR </label>
                      <Field name="emitemtr" component="select">
                        <option value="0">NÃO</option>
                        <option value="1">SIM</option>
                      </Field>
                      {errors.emitemtr && touched.emitemtr ? (
                        <span className={styles.alertError}>
                          {errors.emitemtr}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={2}>
                      <label> Entidade MTR </label>
                      <Field name="entidademtr" component="select">
                        {entidade.map((values) => (
                          <option value={values?.id_entidade_mtr}>
                            {values?.nome_entidade_mtr}
                          </option>
                        ))}
                      </Field>
                      {errors.entidademtr && touched.entidademtr ? (
                        <span className={styles.alertError}>
                          {errors.entidademtr}
                        </span>
                      ) : null}
                    </Col>

                    <Col md={2}>
                      <label> Login </label>
                      <Field type="text" name="login" />
                      {errors.login && touched.login ? (
                        <span className={styles.alertError}>
                          {errors.login}
                        </span>
                      ) : null}
                    </Col>

                    <Col md={2}>
                      <label> Senha </label>
                      <Field type="password" name="senha" />
                      {errors.senha && touched.senha ? (
                        <span className={styles.alertError}>
                          {errors.senha}
                        </span>
                      ) : null}
                    </Col>

                    <Col md={2}>
                      <label> Unidade </label>
                      <Field type="text" name="unidade" />
                      {errors.unidade && touched.unidade ? (
                        <span className={styles.alertError}>
                          {errors.unidade}
                        </span>
                      ) : null}
                    </Col>
                  </Grid>
                </TabPanel>
                <TabPanel>
                  <Ligacao />
                </TabPanel>
                <TabPanel>
                  <Relacionamento />
                </TabPanel>
                <TabPanel>
                  <Materiais />
                </TabPanel>
                <TabPanel>
                  <Coletas />
                </TabPanel>
              </Tabs>
              <section className={styles.section_button}>
                <Button title="Salvar" type="submit" />
                <Button
                  color="secondary"
                  title="Voltar"
                  onClick={() => {
                    history.push('/usina/local')
                  }}
                  type="button"
                />
              </section>
            </Form>
          )}

          {/*  */}
        </Formik>
      </section>
      <Modal
        width="500px"
        height="200px"
        titleModal="Contrapartida"
        display={openModalBrinde}
        onClick={setOpenModalBrinde}
      >
        <ModalBrinde
          setOpenModalBrinde={setOpenModalBrinde}
          adicionaBrindeAPI={adicionaBrindeAPI}
          adicionaBrindeLocalmente={adicionaBrindeLocalmente}
        />
      </Modal>
    </Container>
  )
}
