import style from './DatePick.module.css'

export default function DatePicker(props) {
  return (
    <>
      <label htmlFor={props.id}>{props.label}</label>
      <input
        id={props.id}
        className={style.input}
        type="date"
        value={props.value}
        onChange={props.onChange}
      />
    </>
  )
}
