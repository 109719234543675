import styles from './RelatorioDeColeta.module.css'
import Button from '../../../components/components-ui/Button/Button'
import Container from '../../../components/components-ui/Container/Container'
import Loading from '../../../components/components-ui/Loading/Loading'
import DatePicker from '../../../components/components-ui/DatePicker/DatePicker'
import api from '../../../services/Api'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Link } from 'react-router-dom'
import { useState } from 'react'

export default function RelatorioDeColeta() {
  var valorPesoTotal = 0
  const [showTable, setShowTable] = useState(false)
  const [formDate, setFormDate] = useState(true)
  const [dataInicial, setDataInicial] = useState('')
  const [dataFinal, setDataFinal] = useState('')
  const [listRelatorio, setListRelatorio] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  // LINKS
  const API_RELATORIO_DE_COLETA = '/mob/coleta/relatoriocoleta'
  // ALERT PERSONALIZADO
  function showAlert(notify, msg) {
    if (notify === 0) {
      toast.success(msg)
    } else {
      toast.error(msg)
    }
  }
  // CARREGA LISTA DE RELATORIOS
  async function getListaRelatorio() {
    setShowLoading(true)
    if (dataInicial.length !== 0 && dataFinal.length !== 0) {
      const datas = { data_de: dataInicial, data_ate: dataFinal }
      const response = await api.post(API_RELATORIO_DE_COLETA, datas)
      if (response.status === 401) {
        showAlert(1, 'Ocorreu algum erro')
        setFormDate(true)
        setShowLoading(false)
      } else {
        const dadosRelatorio = await response.data
        setListRelatorio(dadosRelatorio)
      }
      setShowTable(true)
      setFormDate(false)
    } else {
      showAlert(1, 'Preencha todos os campos')
      setFormDate(true)
      setShowLoading(false)
    }
  }
  return (
    <Container>
      <ToastContainer />
      <h3> Relatórios de Coleta </h3>
      <section className={formDate ? styles.form : styles.hidden}>
        <div className={styles.content}>
          <section>
            <DatePicker
              id="dateStart"
              label="Data Inicial"
              value={dataInicial}
              onChange={(e) => setDataInicial(e.target.value)}
            />
          </section>
          <section>
            <DatePicker
              id="dateFinish"
              label="Data Final"
              value={dataFinal}
              onChange={(e) => setDataFinal(e.target.value)}
            />
          </section>
          <Loading display={showLoading ? 'block' : 'none'} />
          <section>
            <Button
              onClick={getListaRelatorio}
              display={showLoading ? 'none' : 'block'}
              title="Listar Relatórios"
              type="submit"
            />
          </section>
        </div>
      </section>
      <section className={showTable ? styles.form : styles.hidden}>
        {/* Mostrados Dinamicamente */}
        <Link
          to="#"
          onClick={() => {
            setFormDate(true)
            setShowTable(false)
            setShowLoading(false)
          }}
          className={showTable ? styles.backButton : styles.hidden}
        >
          <i className="fas fa-arrow-left"></i> Voltar
        </Link>

        <table className={styles.table}>
          <thead>
            <tr>
              <th>Nome Local</th>
              <th>Placa</th>
              <th>Qtd. Ligação</th>
              <th>Estimativa Motorista</th>
              <th>Peso Óleo</th>
              <th>Qtd. Resíduo</th>
              <th>Qtd. Impureza</th>
              <th>Peso Total</th>
            </tr>
          </thead>
          <tbody>
            {listRelatorio.map((values) => (
              <tr key={values?.id}>
                <td>{values?.nome_local}</td>
                <td>{values?.placa}</td>
                <td>{values?.quantidade}</td>
                <td>{values?.quantidade_estimada_motorista}</td>
                <td>{values?.quantidade_oleo}</td>
                <td>{values?.quantidade_banha}</td>
                <td>{values?.quantidade_agua}</td>
                <td>{values?.quantidade_total}</td>
                <td hidden>
                  {(valorPesoTotal = valorPesoTotal + values?.quantidade_total)}
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan="7" style={{ textAlign: 'right' }}>
                <strong> Valor Total : </strong>
              </td>
              <td className={styles.tdCenter}>
                <strong>{valorPesoTotal}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </Container>
  )
}
