import styles from './NovoRoteiro.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import Button from '../../../../components/components-ui/Button/Button'
import Modal from '../../../../components/Modal'
import { Table } from '../../../../components/Table'
import ModalEndereco from './Modal/ModalEndereco'
import ModalColeta from './Modal/ModalColeta'
import { useState } from 'react'

export default function NovoRoteiro() {
  const [openModalColeta, setOpenModalColeta] = useState(false)
  const [openModalEndereco, setOpenModalEndereco] = useState(false)
  const columnsListaColetas = [
    {
      column: 'Coleta',
    },
  ]

  return (
    <Container>
      <h3>Novo Roteiro</h3>

      <div className={styles.content}>
        <Table edit columns={columnsListaColetas}></Table>
        {/* <div className={styles.loading}>
          <Loading display={showLoading ? 'block' : 'none'} />
        </div> */}
        <div className={styles.section_button}>
          <Button
            title="Adicionar Coleta"
            onClick={() => setOpenModalColeta(true)}
          />

          <Button
            title="Adicionar Endereço"
            color="secondary"
            onClick={() => setOpenModalEndereco(true)}
          />
        </div>
        <div className={styles.section_button}>
          <Button title="Gerar Roteiro" />
        </div>
      </div>
      {/*  */}
      <Modal
        titleModal="Coletas"
        display={openModalColeta}
        onClick={setOpenModalColeta}
      >
        <ModalColeta />
      </Modal>
      <Modal
        width="900px"
        height="400px"
        titleModal="Endereço"
        display={openModalEndereco}
        onClick={setOpenModalEndereco}
      >
        <ModalEndereco />
      </Modal>
    </Container>
  )
}
