import styles from './SolicitacaoDeColeta.module.css'
import Container from '../../components/components-ui/Container/Container'
import Button from '../../components/components-ui/Button/Button'
import Loading from '../../components/components-ui/Loading/Loading'
import { Table } from '../../components/Table'
import Api from '../../services/Api'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function ListagemSolicitacao() {
  const [coleta, setColeta] = useState([])
  const [coletaSolicitacao, setColetaSolicitacao] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  // LINKS
  const API_SOLICITACAO_DE_COLETA = '/coletasolicitacao'
  const API_COLETAS = '/coleta'
  // CARREGA AS COLETAS
  useEffect(() => {
    // Primeira tabela
    async function getColetaSolicitacao() {
      setShowLoading(true)
      const response = await Api.get(API_SOLICITACAO_DE_COLETA)
      setColetaSolicitacao(response.data)
      setShowLoading(false)
    }
    getColetaSolicitacao()
    // Segunda Tabela
    async function getColeta() {
      setShowLoading(true)
      const response = await Api.get(API_COLETAS)
      setColeta(response.data)
      setShowLoading(false)
    }
    getColeta()
  }, [])
  // COLUNAS DAS TABELAS
  const columns_soli_coletas = [
    {
      column: '',
    },
    {
      column: 'Data Solicitação',
    },
    {
      column: 'Nome Solicitante',
      hiden: true,
    },
    {
      column: 'Est. Kg. Óleo',
      hiden: true,
    },
    {
      column: 'Status',
    },
  ]
  const columns_coletas = [
    {
      column: '',
    },
    {
      column: 'N° Coleta',
      hiden: true,
    },
    {
      column: 'Data entrada',
    },
    {
      column: 'Placa',
      hiden: true,
    },
    {
      column: 'Status',
    },
  ]
  return (
    <Container>
      <h3>Solicitação de Coleta</h3>
      <div className={styles.btnTopo}>
        <Link to="/home/nova-coleta">
          <Button title="Solicitar Coleta" type="button" />
        </Link>
      </div>
      <section className={styles.content}>
        {coletaSolicitacao.length > 0 ? (
          <Table title="Solicitação de Coletas" columns={columns_soli_coletas}>
            {coletaSolicitacao.map((values, index) => (
              <tr key={index}>
                <td>
                  <img src={values?.image_url} alt="Icon" />
                </td>
                <td>{values?.data_solicitacao}</td>
                <td className={styles.th_tr_Responsive}>
                  {values?.nome_solicitante}
                </td>
                <td className={styles.th_tr_Responsive}>
                  {values?.estimativa_oleo_kg}
                </td>
                <td>{values?.status}</td>
              </tr>
            ))}
          </Table>
        ) : null}

        <Table columns={columns_coletas}>
          {coleta.map((values) => (
            <tr key={values?.id}>
              <td>
                <img src={values?.image_url} alt="Icon" />
              </td>
              <td className={styles.th_tr_Responsive}>{values?.id_coleta}</td>
              <td>{values?.data_entrada}</td>
              <td className={styles.th_tr_Responsive}>{values?.placa}</td>
              <td>{values?.status}</td>
            </tr>
          ))}
        </Table>

        <Loading display={showLoading ? 'block' : 'none'} />
      </section>
    </Container>
  )
}
