import styles from '../../PainelDeLigacao.module.css'
import Button from '../../../../../../components/components-ui/Button/Button'
import {Grid, Col} from '../../../../../../components/Grid'
import {Formik, Form, Field} from 'formik'

export default function RelatarOcorrencia() {
  return (
    <div className={styles.content}>
      <h3>Relatar Ocorrência </h3>
      <Formik
        initialValues={{
          codigoPonto: '',
          razaoSocial: '',
          estabelecimento: '',
          status: 3,
          grupo: '',
        }}
        //validationSchema={ValidationSchema}
        onSubmit={(values) => console.log(values)}
      >
        {({errors, touched}) => (
          <Form>
            <Grid>
              <Col md={2}>
                <label>Data Ocorrência</label>
                <Field type="date" name="dataOcorrencia" />
                {errors.dataOcorrencia && touched.dataOcorrencia ? (
                  <span className={styles.alertError}>
                    {errors.dataOcorrencia}
                  </span>
                ) : null}
              </Col>
              <Col md={4}>
                <label>Código Local</label>
                <Field component="select" name="codigoLocal">
                  <option value="">Selecione</option>
                </Field>
                {errors.codigoLocal && touched.codigoLocal ? (
                  <span className={styles.alertError}>
                    {errors.codigoLocal}
                  </span>
                ) : null}
              </Col>
              <Col md={4}>
                <label>Nome Local</label>
                <Field type="text" name="nomeLocal" />
                {errors.nomeLocal && touched.nomeLocal ? (
                  <span className={styles.alertError}>{errors.nomeLocal}</span>
                ) : null}
              </Col>
              <Col md={2}>
                <label>Responsável</label>
                <Field component="select" name="responsavel">
                  <option value="">Selecione</option>
                </Field>
                {errors.responsavel && touched.responsavel ? (
                  <span className={styles.alertError}>
                    {errors.responsavel}
                  </span>
                ) : null}
              </Col>
            </Grid>
            <Grid>
              <Col md={12}>
                <label>Descrição</label>
                <Field component="textarea" name="descricao" />
                {errors.descricao && touched.descricao ? (
                  <span className={styles.alertError}>{errors.descricao}</span>
                ) : null}
              </Col>
            </Grid>
          </Form>
        )}
      </Formik>
      <div className={styles.button_section}>
        <Button title="Salvar" />
      </div>
    </div>
  )
}
