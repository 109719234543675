import styles from './ComprovanteDePagamento.module.css'
import Container from '../../../components/components-ui/Container/Container'
import UINumber from '../../../components/components-ui/UINumber/UINumber'
import Loading from '../../../components/components-ui/Loading/Loading'
import { Table } from '../../../components/Table'
import api from '../../../services/Api'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export default function ComprovanteDePagamento() {
  const [listLicenca, setListaLicenca] = useState([])
  const [showLoading, setShowLoading] = useState(false)

  var SomaValorRecebimento = 0
  // LINKS
  const API_COMPROVANTE_DE_PAGAMENTO = '/motorista/relatoriomotorista'
  // CARREGA OS COMPROVANTE DE PAGAMENTO
  useEffect(() => {
    async function getLicenses() {
      setShowLoading(true)
      const response = await api.get(API_COMPROVANTE_DE_PAGAMENTO)
      setListaLicenca(response.data)
      setShowLoading(false)
    }
    getLicenses()
  }, [])
  // COLUNAS DAS TABELAS
  const columns_comprovante_pagamento = [
    {
      column: 'Data Saída',
    },
    {
      column: 'Nº OC',
    },
    {
      column: 'Km Total',
    },
    {
      column: 'Peso Total',
    },
    {
      column: 'Total Coletas',
    },

    // {
    //   column: 'Data do Recibo',
    //   hiden: true,
    // },

    {
      column: 'Valor',
    },
  ]
  return (
    <Container>
      <h3>Relatório de Conferência</h3>
      <p>*Valores para simples conferencia!</p>
      <section className={styles.content}>
        <Table columns={columns_comprovante_pagamento}>
          {listLicenca.map((values) => (
            <tr key={values?.data_programacao}>
              <td>
                {values?.data_programacao}
              </td>
              <td>{values?.id_oc}</td>
              {/* <td className={styles.th_tr_Responsive}>{values?.data_recibo}</td> */}
              <td>
                <UINumber format="0.0">{values?.km_realizado}</UINumber>
              </td>
              <td>
                <UINumber format="0.0">{values?.peso_kg_oleo_coletado}</UINumber>
              </td>
              <td>
                <UINumber format="0.0">{values?.total_coletas}</UINumber>
              </td>
              <td>
                <UINumber format="$0.00">{values?.valor_receber_total}</UINumber>
              </td>
              <td hidden>
                  {(SomaValorRecebimento = SomaValorRecebimento + values?.valor_receber_total)}
                </td>
            </tr>
            
          ))}
          <tr>
            <td colSpan="5" style={{ textAlign: 'right' }}>
              <strong> Valor Total :  </strong>
            </td>

            <td>
              <strong>
                <UINumber format="0.00">{SomaValorRecebimento}</UINumber>
              </strong>
            </td>
          </tr>
          
        </Table>
        <Loading display={showLoading ? 'block' : 'none'} />
      </section>
    </Container>
  )
}
