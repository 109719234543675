import styles from './AtualizarUsuario.module.css'
import Container from '../../components/components-ui/Container/Container'
import Button from '../../components/components-ui/Button/Button'
import api from '../../services/Api'
import { AuthContext } from '../../Provider/AuthContext'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'
import { useHistory } from 'react-router-dom'
import { useContext } from 'react'

export default function AtualizarDados() {
  const history = useHistory()
  const { logout, nomeUsuario } = useContext(AuthContext)

  function showAlert(notify, msg) {
    if (notify === 0) {
      toast.success(msg)
    } else {
      toast.error(msg)
    }
  }
  async function updateUser(values) {
    const data = {
      nome_usuario: values?.nome_user,
      email: values?.email,
      celular: values?.celular,
      senha_usuario: values?.confirme_senha,
    }
    console.log(data)
    const response = await api.post('/session/alterasenha', data)
    if (response.data.error !== 1) {
      window.alert('Usuario atualizado com sucesso!!!')
      logout()
      history.push('/')
      return
    }
    showAlert(1, 'Erro ao atualizar usuario')
  }
  const updateInfoUser = Yup.object().shape({
    nome_user: Yup.string().required('*Campo nome Obrigatório'),
    email: Yup.string().required('*Campo email Obrigatório'),
    celular: Yup.number().required('*Campo celular Obrigatório'),
    senha: Yup.string().required('*Campo senha Obrigatório'),
    confirme_senha: Yup.string()
      .oneOf([Yup.ref('senha'), null], 'As senhas não conferem')
      .required('*Campo confirmar senha é obrigatório'),
  })
  return (
    <Container>
      <ToastContainer />
      <div className={styles.container}>
        <h3>Atualizar Dados Do Usuario</h3>
        <section className={styles.content}>
          <div className={styles.formUpdateUser}>
            <Formik
              initialValues={{
                nome_user: nomeUsuario ? nomeUsuario : '',
                email: '',
                celular: '',
                senha: '',
                confirme_senha: '',
              }}
              onSubmit={(values) => updateUser(values)}
              validationSchema={updateInfoUser}>
              {({ errors, touched }) => (
                <Form>
                  <section className={styles.sectionInput}>
                    <label> Nome do Solicitante </label>
                    <Field type="text" name="nome_user" />
                    {errors.nome_user && touched.nome_user ? (
                      <span className={styles.alertError}>{errors.nome_user}</span>
                    ) : null}
                    <label> Email </label>
                    <Field type="email" name="email" />
                    {errors.email && touched.email ? (
                      <span className={styles.alertError}>{errors.email}</span>
                    ) : null}
                    <label> Celular </label>
                    <Field type="number" name="celular" />
                    {errors.celular && touched.celular ? (
                      <span className={styles.alertError}>{errors.celular}</span>
                    ) : null}
                    <label> Senha </label>
                    <Field type="password" name="senha" />
                    {errors.senha && touched.senha ? (
                      <span className={styles.alertError}>{errors.senha}</span>
                    ) : null}
                    <label> Confirmar Senha </label>
                    <Field type="password" name="confirme_senha" />
                    {errors.confirme_senha && touched.confirme_senha ? (
                      <span className={styles.alertError}>{errors.confirme_senha}</span>
                    ) : null}

                    <Button title="Salvar" type="submit" />
                    <Button
                      color="secondary"
                      title="Voltar"
                      type="button"
                      onClick={() => window.history.back()}
                    />
                  </section>
                </Form>
              )}
            </Formik>
          </div>
        </section>
      </div>
    </Container>
  )
}
