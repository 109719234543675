import styles from './Preposto.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import Button from '../../../../components/components-ui/Button/Button'
import Loading from '../../../../components/components-ui/Loading/Loading'
import { Table } from '../../../../components/Table'
import api from '../../../../services/Api'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export default function Preposto() {
  const [listaPreposto, setListaPreposto] = useState([])
  const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    async function getPreposto() {
      setShowLoading(true)
      const response = await api.get(`/preposto/index/`)
      setListaPreposto(response.data)
      setShowLoading(false)
    }
    getPreposto()
  }, [])

  // Colunas da tabela
  const columns = [
    {
      column: 'Sigla',
    },
    {
      column: 'Nome Preposto',
    },
    {
      column: 'Tipo',
    },
    {
      column: 'Cidade / UF',
    },

    {
      column: 'Status',
    },
  ]

  return (
    <Container>
      <h3>Preposto</h3>

      <div className={styles.content}>
        <Link to="/usina/preposto/novo/">
          <Button title="Novo" type="button" />
        </Link>

        <Table edit columns={columns}>
          {listaPreposto.map((values) => (
            <tr key={values?.id}>
              <td>
                <Link to={`/usina/preposto/edit/${values?.id}`}>
                  <i className="fas fa-pencil-alt"></i>
                </Link>
              </td>
              <td>{values?.sigla}</td>
              <td>{values?.nome_fantasia}</td>
              <td>{values?.tipo} </td>
              <td></td>
              <td>{values?.status}</td>
            </tr>
          ))}
        </Table>
        <div className={styles.loading}>
          <Loading display={showLoading ? 'block' : 'none'} />
        </div>
      </div>
      {/*  */}
    </Container>
  )
}
