import Container from '../../../../../components/components-ui/Container/Container'
import { Table } from '../../../../../components/Table'
export default function Materiais() {
  const columns = [
    {
      column: 'Material',
    },
    {
      column: 'Quantidade',
    },
    {
      column: 'Data Entrega',
    },
    {
      column: 'Entregue por',
    },
  ]
  return (
    <Container>
      <Table columns={columns}></Table>
    </Container>
  )
}
