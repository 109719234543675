import styles from '../EmissaoDeColeta.module.css'
import { Grid, Col } from '../../../../../components/Grid'
import Button from '../../../../../components/components-ui/Button/Button'
import { Selects } from '../../../../../components/components-ui/NewSelect'
import api from '../../../../../services/Api'
import 'react-alert-confirm/dist/index.css'
import popup from 'react-alert-confirm'
import { useState, useEffect } from 'react'

export function ModalColeta({ data, dataColeta, closeModal }) {
  const [estimativaLigacao, setEstimativaLigacao] = useState('')
  const [itens, setItens] = useState('')
  const [listItens, setListItens] = useState([])
  useEffect(() => {
    async function getItens() {
      const response = await api.get(`/coleta/subitem/index`)
      setListItens(response.data)
    }
    getItens()
  }, [])

  //
  function adiciona(val) {
    dataColeta([...data, val])
    closeModal(false)
  }
  async function naoAdiciona() {
    popup.confirm({
      title: 'Item já adicionado',
      footer(dispatch) {
        return <Button onClick={() => dispatch('ok')}>Ok</Button>
      },
    })
    // let response = await res
    // window.alert('Item já adicionado')
  }
  //
  const submitDadosColeta = () => {
    //
    if (itens.value === '' || itens.value === undefined) {
      popup.confirm({
        title: 'Selecione alguma opção',
        footer(dispatch) {
          return <Button onClick={() => dispatch('ok')}>Ok</Button>
        },
      })
      return
      // window.alert('Selecione alguma opção')
    }
    if (estimativaLigacao.value === undefined || estimativaLigacao.value <= 0) {
      popup.confirm({
        title: 'Preencha o campo estimativa ligação',
        footer(dispatch) {
          return <Button onClick={() => dispatch('ok')}>Ok</Button>
        },
      })
      // window.alert('Preencha o campo estimativa ligação')
      return
    }
    const dataInput = {
      item: itens.label,
      item_id: itens.value,
      estimativaLigacao: estimativaLigacao.value,
    }
    //
    if (data.length !== 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].item_id === dataInput.item_id) {
          naoAdiciona()
          return
        }
      }
      adiciona(dataInput)
    } else {
      dataColeta([dataInput])
      closeModal(false)
    }
  }

  const optionsItens = listItens.map((values) => {
    return {
      value: values?.id_item,
      label: values?.item,
    }
  })

  return (
    <section className={styles.content}>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className={styles.wrap}>
          <Grid>
            <Col md={9}>
              <label>Item</label>

              <Selects
                options={optionsItens}
                isClearable
                onChange={(ev) => setItens(ev)}
              />

              {/* <select onChange={(ev) => setItens(ev.target)}>
                <option value=""> Selecione </option>
                {listItens.map((values) => (
                  <option key={values?.id_item} value={values?.item}>
                    {values?.item}
                  </option>
                ))}
              </select> */}
            </Col>
            <Col md={3}>
              <label>Est. Ligação</label>
              <input
                required
                type="number"
                defaultValue={estimativaLigacao.value}
                onChange={(ev) => setEstimativaLigacao(ev.target)}
              />
            </Col>
          </Grid>

          <Grid>
            <Col md={6}>
              <Button
                onClick={submitDadosColeta}
                type="submit"
                title="Salvar"
              />
            </Col>
            <Col md={6}>
              <Button
                onClick={() => closeModal(false)}
                type="submit"
                title="Cancelar"
                color="secondary"
              />
            </Col>
          </Grid>
        </div>
      </form>
    </section>
  )
}

export function ModalContrapartida({ data, dataContrapatida, closeModal }) {
  const [contrapartida, setContrapartida] = useState('')
  const [quantidade, setQuantidade] = useState('')
  const [brinde, setBrinde] = useState(false)
  const [listBrinde, setListBrinde] = useState([])
  useEffect(() => {
    //Receber nomes dos veiculos
    async function getBrinde() {
      const response = await api.get(`/brinde/index`)
      setListBrinde(response.data)
    }
    getBrinde()
  }, [])

  //
  function adiciona(val) {
    dataContrapatida([...data, val])
    closeModal(false)
  }
  async function naoAdiciona() {
    popup.confirm({
      title: 'Item já adicionado',
      footer(dispatch) {
        return <Button onClick={() => dispatch('ok')}>Ok</Button>
      },
    })
    // let response = await res
    // window.alert('Item já adicionado')
  }

  const submitDadosContrapartida = () => {
    // const id = retornID(contrapartida.value)
    if (contrapartida.value === '' || contrapartida.value === undefined) {
      popup.confirm({
        title: 'Selecione alguma opção',
        footer(dispatch) {
          return <Button onClick={() => dispatch('ok')}>Ok</Button>
        },
      })
      // window.alert('Selecione alguma opção')
      return
    }
    if (quantidade.value <= 0 || quantidade.value === undefined) {
      popup.confirm({
        title: 'Preencha o campo quantidade',
        footer(dispatch) {
          return <Button onClick={() => dispatch('ok')}>Ok</Button>
        },
      })
      // window.alert('Preencha o campo quantidade')
      return
    }
    const dataInput = {
      contrapartida: contrapartida.label,
      id_contrapartida: contrapartida.value,
      quantidade: quantidade.value,
      brinde: brinde.checked,
    }
    //
    if (data.length !== 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id_contrapartida === dataInput.id_contrapartida) {
          naoAdiciona()
          return
        }
      }
      adiciona(dataInput)
    } else {
      dataContrapatida([dataInput])
      closeModal(false)
    }
  }
  const optionsBrinde = listBrinde.map((values) => {
    return {
      value: values?.id_brinde,
      label: values?.brinde,
    }
  })

  return (
    <section className={styles.content}>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className={styles.wrap}>
          <Grid>
            <Col md={8}>
              <label> Contrapartida </label>

              <Selects
                options={optionsBrinde}
                isClearable
                onChange={(ev) => setContrapartida(ev)}
              />

              {/* <select onChange={(ev) => setContrapartida(ev.target)}>
                <option value=""> Selecione </option>
                {listBrinde.map((values) => (
                  <option key={values?.id} value={values?.id}>
                    {values?.brinde}
                  </option>
                ))}
              </select> */}
            </Col>
            <Col md={2}>
              <label>Qtd.</label>
              <input
                type="number"
                onChange={(ev) => setQuantidade(ev.target)}
                required
              />
            </Col>
            <Col md={1}>
              <label>Brinde</label>
              <input type="checkbox" onChange={(ev) => setBrinde(ev.target)} />
            </Col>
          </Grid>
          <Grid>
            <Col md={6}>
              <Button
                type="submit"
                title="Salvar"
                onClick={submitDadosContrapartida}
              />
            </Col>
            <Col md={6}>
              <Button
                type="submit"
                title="Cancelar"
                color="secondary"
                onClick={() => closeModal(false)}
              />
            </Col>
          </Grid>
        </div>
      </form>
    </section>
  )
}
