import styles from '../Tabelas.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import Button from '../../../../components/components-ui/Button/Button'
import { Grid, Col } from '../../../../components/Grid'
import api from '../../../../services/Api'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import { useParams, useHistory } from 'react-router'
import { useEffect, useState } from 'react'

export default function Action() {
  const [cidade, setCidade] = useState('')
  const { id } = useParams()
  function showAlert(notify, msg) {
    if (notify === 0) {
      toast.success(msg)
    } else {
      toast.error(msg)
    }
  }

  async function createCidade(values) {
    const response = await api.post('/cidade/create/', {
      cidade: values.nomeCidade,
    })
    if (response.data.error === 0) {
      showAlert(0, response.data.msg)
      return
    }
    showAlert(1, response.data.msg)
  }

  async function editarCidade(values) {
    const response = await api.put(`/cidade/edit/${id}`, {
      cidade: values.nomeCidade,
    })
    if (response.data.error === 0) {
      showAlert(0, response.data.msg)
      return
    }
    showAlert(1, response.data.msg)
  }

  useEffect(() => {
    async function getCidade() {
      const response = await api.get(`/cidade/show/${id}`)
      setCidade(response.data.cidade)
    }
    getCidade()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const history = useHistory()
  const validadtionSchema = Yup.object().shape({
    nomeCidade: Yup.string().required('*Campo Obrigatório'),
  })
  return (
    <Container>
      <ToastContainer />
      <h3>{id ? 'Edição de Cidade' : 'Cadastro de Cidade'}</h3>
      <section className={styles.action_section}>
        <Formik
          initialValues={{ nomeCidade: cidade }}
          enableReinitialize={true}
          onSubmit={
            id
              ? (values) => editarCidade(values)
              : (values) => createCidade(values)
          }
          validationSchema={validadtionSchema}
        >
          {({ errors, touched }) => (
            <Form>
              <Grid>
                <Col md={12}>
                  <label> Nome Cidade </label>
                  <Field type="text" name="nomeCidade" />
                  {errors.nomeCidade && touched.nomeCidade ? (
                    <span className={styles.alertError}>
                      {errors.nomeCidade}
                    </span>
                  ) : null}
                </Col>
              </Grid>
              <Grid>
                <Col md={12}>
                  <Button type="submit" title={!id ? 'Salvar' : 'Editar'} />
                  <Button
                    type="button"
                    title="Voltar"
                    color="secondary"
                    onClick={() => {
                      history.push('/usina/cidade')
                    }}
                  />
                </Col>
              </Grid>
            </Form>
          )}
        </Formik>
      </section>
    </Container>
  )
}
