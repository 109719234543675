import styles from '../Tabelas.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import { Table } from '../../../../components/Table'
import Button from '../../../../components/components-ui/Button/Button'
import Loading from '../../../../components/components-ui/Loading/Loading'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import api from '../../../../services/Api'

export default function CadastroDePontos() {
  const [showLoading, setShowLoading] = useState(false)
  const [loadCampanha, setLoadCampanha] = useState([])

  useEffect(() => {
    async function getGrupos() {
      setShowLoading(true)
      const response = await api.get('/campanha/index')
      setLoadCampanha(response.data)
      setShowLoading(false)
    }
    getGrupos()
  }, [])
  // Colunas da tabela
  const columns = [
    {
      column: 'Campanha',
    },
  ]
  return (
    <Container>
      <h3>Listagem de Campanhas</h3>
      <Link to="/usina/campanha/novo">
        <Button title="Novo" type="button" />
      </Link>

      <div className={styles.content}>
        <Table edit columns={columns}>
          {loadCampanha.map(({ id_campanha, campanha }) => (
            <tr key={id_campanha}>
              <td>
                <Link to={`/usina/campanha/edit/${id_campanha}`}>
                  <i className="fas fa-pencil-alt"></i>
                </Link>
              </td>
              <td>{campanha}</td>
            </tr>
          ))}
        </Table>
        <div className={styles.loading}>
          <Loading display={showLoading ? 'block' : 'none'} />
        </div>
      </div>
    </Container>
  )
}
