import styles from '../Tabelas.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import { Table } from '../../../../components/Table'
import Button from '../../../../components/components-ui/Button/Button'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import api from '../../../../services/Api'
import Loading from '../../../../components/components-ui/Loading/Loading'

export default function Brinde() {
  const [listaBrinde, setListaBrinde] = useState([])
  const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    async function getBrinde() {
      setShowLoading(true)
      const response = await api.get('/brinde/index')
      setListaBrinde(response.data)
      setShowLoading(false)
    }
    getBrinde()
  }, [])
  // Colunas da tabela
  const columns = [
    {
      column: 'Brinde',
    },
    {
      column: 'Status',
    },
  ]
  return (
    <Container>
      <h3>Listagem de Brinde</h3>
      <Link to="/usina/brinde/novo/">
        <Button title="Novo" type="button" />
      </Link>

      <div className={styles.content}>
        <Table edit columns={columns}>
          {listaBrinde.map(({ id_brinde, brinde, status }) => (
            <tr key={id_brinde}>
              <td>
                <Link to={`/usina/brinde/edit/${id_brinde}`}>
                  <i className="fas fa-pencil-alt"></i>
                </Link>
              </td>
              <td>{brinde}</td>
              <td>{status}</td>
            </tr>
          ))}
        </Table>
        <div className={styles.loading}>
          <Loading display={showLoading ? 'block' : 'none'} />
        </div>
      </div>
    </Container>
  )
}
