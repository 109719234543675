import styles from './MeusMTR.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import Loading from '../../../../components/components-ui/Loading/Loading'
import { Table } from '../../../../components/Table'
import api from '../../../../services/Api'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export default function MeusMTR() {
  const [coletaMTR, setColetaMTR] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  // const [coletaMTR, setColetaMTR] = useState([
  //   {
  //     numero_mtr: '',
  //     id_coleta: '',
  //     data_pedido: '',
  //   },
  // ])
  // Links
  const API_LOCAL_MTR = `/localmtr`
  useEffect(() => {
    async function getMtr() {
      setShowLoading(true)
      const response = await api.get(API_LOCAL_MTR)
      console.log(response.data)
      setColetaMTR(response.data)
      setShowLoading(false)
    }
    getMtr()
  }, [API_LOCAL_MTR])
  return (
    <Container>
      <div className={styles.content}>
        <h3> Meus MTR'S </h3>
        <Table
          columns={[
            { column: 'Baixar' },
            { column: 'N° MTR' },
            { column: 'N° Coleta' },
            { column: 'Data pedido' },
          ]}
        >
          {coletaMTR?.map((values) => (
            <tr key={values?.id_coleta}>
              <td>
                <Link to={{ pathname: values?.url_download }} target="_blank">
                  <i className="fas fa-download"> </i>
                </Link>
              </td>
              <td> {values?.numero_mtr} </td>
              <td> {values?.id_coleta} </td>
              <td> {values?.data_pedido} </td>
            </tr>
          ))}
        </Table>
        <div className={styles.loading}>
          <Loading display={showLoading ? 'block' : 'none'} />
        </div>
      </div>
    </Container>
  )
}
