import styles from './ListaRoteiros.module.css'
import Container from '../../../components/components-ui/Container/Container'
import Loading from '../../../components/components-ui/Loading/Loading'
import { Table } from '../../../components/Table'
import UINumber from '../../../components/components-ui/UINumber/UINumber'
import api from '../../../services/Api'
import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'



export default function ListaRoteiros() {
  const history = useHistory()
  const { id } = useParams()
  const [detalheRoteiro, setDetalheRoteiro] = useState([])
  const [showLoading, setShowLoading] = useState(false)

  //const [colorRoteiro, setColorRoteiro] = useState()

  useEffect(() => {
    async function getDetalhesRoteiros() {
      setShowLoading(true)
      const response = await api.post(`/motorista/roteirodetalhe/${id}`)
      setDetalheRoteiro(response.data)
      setShowLoading(false)
    }
    getDetalhesRoteiros()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const columns = [
    {
      column: 'Data',
    },
    {
      column: 'Km Inicial',
    },
    {
      column: 'Placa',
    },
    {
      column: 'Motorista',
    },
  ]
  return (
    <Container>
      <div className={styles.content}>
        <div className={styles.btn_footer}>
          <Link
            to="#"
            onClick={() => history.push('/motorista')}
            className={styles.backButton}
          >
            <i className="fas fa-arrow-left"></i> Voltar
          </Link>
        </div>
        <h3>Listagem de Coletas</h3>
        <Table columns={columns}>
          <tr>
            <td>{detalheRoteiro[0]?.data_oc}</td>
            <td>{detalheRoteiro[0]?.km_inicial_motorista}</td>
            <td>{detalheRoteiro[0]?.placa_veiculo}</td>
            <td>{detalheRoteiro[0]?.motorista}</td>
          </tr>
        </Table>
        <div className={styles.loading}>
          <Loading display={showLoading ? 'block' : 'none'} />
        </div>
        {detalheRoteiro.map((values) => (
          <Link
            to={`/motorista/detalhe-coleta/${values?.id_oc_sub}`}
            className={styles.wrap_roteiro_tipo1}
            key={values?.id_oc_sub}
          >
            <div>
              <ul>
                <li>
                  <strong>Ordem: </strong> {values?.id_ordem}
                </li>
                <li>
                  <strong>Coleta:</strong> {values?.id_coleta}
                </li>

                <li>
                  <strong>Nome Local:</strong> {values?.nome_local}
                </li>
                <li>
                  <strong>Endereço:</strong> {values?.endereco}
                </li>
                <li>
                  <strong>CEP: </strong> {values?.cep}
                </li>
                <li>
                  <strong>Campanha:</strong> {values?.campanha}
                </li>
                <li>
                  <strong>Estimativa Ligação: </strong>
                  <UINumber format="0.00">
                    {values?.estimativa_ligacao}
                  </UINumber>
                </li>
                <li>
                  <strong>Estimativa Motorista: </strong>
                  <UINumber format="0.00">
                    {values?.quantidade_oleo_motorista}
                  </UINumber>
                </li>
                <li>
                  <strong>Data/Hora  {values?.aviso_ocorrencia == 1 ? 'Aviso:':'Validação:'} </strong>
                  {values?.data_validacao}
                </li>
                <li>
                  <strong>Status: </strong> {values?.status} {values?.aviso_ocorrencia == 1 ? ('( Aviso de Ocorrência)'):null}
                </li>
              </ul>
            </div>
            <div className={styles.link_to_nextPage}>
              <span>
                <i className="fas fa-arrow-right"></i>
              </span>
            </div>
          </Link>
        ))}
      </div>

    </Container>
  )
}
