import styles from './AcessoMTR.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import Button from '../../../../components/components-ui/Button/Button'
import api from '../../../../services/Api'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'
import { useState, useEffect } from 'react'

export default function AcessoMTR() {
  // const [valuesForm, setValuesForm] = useState({
  //   login_mtr: '',
  //   senha_mtr: '',
  //   unidade_mtr: '',
  // })
  const [dadosMtr, setDadosMtr] = useState([])

  useEffect(() => {
    async function getMtr() {
      const response = await api.get('/mob/local/mtrlogin/index')
      setDadosMtr(response.data)
    }
    getMtr()
  }, [])

  function showAlert(notify, msg) {
    if (notify === 0) {
      toast.success(msg)
    } else {
      toast.error(msg)
    }
  }
  // function onChange(ev) {
  //   const { name, value } = ev.target
  //   setValuesForm({ ...valuesForm, [name]: value })
  // }
  const validationSchema = Yup.object().shape({
    login_mtr: Yup.string().required('*Campo login Obrigatório'),
    senha_mtr: Yup.string().required('*Campo senha Obrigatório'),
    unidade_mtr: Yup.string().required('*Campo unidade Obrigatório'),
  })

  async function salvarMTR(values) {
    const response = await api.put('/mob/local/mtrlogin/edit', values)
    if (response.data.status === 1) {
      showAlert(1, response.data.status_msg)
      return
    }
    showAlert(0, response.data.status_msg)
  }
  return (
    <Container>
      <ToastContainer />
      <h3>Quer Ganhar Tempo ?</h3>
      <br />
      <p>
        Informe aqui os dados de acesso ao MTR e deixe que nós o emitimos para
        você
      </p>
      <section className={styles.content}>
        <section className={styles.form}>
          <Formik
            initialValues={{
              login_mtr: dadosMtr?.login_mtr,
              senha_mtr: dadosMtr?.senha_mtr || '',
              unidade_mtr: dadosMtr?.unidade_mtr || '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => salvarMTR(values)}
            enableReinitialize={true}
          >
            {({ errors, touched }) => (
              <Form>
                <div className={styles.field_column}>
                  <label> Login MTR </label>
                  <Field type="text" name="login_mtr" />
                  {errors.login_mtr && touched.login_mtr ? (
                    <span className={styles.alertError}>
                      {errors.login_mtr}
                    </span>
                  ) : null}
                </div>
                <div className={styles.field_column}>
                  <label> Senha MTR </label>
                  <Field type="text" name="senha_mtr" />
                  {errors.senha_mtr && touched.senha_mtr ? (
                    <span className={styles.alertError}>
                      {errors.senha_mtr}
                    </span>
                  ) : null}
                </div>
                <div className={styles.field_column}>
                  <label> Unidade MTR </label>
                  <Field type="text" name="unidade_mtr" />
                  {errors.unidade_mtr && touched.unidade_mtr ? (
                    <span className={styles.alertError}>
                      {errors.unidade_mtr}
                    </span>
                  ) : null}
                </div>
                <div className={styles.field_column}>
                  <Button title="Salvar" type="submit" />
                </div>
              </Form>
            )}
          </Formik>
        </section>
      </section>
    </Container>
  )
}
