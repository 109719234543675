import styles from './Confirmation.module.css'
import Loading from '../components-ui/Loading/Loading'

export function BlockScreenLoading({ children, loading, display }) {
  return display ? (
    <div className={styles.blockScreen}>
      {children}
      <Loading display={loading ? 'block' : 'none'} />
    </div>
  ) : null
}
