import styles from '../EmissaoDeColeta.module.css'
import Container from '../../../../../components/components-ui/Container/Container'
import { BlockScreenLoading } from '../../../../../components/BlockScreenLoading'
import { AsyncSelects } from '../../../../../components/components-ui/NewSelect'
// import { Selects } from '../../../../../components/components-ui/Select/Select'
import Button from '../../../../../components/components-ui/Button/Button'
import Modal from '../../../../../components/Modal'
import { Table } from '../../../../../components/Table'
import { Grid, Col } from '../../../../../components/Grid'
import { ModalColeta, ModalContrapartida } from '../Modal/'
import api from '../../../../../services/Api'
// import ReactHTMLDatalist from 'react-html-datalist'
import 'react-alert-confirm/dist/index.css'
import popup from 'react-alert-confirm'
import * as Yup from 'yup'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import { Formik, Form, Field } from 'formik'
import { useHistory, Link } from 'react-router-dom'
import { useParams } from 'react-router'
import { useEffect, useState } from 'react'

function Action() {
  const { id } = useParams()
  const history = useHistory()
  const [dadosColeta, setDadosColeta] = useState('')
  // const [dadosLocal, setDadosLocal] = useState([])
  const [dadosStatus, setDadosStatus] = useState([])
  const [veiculo, setVeiculo] = useState([])
  const [openModalColeta, setOpenModalColeta] = useState(false)
  const [openModalContrapartida, setOpenModalContrapartida] = useState(false)
  const [dadosModalColeta, setDadosModalColeta] = useState([])
  const [dadosModalContrapartida, setDadosModalContraPartida] = useState([])
  const [emissor, setEmissor] = useState([])
  const [tipoDeColeta, setTipoDeColeta] = useState([])
  const [dadosNovaColeta, setDadosNovaColeta] = useState([])
  const [dadosListaColetaSub, setDadosListaColetaSub] = useState([])
  const [dadosListaContrapartida, setDadosListaContrapartida] = useState([])
  const [nomeLocal, setNomeLocal] = useState()
  const [blockScreen, setBlockScreen] = useState(false)
  // const localRef = useRef()
  // Links
  const API_SHOW_DADOS_COLETA = `/coleta/show/`
  const API_DADOS_NOVA_COLETA = `/coleta/new`
  const API_COLETA_VIEW = `/local/view`
  const API_DADOS_STATUS = `/coleta/status/index`
  const API_DADOS_VEICULOS = `/veiculo/index`
  const API_LISTA_EMISSOR = `/usuario/view`
  const API_DADOS_TIPO_COLETA = `/coletatipo/view`
  // ALERT PERSONALIZADO
  function showAlert(notify, msg) {
    if (notify === 0) {
      toast.success(msg)
    } else {
      toast.error(msg)
    }
  }
  // CARREGA OS TODAS AS REQUISIÇÃO DOS CAMPOS
  useEffect(() => {
    if (id) {
      setBlockScreen(true)
      async function getDadosColeta() {
        const response = await api.get(API_SHOW_DADOS_COLETA + id)
        setDadosColeta(response.data.coleta)
        setDadosListaColetaSub(response.data.coleta_sub)
        setDadosListaContrapartida(response.data.coleta_contrapartida)
        setBlockScreen(false)
      }
      getDadosColeta()
    }
    // Dados Pra nova coleta
    async function getDadosNovaColeta() {
      const response = await api.get(API_DADOS_NOVA_COLETA)
      setDadosNovaColeta(response.data)
    }
    getDadosNovaColeta()
    //Receber valores do local
    // async function getDadosLocal() {
    //   const response = await api.get(API_COLETA_VIEW)
    //   setDadosLocal(response.data.data)
    // }
    // getDadosLocal()
    //Receber valores do local
    async function getDadosStatus() {
      const response = await api.get(API_DADOS_STATUS)
      setDadosStatus(response.data.coleta_status)
    }
    getDadosStatus()
    //Receber nomes dos veiculos
    async function getVeiculo() {
      const response = await api.get(API_DADOS_VEICULOS)
      setVeiculo(response.data)
    }
    getVeiculo()
    // Carrega emissor
    async function getEmissor() {
      const response = await api.get(API_LISTA_EMISSOR)
      setEmissor(response.data)
    }
    getEmissor()
    // Carrega emissor
    async function getTipoColeta() {
      const response = await api.get(API_DADOS_TIPO_COLETA)
      setTipoDeColeta(response.data)
    }
    getTipoColeta()
    //
    // localRef.current.autofocus()
  }, [
    API_COLETA_VIEW,
    API_LISTA_EMISSOR,
    API_DADOS_NOVA_COLETA,
    API_DADOS_STATUS,
    API_DADOS_TIPO_COLETA,
    API_DADOS_VEICULOS,
    API_SHOW_DADOS_COLETA,
    id,
  ])
  function novaColeta(values) {
    const data = {
      dadosFormulario: values,
      dadosDaColeta: dadosModalColeta,
      dadosDaContrapartida: dadosModalContrapartida,
    }

    console.log(data)
    showAlert(0, 'Coleta Criada')
  }
  function editarColeta(values) {
    const data = {
      dadosFormulario: values,
      dadosDaColeta: dadosModalColeta,
      dadosDaContrapartida: dadosModalContrapartida,
    }
    console.log(data)
    showAlert(0, 'Coleta Editada')
  }
  // DELETAR ITENS DAS TABELAS
  function deletar(id, arr) {
    arr.forEach((item, index) => {
      if (index === id) {
        arr.splice(id, 1)
        // window.alert(`Item deletado`)
        popup.confirm({
          title: `Item deletado`,
          footer(dispatch) {
            return <Button onClick={() => dispatch('ok')}>Ok</Button>
          },
        })
      }
    })
  }
  // FILTRO DE LOCAIS
  async function getDadosLocal(inputValue) {
    const response = await api.get(`/local/viewedit?nome_local=${inputValue}`)
    const optionsLocal = response.data.data.map((values) => {
      return {
        value: values?.id_local,
        label: values?.nome_local,
      }
    })
    return optionsLocal
  }
  // LISTA NOME DO LOCAL AO CARREGAR EDIÇÃO DA COLETA
  async function getNomeLocal() {
    const response = await api.get(
      `/local/viewedit?id_local=${dadosColeta?.id_local}`
    )
    const optionsLocal = response.data.data.map((values) => {
      if (dadosColeta?.id_local === values.id_local) {
        setNomeLocal(values.nome_local)
      }
    })
    return optionsLocal
  }
  useEffect(() => {
    if (dadosColeta?.id_local) {
      getNomeLocal()
    }
  }, [dadosColeta])
  // COLUNAS DAS TABELAS
  const columnsColeta = [
    {
      column: 'Itens',
    },
    {
      column: 'Estimativa Ligação',
    },
  ]
  const columnsContrapartida = [
    {
      column: 'Contrapartida',
    },
    {
      column: 'Qtde.',
    },
    {
      column: 'Brinde.',
    },
  ]
  // SCHEMA DE VALIDAÇÃO DO FORMULÁRIO
  const validationSchema = Yup.object().shape({
    numeroColeta: Yup.string(),
    local: Yup.string(),
    status: Yup.string(),
    data_pedido: Yup.date(),
    emissor: Yup.string(),
    veiculo: Yup.string(),
    tipoColeta: Yup.string(),
    etapa: Yup.string(),
    horarioDeFuncionamento: Yup.string(),
    horaDe: Yup.string(),
    horaAte: Yup.string(),
    criadoPor: Yup.string(),
    observacao: Yup.string(),
    // alteradoPor: Yup.string(),
    // dataAlteracao: Yup.string(),
  })
  //
  function InputSelect(props) {
    return <AsyncSelects loadOptions={getDadosLocal} {...props} />
  }
  return (
    <Container>
      <ToastContainer />
      <BlockScreenLoading loading display={blockScreen} />
      <h3> {id ? 'Edição de Coleta' : 'Cadastro de Coleta'} </h3>
      <section className={styles.content}>
        <Formik
          initialValues={{
            numeroColeta: id ? dadosColeta?.id_coleta : 'Nova',
            local: dadosColeta?.id_local ? dadosColeta?.id_local : '',
            status: dadosColeta?.id_status ? dadosColeta?.id_status : 1,
            data_pedido: dadosColeta?.data_pedido
              ? dadosColeta?.data_pedido
              : dadosNovaColeta?.data_pedido,
            emissor: dadosColeta?.id_criado_por
              ? dadosColeta?.id_criado_por
              : dadosNovaColeta?.user_id,
            veiculo: dadosColeta?.id_veiculo ? dadosColeta?.id_veiculo : '',
            tipoColeta: dadosColeta?.id_tipo_coleta,
            etapa: dadosColeta?.id_etapa,
            horarioDeFuncionamento: '',
            horaDe: '',
            horaAte: '',
            // criadoPor: dadosColeta?.id_criado_por,
            observacao: dadosColeta?.observacao ? dadosColeta?.observacao : '',
            // alteradoPor: dadosColeta?.id_alterado_por,
            // dataAlteracao: dadosColeta?.data_alteracao,
          }}
          enableReinitialize={true}
          onSubmit={(values) =>
            id ? editarColeta(values) : novaColeta(values)
          }
          validationSchema={validationSchema}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <Grid>
                <Col md={1}>
                  <label> N° Coleta </label>
                  <Field type="text" name="numeroColeta" disabled />
                  {errors.numeroColeta && touched.numeroColeta ? (
                    <span className={styles.alertError}>
                      {errors.numeroColeta}
                    </span>
                  ) : null}
                </Col>
                <Col md={5}>
                  <label> Local </label>

                  <Field name="local">
                    {({ field }) => (
                      <InputSelect
                        autoFocus
                        cacheOptions
                        defaultOptions
                        defaultValue={{
                          value: field?.value,
                          label: nomeLocal ? nomeLocal : 'Selecione...',
                        }}
                        onChange={(ev) => setFieldValue('local', ev.value)}
                      />
                    )}
                  </Field>
                  {errors.local && touched.local ? (
                    <span className={styles.alertError}>{errors.local}</span>
                  ) : null}
                </Col>
                <Col md={3}>
                  <label> Status </label>
                  {id ? (
                    <Field name="status" component="select">
                      <option value="">Selecione</option>
                      {dadosStatus.map((values) => (
                        <option
                          key={values?.id_status}
                          value={values?.id_status}
                        >
                          {values?.status}
                        </option>
                      ))}
                    </Field>
                  ) : (
                    <Field name="status" component="select" disabled>
                      <option value=""> Selecione </option>
                      {dadosStatus.map((values) => (
                        <option
                          key={values?.id_status}
                          value={values?.id_status}
                        >
                          {values?.status}
                        </option>
                      ))}
                    </Field>
                  )}
                  {errors.status && touched.status ? (
                    <span className={styles.alertError}>{errors.status}</span>
                  ) : null}
                </Col>
                <Col md={3}>
                  <label>Data Pedido</label>
                  <Field type="date" name="data_pedido" />
                  {errors.data_pedido && touched.data_pedido ? (
                    <span className={styles.alertError}>
                      {errors.data_pedido}
                    </span>
                  ) : null}
                </Col>
              </Grid>
              <Grid>
                <Col md={3}>
                  <label> Emissor </label>
                  <Field name="emissor" component="select">
                    <option value=""> Todos </option>
                    {emissor.map((values) => (
                      <option
                        key={values?.id_usuario}
                        value={values?.id_usuario}
                      >
                        {values?.nome_usuario}
                      </option>
                    ))}
                  </Field>
                  {errors.emissor && touched.emissor ? (
                    <span className={styles.alertError}>{errors.emissor}</span>
                  ) : null}
                </Col>
                {id ? (
                  <Col md={3}>
                    <label> Veículo </label>
                    <Field name="veiculo" component="select">
                      <option value=""> Selecione </option>
                      {veiculo.map((values) => (
                        <option key={values?.id_veiculo} value={values?.placa}>
                          {values?.placa}
                        </option>
                      ))}
                    </Field>
                    {errors.veiculo && touched.veiculo ? (
                      <span className={styles.alertError}>
                        {errors.veiculo}
                      </span>
                    ) : null}
                  </Col>
                ) : null}

                <Col md={3}>
                  <label> Tipo Coleta </label>
                  <Field name="tipoColeta" component="select">
                    <option value=""> Selecione </option>
                    {tipoDeColeta.map((values) => (
                      <option
                        key={values?.id_coleta_tipo}
                        value={values?.id_coleta_tipo}
                      >
                        {values?.tipo_coleta}
                      </option>
                    ))}
                  </Field>
                  {errors.tipoColeta && touched.tipoColeta ? (
                    <span className={styles.alertError}>
                      {errors.tipoColeta}
                    </span>
                  ) : null}
                </Col>
                {id ? (
                  <Col md={3}>
                    <label> Etapa </label>
                    {id ? (
                      <Field type="text" name="etapa" disabled />
                    ) : (
                      <Field type="text" name="etapa" />
                    )}

                    {errors.etapa && touched.etapa ? (
                      <span className={styles.alertError}>{errors.etapa}</span>
                    ) : null}
                  </Col>
                ) : null}
              </Grid>
              <Grid>
                <Col md={8}>
                  <label> Horário de Funcionamento </label>
                  <Field type="text" name="horarioDeFuncionamento" />
                  {errors.horarioDeFuncionamento &&
                  touched.horarioDeFuncionamento ? (
                    <span className={styles.alertError}>
                      {errors.horarioDeFuncionamento}
                    </span>
                  ) : null}
                </Col>

                <Col md={2}>
                  <label> Hora Coleta De </label>
                  <Field type="text" name="horaDe" />
                  {errors.horaDe && touched.horaDe ? (
                    <span className={styles.alertError}>{errors.horaDe}</span>
                  ) : null}
                </Col>

                <Col md={2}>
                  <label> Até </label>
                  <Field type="text" name="horaAte" />
                  {errors.horaAte && touched.horaAte ? (
                    <span className={styles.alertError}>{errors.horaAte}</span>
                  ) : null}
                </Col>
              </Grid>

              <Grid>
                <Col md={6}>
                  {id ? (
                    <Table
                      edit
                      columns={columnsColeta}
                      title="Detalhe da Coleta"
                    >
                      {dadosListaColetaSub?.map((values, index) => (
                        <tr key={index}>
                          <td>
                            <Link
                              to="#"
                              // onClick={() => deletar(index, dadosModalColeta)}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </Link>
                          </td>
                          <td> {values?.item} </td>
                          <td> {values?.estimativa_ligacao} </td>
                        </tr>
                      ))}
                    </Table>
                  ) : (
                    <Table
                      edit
                      columns={columnsColeta}
                      title="Detalhe da Coleta"
                    >
                      {dadosModalColeta.map((values, index) => (
                        <tr key={index}>
                          <td>
                            <Link
                              to="#"
                              onClick={() => deletar(index, dadosModalColeta)}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </Link>
                          </td>
                          <td> {values?.item} </td>
                          <td> {values?.estimativaLigacao} </td>
                        </tr>
                      ))}
                    </Table>
                  )}

                  {/* // <Table edit columns={columnsColeta} title="Detalhe da Coleta"> 
                    
                  //   {dadosModalColeta.map((values, index) => (
                  //     <tr key={index}>
                  //       <td>
                  //         <Link
                  //           to="#"
                  //           onClick={() => deletar(index, dadosModalColeta)}
                  //         >
                  //           <i className="fas fa-trash-alt"></i>
                  //         </Link>
                  //       </td>
                  //       <td> {values?.itens} </td>
                  //       <td> {values?.estimativaLigacao} </td>
                  //     </tr>
                  //   ))}
                  // </Table>
                  */}
                  <Button
                    title="Adicionar Item"
                    type="button"
                    onClick={() => setOpenModalColeta(true)}
                  />
                </Col>
                <Col md={6}>
                  {id ? (
                    <Table
                      edit
                      columns={columnsContrapartida}
                      title="Contrapartida"
                    >
                      {dadosListaContrapartida.map((values, index) => (
                        <tr key={index}>
                          <td>
                            <Link
                              to="#"
                              onClick={() =>
                                deletar(index, dadosModalContrapartida)
                              }
                            >
                              <i className="fas fa-trash-alt"></i>
                            </Link>
                          </td>
                          <td> {values?.brinde} </td>
                          <td> {values?.quantidade_solicitada} </td>
                          <td>
                            {' '}
                            {values?.brinde ? 'Com Brinde' : 'Sem Brinde'}{' '}
                          </td>
                        </tr>
                      ))}
                    </Table>
                  ) : (
                    <Table
                      edit
                      columns={columnsContrapartida}
                      title="Contrapartida"
                    >
                      {dadosModalContrapartida.map((values, index) => (
                        <tr key={index}>
                          <td>
                            <Link
                              to="#"
                              onClick={() =>
                                deletar(index, dadosModalContrapartida)
                              }
                            >
                              <i className="fas fa-trash-alt"></i>
                            </Link>
                          </td>
                          <td> {values?.contrapartida} </td>
                          <td> {values?.quantidade} </td>
                          <td>
                            {values?.brinde ? 'Com Brinde' : 'Sem Brinde'}
                          </td>
                        </tr>
                      ))}
                    </Table>
                  )}

                  <Button
                    title="Adicionar Contrapartida"
                    type="button"
                    onClick={() => setOpenModalContrapartida(true)}
                  />
                </Col>
              </Grid>
              <Grid>
                <Col md={12}>
                  <label>Observação</label>
                  <Field component="textarea" name="observacao"></Field>
                  {errors.observacao && touched.observacao ? (
                    <span className={styles.alertError}>
                      {errors.observacao}
                    </span>
                  ) : null}
                </Col>
              </Grid>
              {/*
               <Grid grid="12">
                <Col col="3">
                  
                    <label>Criado Por</label>
                    <Field component="select" name="criadoPor">
                      <option> criadoPor </option>
                    </Field>
                  
                </Col>

                <Col col="3">
                  
                    <label>Criado Por</label>
                    <Field type="date" name="dataCriacao" />
                  
                </Col>

                <Col col="3">
                  
                    <label>Alterado Por</label>
                    <Field component="select" name="alteradoPor">
                      <option> AlteradoPor </option>
                    </Field>
                  
                </Col>

                <Col col="3">
                  
                    <label>Data Última Alteração</label>
                    <Field type="date" name="dataAlteracao" />
                  
                </Col>
              </Grid> */}

              <section className={styles.section_button}>
                <Button title="Salvar" type="submit" />
                <Button
                  color="secondary"
                  title="Voltar"
                  onClick={() => {
                    history.push('/usina/emissao-de-coleta')
                  }}
                  type="button"
                />
              </section>
            </Form>
          )}
        </Formik>
      </section>
      <Modal
        width="500px"
        height="200px"
        titleModal="Coletas"
        display={openModalColeta}
        onClick={() => setOpenModalColeta(false)}
      >
        <ModalColeta
          closeModal={setOpenModalColeta}
          data={dadosModalColeta}
          dataColeta={setDadosModalColeta}
        />
      </Modal>
      <Modal
        width="500px"
        height="400px"
        titleModal="Contrapartida"
        display={openModalContrapartida}
        onClick={() => setOpenModalContrapartida(false)}
      >
        <ModalContrapartida
          closeModal={setOpenModalContrapartida}
          data={dadosModalContrapartida}
          dataContrapatida={setDadosModalContraPartida}
        />
      </Modal>
    </Container>
  )
}

export default Action
