import { ReactTabs } from '../../../../components/ReactTabs'
import Container from '../../../../components/components-ui/Container/Container'
import Action from './Tabs/Action'
import { useParams } from 'react-router-dom'

export default function PainelLocal() {
  const { id } = useParams()
  const title = [
    {
      title: 'Dados da Coleta',
    },
  ]
  const content = [
    {
      component: <Action />,
    },
  ]
  return (
    <Container>
      <h3> {id ? 'Edição de Coleta' : 'Nova Coleta'} </h3>
      <ReactTabs title={title} content={content} />
    </Container>
  )
}
