import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

export const ReactTabs = ({ title, content }) => {
  return (
    <Tabs forceRenderTabPanel={true}>
      <TabList>
        {title.map((values, index) => (
          <Tab key={index}>{values?.title}</Tab>
        ))}
      </TabList>

      {content.map((values, index) => (
        <TabPanel key={index}>{values?.component}</TabPanel>
      ))}
    </Tabs>
  )
}
