import axios from 'axios'
import { getToken } from './auth'

const Api = axios.create({
  baseURL: 'https://triangulodesktop.vbanet.com.br/',
  //baseURL: 'http://localhost:4501',
  timeout: 99999999,
})

Api.interceptors.request.use(async (config) => {
  const token = getToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

export default Api
