import styles from './Ocorrencia.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import {Table} from '../../../../components/Table'
import Button from '../../../../components/components-ui/Button/Button'
import {Link} from 'react-router-dom'
// import api from '../../../../services/Api'
// import Loading from '../../../../components/components-ui/Loading/Loading'
import {Grid, Col} from '../../../../components/Grid'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'

export default function Ocorrencia() {
  // const [showLoading, setShowLoading] = useState(false)
  const ValidationSchema = Yup.object().shape({
    local: Yup.string(),
    dataOcorrenciaDe: Yup.date(),
    dataOcorrenciaAte: Yup.date(),
    ocorrencia: Yup.string(),
  })
  //
  const columns = [
    {
      column: 'Nome Local',
    },
  ]
  return (
    <Container>
      <h3>Ocorrências</h3>
      <div className={styles.content}>
        <Link to="/usina/ocorrencia/novo">
          <Button title="Novo" type="button" />
        </Link>
        <Formik
          initialValues={{
            local: '',
            dataOcorrenciaDe: '',
            dataOcorrenciaAte: '',
            ocorrencia: '',
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values) => console.log(values)}
        >
          {({errors, touched}) => (
            <Form>
              <section className={styles.wrap}>
                <div className={styles.filter_header}>
                  <strong>Filtros</strong>
                  <Button title="Procurar" type="submit" />
                </div>
                <Grid>
                  <Col md={4}>
                    <label>Local</label>
                    <Field component="select" name="local">
                      <option value="">Selecione</option>
                    </Field>
                    {errors.local && touched.local ? (
                      <span className={styles.alertError}>{errors.local}</span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Data Ocorrência De</label>
                    <Field type="date" name="dataOcorrenciaDe" />
                    {errors.dataOcorrenciaDe && touched.dataOcorrenciaDe ? (
                      <span className={styles.alertError}>
                        {errors.dataOcorrenciaDe}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Data Ocorrência Até</label>
                    <Field type="date" name="dataOcorrenciaAte" />
                    {errors.dataOcorrenciaAte && touched.dataOcorrenciaAte ? (
                      <span className={styles.alertError}>
                        {errors.dataOcorrenciaAte}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={4}>
                    <label>Ocorrências</label>
                    <Field component="select" name="ocorrencia">
                      <option value="">Selecione</option>
                    </Field>
                    {errors.ocorrencia && touched.ocorrencia ? (
                      <span className={styles.alertError}>
                        {errors.ocorrencia}
                      </span>
                    ) : null}
                  </Col>
                </Grid>
              </section>
            </Form>
          )}
        </Formik>
        <Table edit columns={columns}>
          <tr>
            <td>
              <Link to="">
                <i className="fas fa-pencil-alt"></i>
              </Link>
            </td>
          </tr>
        </Table>
      </div>
    </Container>
  )
}
