import Container from '../../../../../components/components-ui/Container/Container'
import { Table } from '../../../../../components/Table'
export default function Relacionamento() {
  const columns = [
    {
      column: 'Data Contato',
    },
    {
      column: 'Histórico',
    },
    {
      column: 'Responsável',
    },
    {
      column: 'Status',
    },
  ]
  return (
    <Container>
      <Table columns={columns}></Table>
    </Container>
  )
}
