import styles from './ModalEndereco.module.css'
import Container from '../../../../../../components/components-ui/Container/Container'
import { Grid, Col } from '../../../../../../components/Grid'
import Button from '../../../../../../components/components-ui/Button/Button'
import { InputMasked } from '../../../../../../components/components-ui/InputMask/InputMask'
import axios from 'axios'
import { useState } from 'react'
export default function ModalEndereco() {
  const initialValues = {
    nome_local: '',
    cep: '',
    tipo_logradouro: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: '',
    estimativa_oleo: '',
  }
  const [dadosEndereco, setDadosEndereco] = useState(initialValues)

  function onchangeForm(ev) {
    const { name, value } = ev.target
    setDadosEndereco({
      ...dadosEndereco,
      [name]: value,
    })
  }
  async function handleCep(ev) {
    const value = ev.value.replace(/[^0-9]/g, '')
    if (value.length >= 8) {
      const response = await axios.get(
        `https://viacep.com.br/ws/${value}/json/`
      )
      setDadosEndereco({
        ...dadosEndereco,
        cidade: response.data.localidade,
        uf: response.data.uf,
      })
    }
  }
  return (
    <Container>
      <div className={styles.content}>
        <form onSubmit={(ev) => ev.preventDefault()}>
          <Grid>
            <Col md={12}>
              <label> Nome do Local </label>
              <input
                value={dadosEndereco.nome_local}
                type="text"
                name="nome_local"
                onChange={(ev) => {
                  handleCep(ev.target)
                }}
              />
            </Col>
          </Grid>
          <Grid>
            <Col md={2}>
              <label> CEP </label>
              <InputMasked
                mask="99999-999"
                value={dadosEndereco.cep}
                type="text"
                name="cep"
                onChange={(ev) => {
                  handleCep(ev.target)
                  onchangeForm(ev)
                }}
              />
            </Col>
            <Col md={3}>
              <label> Tipo Logradouro </label>
              <input
                name="tipo_logradouro"
                value={dadosEndereco.tipo_logradouro}
                onChange={(ev) => onchangeForm(ev)}
              />
              {/* <select
                name="tipo_logradouro"
                value={dadosEndereco.tipo_logradouro}
                onChange={(ev) => onchangeForm(ev)}
              >
                <option> Tipo </option>
              </select> */}
            </Col>

            <Col md={6}>
              <label> Logradouro </label>
              <input
                value={dadosEndereco.logradouro}
                type="text"
                name="logradouro"
                onChange={(ev) => onchangeForm(ev)}
              />
            </Col>
            <Col md={1}>
              <label> N° </label>
              <input
                value={dadosEndereco.numero}
                type="text"
                name="numero"
                onChange={(ev) => onchangeForm(ev)}
              />
            </Col>
          </Grid>
          <Grid>
            <Col md={5}>
              <label> Complemento </label>
              <input
                value={dadosEndereco.complemento}
                type="text"
                name="complemento"
                onChange={(ev) => onchangeForm(ev)}
              />
            </Col>
            <Col md={3}>
              <label> Bairro </label>
              <input
                value={dadosEndereco.bairro}
                type="text"
                name="bairro"
                onChange={(ev) => onchangeForm(ev)}
              />
            </Col>
            <Col md={3}>
              <label> Cidade </label>
              <input
                value={dadosEndereco.cidade}
                type="text"
                name="cidade"
                onChange={(ev) => onchangeForm(ev)}
              />
            </Col>
            <Col md={1}>
              <label> UF </label>
              <input
                value={dadosEndereco.uf}
                type="text"
                name="uf"
                onChange={(ev) => onchangeForm(ev)}
              />
            </Col>
          </Grid>
          <Grid>
            <Col md={12}>
              <label> Estimativa Óleo </label>
              <input
                value={dadosEndereco.estimativa_oleo}
                type="text"
                name="estimativa_oleo"
                onChange={(ev) => onchangeForm(ev)}
              />
            </Col>
          </Grid>
          <Grid>
            <Col md={12}>
              <Button
                title="Salvar"
                onClick={() => console.log(dadosEndereco)}
              />
            </Col>
          </Grid>
        </form>
      </div>
    </Container>
  )
}
