import styles from './TopMenu.module.css'
import { AuthContext } from '../../Provider/AuthContext'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import OptionDropdown from './OptionDropDown'
function TopMenu({ leave }) {
  const { idTipoUsuario } = useContext(AuthContext)
  //
  const links = [
    {
      title: 'Usuarios',
      icon: 'fas fa-user',
      link: '/usina/usuarios',
    },
  ]

  const dropdowns = [
    //Links que não entrarão no dropdown
    //Links do dropdown
    {
      title: 'Cadastro',
      icon: 'fas fa-user-plus',
      option: [
        {
          title: 'Prospecção',
          icon: 'fas fa-map',
          link: '/usina/prospect',
        },
        {
          title: 'Locais',
          icon: 'fas fa-map',
          link: '/usina/local',
        },
        {
          title: 'Embaixador',
          icon: 'fas fa-chart-line',
          link: '/usina/embaixador',
        },
        {
          title: 'Preposto',
          icon: 'fas fa-chart-line',
          link: '/usina/preposto',
        },
      ],
    },
    {
      title: 'Ligação',
      icon: 'fas fa-phone-alt',
      option: [
        {
          title: 'Painel de Ligação',
          icon: 'fas fa-map',
          link: '/usina/painel-de-ligacao',
        },
        {
          title: 'Dash Ligações',
          icon: 'fas fa-map',
          link: '/usina/dash-ligacao',
        },
      ],
    },

    {
      title: 'Coletas',
      icon: 'fas fa-plus',
      option: [
        {
          title: 'Emissão de Coletas',
          icon: 'fas fa-map',
          link: '/usina/emissao-de-coleta',
        },
        {
          title: 'Ocorrencia',
          icon: 'fas fa-map',
          link: '/usina/ocorrencia',
        },
      ],
    },

    //Links do dropdown que tem opções com dropdown
    {
      title: 'Relatórios',
      icon: 'fas fa-receipt',
      option: [],
      suboptions: [
        {
          subtitle: 'Geral',
          suboption: [
            {
              title: 'Locais',
              icon: 'fas fa-map',
              link: '/usina/',
            },
          ],
        },
        {
          subtitle: 'Operação',
          suboption: [
            {
              title: 'Usina',
              icon: 'fas fa-map',
              link: '/usina/',
            },
          ],
        },
      ],
    },
    {
      title: 'Tabela',
      icon: 'fas fa-table',
      option: [
        {
          title: 'Material',
          icon: 'fas fa-boxes',
          link: '/usina/material',
        },
        {
          title: 'Grupo',
          icon: 'fas fa-layer-group',
          link: '/usina/grupo',
        },
        {
          title: 'Campanha',
          icon: 'fas fa-chart-line',
          link: '/usina/campanha',
        },
        {
          title: 'Brinde',
          icon: 'fas fa-chart-line',
          link: '/usina/brinde',
        },
        {
          title: 'Bombona',
          icon: 'fas fa-chart-line',
          link: '/usina/bombona',
        },
        {
          title: 'Estabelecimento',
          icon: 'fas fa-clipboard-list',
          link: '/usina/estabelecimento',
        },

        {
          title: 'Cidade',
          icon: 'fas fa-chart-line',
          link: '/usina/cidade',
        },
        {
          title: 'Preferencia Contato',
          icon: 'fas fa-chart-line',
          link: '/usina/preferencia-contato',
        },

        {
          title: 'Cadastrar Motorista',
          icon: 'fas fa-chart-line',
          link: '/usina/cadastrar-motorista',
        },
        {
          title: 'Tipo Logradouro',
          icon: 'fas fa-chart-line',
          link: '/usina/tipo-logradouro',
        },
        {
          title: 'Veiculos',
          icon: 'fas fa-chart-line',
          link: '/usina/veiculo',
        },
      ],
    },
  ]

  function validateRouter() {
    if (idTipoUsuario === 5) {
      return {
        links,
        dropdowns,
      }
    }
    return
  }
  const routes = validateRouter()
  //
  return (
    <div className={styles.top_menu}>
      <div className={styles.row}>
        {/** Dropdown */}

        {routes?.dropdowns?.map((values, index) => (
          <OptionDropdown
            key={index}
            option={values.option}
            title={values.title}
            suboption={values.suboptions}
            icon={values.icon}
          />
        ))}

        {/** Links sem dropdown */}
        {routes?.links?.map((values, index) => (
          <div key={index} className={styles.option}>
            <Link to={values?.link}>
              <i className={values?.icon}></i>
              {values?.title}
            </Link>
          </div>
        ))}

        {/** Rotas genericas */}
        <div className={styles.option}>
          <Link
            to={{
              pathname: `https://api.whatsapp.com/send?phone=5511983279221&text=Olá,
              Necessito de um suporte`,
            }}
            target="_blank"
          >
            <i className="fab fa-whatsapp-square"></i>
            Suporte
          </Link>
        </div>
        <div className={styles.option}>
          <Link to="/" onClick={() => leave()}>
            <i className="fas fa-sign-out-alt"></i>
            Sair
          </Link>
        </div>
      </div>
    </div>
  )
}

export default TopMenu
