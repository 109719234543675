import styles from './Home.module.css'
import Header from '../../components/Header/Header'
import SideMenu from '../../components/SideMenu'
import TopMenu from '../../components/TopMenu'
import Main from '../../components/Main/Main'
import { AuthContext } from '../../Provider/AuthContext'
import { logout as exit, isAuthenticated } from '../../services/auth'
import { BrowserRouter as Router } from 'react-router-dom'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

export default function Home() {
  const { logout, idTipoUsuario } = useContext(AuthContext)
  const history = useHistory()
  const [showMenu, setShowMenu] = useState(true)

  const logged = isAuthenticated()
  if (logged === false) {
    history.push('/')
  }

  function leave() {
    exit()
    logout()
    history.push('/')
  }

  function Content() {
    if (idTipoUsuario === 5) {
      return (
        <div className={styles.container}>
          <Header onClick={() => setShowMenu(!showMenu)} color="0" />
          <TopMenu leave={leave} />
          <section className={styles.contentUserTipo5}>
            <Main />
          </section>
        </div>
      )
    }
    return (
      <div className={styles.container}>
        <Header onClick={() => setShowMenu(!showMenu)} color="1" />
        <section className={styles.content}>
          <div className={showMenu ? styles.closeMenu : styles.showMenu}>
            <SideMenu onClick={() => setShowMenu(!showMenu)} leave={leave} />
          </div>
          <Main />
        </section>
      </div>
    )
  }

  return (
    <Router>
      <Content />
    </Router>
  )
}
