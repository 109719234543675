import styles from './RelatorioDeColeta.module.css'
//import vv from '../../../../../
import Button from '../../../../../components/components-ui/Button/Button'
import DatePicker from '../../../../../components/components-ui/DatePicker/DatePicker'
import api from '../../../../../services/Api'
import { useState, useEffect } from 'react'
import Loading from '../../../../../components/components-ui/Loading/Loading'
// import Select from '../../../../../components/components-ui/Select/Select'
import Container from '../../../../../components/components-ui/Container/Container'
import { Link } from 'react-router-dom'

import ExportCSV from '../../../../../services/ExportCSV'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function RelatorioDeColeta() {
  const [dataInicial, setDataInicial] = useState('')
  const [dataFinal, setDataFinal] = useState('')
  const [idSelect, setIdSelect] = useState(0)
  const [groups, setGroups] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  const [showLink, setShowLink] = useState(false)
  const [download, setDownload] = useState([])

  function showAlert(notify, msg) {
    if (notify === 0) {
      toast.success(msg)
    } else {
      toast.error(msg)
    }
  }
  // Preenche o select
  useEffect(() => {
    const loadOption = async () => {
      const response = await api.get('/local/lista')
      setGroups(response.data)
    }
    loadOption()
  }, [])

  async function getListaRelatorio() {
    setShowLoading(true)



    const data = {
      datade: dataInicial,
      dataate: dataFinal
    }

    //console.log(data)
    const response = await api.post(
      '/preposto/relatorio/emitidorealizado',
      data
    )
    if (response.status === 401) {
      showAlert(1, 'Ocorreu algum erro')
      setShowLoading(false)
      return
    }
    setShowLoading(false)
    setShowLink(true)
    setDownload(response.data)

    // showAlert(1, 'Preencha todos os campos')
    // setShowLoading(false)
  }
  return (
    <Container>
      <ToastContainer />
      <div className={styles.content}>
        <h3> Relatórios de Coleta Emitido Vs Realizado</h3>
        <div className={styles.form}>
          <form onSubmit={(ev) => ev.preventDefault()}>

            <section>
              <DatePicker
                id="dateStart"
                label="Data Inicial"
                value={dataInicial}
                onChange={(e) => setDataInicial(e.target.value)}
              />
            </section>
            <section>
              <DatePicker
                id="dateFinish"
                label="Data Final"
                value={dataFinal}
                onChange={(e) => setDataFinal(e.target.value)}
              />
            </section>
            <div className={styles.loading}>
              <Loading display={showLoading ? 'block' : 'none'} />
            </div>
            <section className={styles.sectionButton}>
              {showLink ? (
                <Link
                  className={styles.link}
                  onClick={() => {
                    setShowLink(false)
                  }}
                  to="#"
                >
                  <ExportCSV
                    nameButton="Baixar Excel"
                    fileName="relatorio"
                    csvData={download}
                  />
                </Link>
              ) : (
                <Button
                  onClick={getListaRelatorio}
                  display={showLoading || showLink ? 'none' : 'block'}
                  title="Gerar Excel"
                  type="submit"
                />
              )}
            </section>
          </form>
        </div>
      </div>
    </Container>
  )
}
