import styles from './PainelDeLigacao.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import {Formik, Form, Field} from 'formik'
import {useState} from 'react'
import {Link} from 'react-router-dom'
import Modal from '../../../../components/Modal'
import DetalhesDoLocal from './DetalhesDoLocal'
import {Grid, Col} from '../../../../components/Grid'
import {InputMasked} from '../../../../components/components-ui/InputMask/InputMask'
import {Table} from '../../../../components/Table'
function PainelDeLigacao() {
  const [showModal, setShowModal] = useState(false)
  const columns = [
    {
      column: 'CEP',
    },
    {
      column: 'Cód. Local',
    },
    {
      column: 'Nome Local',
    },
    {
      column: 'Razão Social',
    },
    {
      column: 'Média 6 Meses',
    },
    {
      column: 'Data Último Contato',
    },
    {
      column: 'Com quem falou',
    },
    {
      column: 'Retorno',
    },
    {
      column: 'Data Última Coleta',
    },
    {
      column: 'Data Prev. Próx. Ligação',
    },
    {
      column: 'Volume Coletado',
    },
    {
      column: 'Nome Atendente',
    },
  ]
  return (
    <Container>
      <h3> Painel de Ligação </h3>
      <div className={styles.content}>
        <Formik
          initialValues={{
            codigoPonto: '',
            razaoSocial: '',
            estabelecimento: '',
            status: 3,
            grupo: '',
          }}
          //validationSchema={ValidationSchema}
          onSubmit={(values) => console.log(values)}
        >
          {({errors, touched}) => (
            <Form>
              <section className={styles.wrap}>
                <div className={styles.filter_header}>
                  <strong>Filtros</strong>
                </div>
                <Grid>
                  <Col md={2}>
                    <label>Faixa CEP de</label>
                    {/* <Field type="number" name="faixaCepDe" /> */}
                    <Field name="faixaCepDe">
                      {({field}) => <InputMasked {...field} mask="99999-999" />}
                    </Field>
                    {errors.faixaCepDe && touched.faixaCepDe ? (
                      <span className={styles.alertError}>
                        {errors.faixaCepDe}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Faixa CEP Até</label>
                    {/* <Field type="number" name="faixaCepAte" /> */}
                    <Field name="faixaCepAte">
                      {({field}) => <InputMasked {...field} mask="99999-999" />}
                    </Field>
                    {errors.faixaCepAte && touched.faixaCepAte ? (
                      <span className={styles.alertError}>
                        {errors.faixaCepAte}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Cod. Local</label>
                    <Field type="text" name="codLocal" />
                    {errors.codLocal && touched.codLocal ? (
                      <span className={styles.alertError}>
                        {errors.codLocal}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={3}>
                    <label>Local</label>
                    <Field type="text" name="local" />
                    {errors.local && touched.local ? (
                      <span className={styles.alertError}>{errors.local}</span>
                    ) : null}
                  </Col>
                  <Col md={3}>
                    <label>Endereço Local</label>
                    <Field type="text" name="enderecoLocal" />
                    {errors.enderecoLocal && touched.enderecoLocal ? (
                      <span className={styles.alertError}>
                        {errors.enderecoLocal}
                      </span>
                    ) : null}
                  </Col>
                </Grid>
                <Grid>
                  <Col md={2}>
                    <label>Retorno</label>
                    <Field component="select" name="retorno">
                      <option value="">Selecione</option>
                    </Field>
                    {errors.retorno && touched.retorno ? (
                      <span className={styles.alertError}>
                        {errors.retorno}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={3}>
                    <label>Estabelecimento</label>
                    <Field component="select" name="estabelecimento">
                      <option value="">Selecione</option>
                    </Field>
                    {errors.estabelecimento && touched.estabelecimento ? (
                      <span className={styles.alertError}>
                        {errors.estabelecimento}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Grupo</label>
                    <Field component="select" name="grupo">
                      <option value="">Selecione</option>
                    </Field>
                    {errors.grupo && touched.grupo ? (
                      <span className={styles.alertError}>{errors.grupo}</span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Grupo</label>
                    <Field component="select" name="status">
                      <option value="">Selecione</option>
                    </Field>
                    {errors.status && touched.status ? (
                      <span className={styles.alertError}>{errors.status}</span>
                    ) : null}
                  </Col>
                  <Col md={3}>
                    <label>Média De</label>
                    <Field type="number" name="mediaDe" />
                    {errors.mediaDe && touched.mediaDe ? (
                      <span className={styles.alertError}>
                        {errors.mediaDe}
                      </span>
                    ) : null}
                  </Col>
                </Grid>
                <Grid>
                  <Col md={3}>
                    <label>Média Até</label>
                    <Field type="number" name="mediaDe" />
                    {errors.mediaDe && touched.mediaDe ? (
                      <span className={styles.alertError}>
                        {errors.mediaDe}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Dt.Px. Ligação De</label>
                    <Field type="date" name="dtPxLigacaoDe" />
                    {errors.dtPxLigacaoDe && touched.dtPxLigacaoDe ? (
                      <span className={styles.alertError}>
                        {errors.dtPxLigacaoDe}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Dt.Prx. Ligação Até</label>
                    <Field type="date" name="dtPrxLigacaoAte" />
                    {errors.dtPrxLigacaoAte && touched.dtPrxLigacaoAte ? (
                      <span className={styles.alertError}>
                        {errors.dtPrxLigacaoAte}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Dt. Ul. Coleta De</label>
                    <Field type="date" name="dtUlColetaDe" />
                    {errors.dtUlColetaDe && touched.dtUlColetaDe ? (
                      <span className={styles.alertError}>
                        {errors.dtUlColetaDe}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={3}>
                    <label>Dt. Ul. Coleta Até</label>
                    <Field type="date" name="dtUlColetaAte" />
                    {errors.dtUlColetaAte && touched.dtUlColetaAte ? (
                      <span className={styles.alertError}>
                        {errors.dtUlColetaAte}
                      </span>
                    ) : null}
                  </Col>
                </Grid>
                <Grid>
                  <Col md={2}>
                    <label>Dt. Ul. Ligação De</label>
                    <Field type="date" name="dtUlLigacaoDe" />
                    {errors.dtUlLigacaoDe && touched.dtUlLigacaoDe ? (
                      <span className={styles.alertError}>
                        {errors.dtUlLigacaoDe}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Dt. Ul. Ligação Até</label>
                    <Field type="date" name="dtUlLigacaoAte" />
                    {errors.dtUlLigacaoAte && touched.dtUlLigacaoAte ? (
                      <span className={styles.alertError}>
                        {errors.dtUlLigacaoAte}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={3}>
                    <label>Status Coleta</label>
                    <Field component="select" name="statusColeta">
                      <option value="">Selecione</option>
                    </Field>
                    {errors.statusColeta && touched.statusColeta ? (
                      <span className={styles.alertError}>
                        {errors.statusColeta}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={3}>
                    <label>Tipo Contato</label>
                    <Field component="select" name="tipoContato">
                      <option value="">Selecione</option>
                    </Field>
                    {errors.tipoContato && touched.tipoContato ? (
                      <span className={styles.alertError}>
                        {errors.tipoContato}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Campanha</label>
                    <Field component="select" name="campanha">
                      <option value="">Selecione</option>
                    </Field>
                    {errors.campanha && touched.campanha ? (
                      <span className={styles.alertError}>
                        {errors.campanha}
                      </span>
                    ) : null}
                  </Col>
                </Grid>
              </section>
            </Form>
          )}
        </Formik>
        <Table edit columns={columns}>
          <tr>
            <td>
              <Link to="#" onClick={() => setShowModal(true)}>
                <i className="fas fa-pencil-alt"></i>
              </Link>
            </td>
          </tr>
        </Table>
      </div>
      <Modal
        titleModal="Detalhes Local"
        display={showModal}
        onClick={() => setShowModal(false)}
      >
        <DetalhesDoLocal />
      </Modal>
    </Container>
  )
}

export default PainelDeLigacao
