import styles from './NovaColeta.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import Button from '../../../../components/components-ui/Button/Button'
import api from '../../../../services/Api'
import { AuthContext } from '../../../../Provider/AuthContext'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { useContext } from 'react'

export default function NovaColeta() {
  const { nome_usuario } = useContext(AuthContext)
  const history = useHistory()
  // LINKS
  const API_CRIAR_COLETA = '/mob/coleta/create'
  // ALERT PERSONALIZADO
  function showAlert(notify, msg) {
    if (notify === 0) {
      toast.success(msg)
    } else {
      toast.error(msg)
    }
  }
  // CRIA NOVA COLETA
  async function salvarColeta(values, resetForm) {
    const data = {
      nome_solicitante: values?.nomeSolicitante,
      estimativa_oleo_kg: values?.estimaOleo,
      oleo_observacao: values?.observacaoOleo,
      requisitar_material_observacao: values?.requisitarMaterialObs,
      troca_bombona_observacao: values?.trocaBombonaObs,
    }
    const response = await api.post(API_CRIAR_COLETA, data)
    if (response.data.error === 0) {
      showAlert(0, 'Solicitação realizada com sucesso')
      history.push('/home')
      resetForm()
    } else {
      showAlert(1, 'Error ao realizar solicitação')
    }
  }
  // SCHEEMA DE VALIDAÇÃO DE FORMULÁRIO
  const formValidation = Yup.object().shape({
    nomeSolicitante: Yup.string().required('*Campo Obrigatório'),
    estimaOleo: Yup.number()
      .positive('Digite um valor positivo')
      .required('*Campo Obrigatório'),
    observacaoOleo: Yup.string(),
    requisitarMaterialObs: Yup.string(),
    trocaBombonaObs: Yup.string(),
  })
  return (
    <Container>
      <ToastContainer />
      <h3>Solicitar Coleta</h3>
      <section className={styles.content}>
        <Formik
          initialValues={{
            nomeSolicitante: nome_usuario ? nome_usuario : '',
            estimaOleo: '',
            observacaoOleo: '',
            requisitarMaterialObs: '',
            trocaBombonaObs: '',
          }}
          validationSchema={formValidation}
          onSubmit={(values, { resetForm }) => salvarColeta(values, resetForm)}
          enableReinitialize={true}
        >
          {({ errors, touched }) => (
            <Form>
              <div className={styles.field_wrapper}>
                <strong>Solicitante</strong>
                <Field name="nomeSolicitante" type="text" />
              </div>
              {errors.nomeSolicitante && touched.nomeSolicitante ? (
                <span className={styles.alertError}>
                  {errors.nomeSolicitante}
                </span>
              ) : null}

              <div className={styles.row}>
                <strong>Coleta de Óleo</strong>
                <div style={{ width: 130 }} className={styles.col_1}>
                  <div className={styles.field_wrapper}>
                    <span>Qtd. (Kg)</span>
                    <Field name="estimaOleo" type="number" />
                  </div>
                </div>
                <div className={styles.col_6}>
                  <div className={styles.field_wrapper}>
                    <span>Observação</span>
                    <Field name="observacaoOleo" type="text" />
                  </div>
                </div>
              </div>
              <p>
                {errors.estimaOleo && touched.estimaOleo ? (
                  <span className={styles.alertError}>{errors.estimaOleo}</span>
                ) : null}
              </p>

              <div className={styles.field_wrapper}>
                <strong>Requisitar Material</strong>
                <span>Observação</span>
                <Field
                  component="textarea"
                  name="requisitarMaterialObs"
                  cols="30"
                  rows="3"
                />
              </div>

              <div className={styles.field_wrapper}>
                <strong>Troca de Bombonas</strong>
                <span>Observação</span>
                <Field
                  component="textarea"
                  name="trocaBombonaObs"
                  cols="30"
                  rows="3"
                />
              </div>

              <section className={styles.btns}>
                <Button title="Salvar" type="submit" />
                <Button
                  color="secondary"
                  onClick={() => {
                    window.history.back()
                  }}
                  title="Voltar"
                  type="button"
                />
              </section>
            </Form>
          )}
        </Formik>
      </section>
    </Container>
  )
}
