import styles from './Grid.module.css'
import {Row as Rows, Col as Column} from 'react-flexa'
import 'grid-react/dist/style.css'

export function Col({children, ...rest}) {
  return (
    <Column {...rest}>
      <div className={styles.field_column}>{children}</div>
    </Column>
  )
}
export function Grid(props) {
  return <Rows>{props.children}</Rows>
}
