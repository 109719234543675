import styles from './Locais.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import Button from '../../../../components/components-ui/Button/Button'
import Loading from '../../../../components/components-ui/Loading/Loading'
import {Table} from '../../../../components/Table'
import {Grid, Col} from '../../../../components/Grid'
import {InfoSeachContext} from '../../../../Provider/InfoSearch'
import api from '../../../../services/Api'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import {useContext, useEffect, useState} from 'react'

export default function Local() {
  const [listaLocal, setListaLocal] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  const [status, setStatus] = useState([])
  const [grupo, setGrupo] = useState([])
  const [estabelecimento, setEstabelecimento] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [filterTable, setFilterTable] = useState('')
  const [totalPages, setTotalPages] = useState(0)

  const {setDataLocalSearch, dataLocalSearch} = useContext(InfoSeachContext)
  useEffect(() => {
    async function getLocal() {
      setShowLoading(true)
      setListaLocal([])
      let response = ''
      if (filterTable) {
        response = await api.get(
          `/local/index?page=${currentPage}${filterTable}`
        )
      } else {
        response = await api.get(`/local/index?page=${currentPage}&id_status=3`)
      }
      setTotalPages(response.data.totalPages)
      setListaLocal(response.data.data)
      setCurrentPage(response.data.currentPageNumber)
      setShowLoading(false)
    }
    getLocal()
  }, [currentPage, filterTable])

  function filterList(values) {
    let data = `${
      values?.codigoPonto ? `&id_local=${values?.codigoPonto}` : ''
    }`
    data =
      data +
      `${values?.razaoSocial ? `&nome_local=${values?.razaoSocial}` : ''}`
    data = data + `${values?.status ? `&id_status=${values?.status}` : ''}`
    data = data + `${values?.grupo ? `&id_grupo=${values?.grupo}` : ''}`
    data =
      data +
      `${
        values?.estabelecimento
          ? `&id_estabelecimento=${values?.estabelecimento}`
          : ''
      }`
    setCurrentPage(1)
    setFilterTable(data)

    setDataLocalSearch({
      codigoPonto: values?.codigoPonto,
      razaoSocial: values?.razaoSocial,
      numeroColeta: values?.numeroColeta,
      status: values?.status,
      grupo: values?.grupo,
      estabelecimento: values?.estabelecimento,
    })
  }

  useEffect(() => {
    let data = `${
      dataLocalSearch?.codigoPonto
        ? `&id_local=${dataLocalSearch?.codigoPonto}`
        : ''
    }`
    data =
      data +
      `${
        dataLocalSearch?.razaoSocial
          ? `&nome_local=${dataLocalSearch?.razaoSocial}`
          : ''
      }`
    data =
      data +
      `${
        dataLocalSearch?.status ? `&id_status=${dataLocalSearch?.status}` : ''
      }`
    data =
      data +
      `${dataLocalSearch?.grupo ? `&id_grupo=${dataLocalSearch?.grupo}` : ''}`
    data =
      data +
      `${
        dataLocalSearch?.estabelecimento
          ? `&id_estabelecimento=${dataLocalSearch?.estabelecimento}`
          : ''
      }`
    setFilterTable(data)
  }, [dataLocalSearch])

  //
  useEffect(() => {
    // Carrega Status
    async function getStatus() {
      const response = await api.get(`/local/status/index`)
      setStatus(response.data)
    }
    getStatus()
    // Carrega Grupo
    async function getGrupo() {
      const response = await api.get(`/grupo/index`)
      setGrupo(response.data)
    }
    getGrupo()
    // Carregar Estabelecimento
    async function getEstabelecimento() {
      const response = await api.get(`/estabelecimento/index?`)
      setEstabelecimento(response.data)
    }
    getEstabelecimento()
  }, [])

  function previewPage() {
    if (currentPage === 1) {
      setCurrentPage(1)
    } else {
      setCurrentPage(currentPage - 1)
    }
  }

  function nextPage() {
    if (currentPage === totalPages) {
      setCurrentPage(totalPages)
    } else {
      setCurrentPage(currentPage + 1)
    }
  }

  const ValidationSchema = Yup.object().shape({
    codigoPonto: Yup.number(),
    razaoSocial: Yup.string(),
    estabelecimento: Yup.string(),
    status: Yup.string(),
    grupo: Yup.string(),
  })
  const columns = [
    {
      column: 'Cód. Local',
    },
    {
      column: 'Nome Local',
    },
    {
      column: 'Grupo',
    },
    {
      column: 'Status',
    },
  ]

  return (
    <Container>
      <h3>Locais</h3>

      <div className={styles.content}>
        <Link to="/usina/action-painel/">
          <Button title="Novo" type="button" />
        </Link>
        <Formik
          initialValues={{
            codigoPonto: dataLocalSearch?.codigoPonto
              ? dataLocalSearch?.codigoPonto
              : '',
            razaoSocial: dataLocalSearch?.razaoSocial
              ? dataLocalSearch?.razaoSocial
              : '',
            estabelecimento: dataLocalSearch?.estabelecimento
              ? dataLocalSearch?.estabelecimento
              : '',
            status: dataLocalSearch?.status ? dataLocalSearch?.status : '',
            grupo: dataLocalSearch?.grupo ? dataLocalSearch?.grupo : '',
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values) => filterList(values)}
        >
          {({errors, touched}) => (
            <Form>
              <section className={styles.wrap}>
                <div className={styles.filter_header}>
                  <strong>Filtros</strong>
                  <Button title="Procurar" type="submit" />
                </div>
                <Grid>
                  <Col md={2}>
                    <label>Cód. Ponto</label>
                    <Field type="number" name="codigoPonto" />
                    {errors.codigoPonto && touched.codigoPonto ? (
                      <span className={styles.alertError}>
                        {errors.codigoPonto}
                      </span>
                    ) : null}
                  </Col>

                  <Col md={4}>
                    <label>Razão Social</label>
                    <Field type="text" name="razaoSocial" />
                    {errors.razaoSocial && touched.razaoSocial ? (
                      <span className={styles.alertError}>
                        {errors.razaoSocial}
                      </span>
                    ) : null}
                  </Col>

                  <Col md={2}>
                    <label>Status</label>
                    <Field component="select" name="status">
                      <option value="">Selecione</option>
                      {status.map((values) => (
                        <option
                          key={values?.id_status}
                          value={values?.id_status}
                        >
                          {values?.status}
                        </option>
                      ))}
                    </Field>
                    {errors.status && touched.status ? (
                      <span className={styles.alertError}>{errors.status}</span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Estabelecimento</label>
                    <Field component="select" name="estabelecimento">
                      <option value="">Selecione</option>
                      {estabelecimento.map((values) => (
                        <option
                          key={values?.id_estabelecimento}
                          value={values?.id_estabelecimento}
                        >
                          {values?.estabelecimento}
                        </option>
                      ))}
                    </Field>
                    {errors.estabelecimento && touched.estabelecimento ? (
                      <span className={styles.alertError}>
                        {errors.estabelecimento}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Grupo</label>
                    <Field component="select" name="grupo">
                      <option value="">Selecione</option>
                      {grupo.map((values) => (
                        <option key={values?.id_grupo} value={values?.id_grupo}>
                          {values?.descricao_grupo}
                        </option>
                      ))}
                    </Field>
                    {errors.grupo && touched.grupo ? (
                      <span className={styles.alertError}>{errors.grupo}</span>
                    ) : null}
                  </Col>
                </Grid>
              </section>
            </Form>
          )}
        </Formik>
        {/*  */}

        <Table edit columns={columns}>
          {listaLocal.map((values) => (
            <tr key={values?.id_local}>
              <td>
                <Link to={`/usina/action-painel/edit/${values?.id_local}`}>
                  <i className="fas fa-pencil-alt"></i>
                </Link>
              </td>
              <td>{values?.id_local}</td>
              <td style={{textAlign: 'left'}}>{values?.nome_local}</td>
              <td style={{textAlign: 'left'}}>{values?.grupo}</td>
              <td style={{textAlign: 'left'}}>{values?.status}</td>
            </tr>
          ))}
        </Table>
        <div className={styles.loading}>
          <Loading display={showLoading ? 'block' : 'none'} />
        </div>
        <div className={styles.table_pagination}>
          <div className={styles.table_pagination_buttons}>
            <Button
              disabled={currentPage === 1 ? true : false}
              onClick={() => previewPage()}
            >
              <i style={{marginRight: 5}} className="fas fa-chevron-left"></i>
              Voltar
            </Button>
            <span>
              {currentPage} De {totalPages}
            </span>
            <Button
              disabled={
                currentPage === totalPages || totalPages === 0 ? true : false
              }
              onClick={() => nextPage()}
            >
              Próxima
              <i style={{marginLeft: 5}} className="fas fa-chevron-right"></i>
            </Button>
          </div>
        </div>
      </div>
      {/*  */}
    </Container>
  )
}
