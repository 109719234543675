import { createContext, useState } from 'react'

export const InfoSeachContext = createContext({})

export function InfoSeachProvider({ children }) {
  const [dataColetaSearch, setDataColetaSearch] = useState([])
  const [dataLocalSearch, setDataLocalSearch] = useState([])

  return (
    <InfoSeachContext.Provider
      value={{ setDataColetaSearch, dataColetaSearch, setDataLocalSearch, dataLocalSearch }}>
      {children}
    </InfoSeachContext.Provider>
  )
}
