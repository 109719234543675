import jwt from 'jsonwebtoken'

export const TOKEN_KEY = 'app-token'
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null
export const getToken = () => localStorage.getItem(TOKEN_KEY)

export const login = (token) => {
  localStorage.setItem(TOKEN_KEY, token)
}
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem('user-data')
}
export const infoUser = () => {
  const userDataJWT = localStorage.getItem('user-data')
  const token = getToken()
  const userInfo = jwt.decode(token)
  const userData = jwt.decode(userDataJWT)
  return {
    nome_usuario: userInfo?.nome_usuario,
    id_tipo_usuario: userInfo?.id_tipo_usuario,
    preposto: userInfo?.preposto,
    link_whatsapp: userInfo?.url_ocorrencia_whats_app,
    app_nome: userData?.appNome,
    app_logo: userData?.appLogo,
    id_emite_mtr: userData?.idEmiteMtr,
    id_tipo_contrapartida: userInfo?.id_tipo_contrapartida
  }
}
export const isTokenExpired = () => {
  const token = getToken()
  try {
    const date = new Date(0)
    const decoded = jwt.decode(token)
    date.setUTCSeconds(decoded.exp)
    return date.valueOf() > new Date().valueOf() ? false : true
  } catch (err) {
    return true
  }
}
