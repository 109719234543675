import styles from './Cdf.module.css'
import Container from '../../../../components/components-ui/Container/Container'
export default function Cdf() {
  return (
    <Container>
      <div className={styles.content}>
        <h3> Aguarde a sincronização... </h3>
      </div>
    </Container>
  )
}
