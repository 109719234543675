import styles from '../Tabelas.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import { Table } from '../../../../components/Table'
import Button from '../../../../components/components-ui/Button/Button'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import api from '../../../../services/Api'
import Loading from '../../../../components/components-ui/Loading/Loading'

export default function TipoLogradouro() {
  const [listLogradouro, setListLogradouro] = useState([])
  const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    async function getTipoLogradouro() {
      setShowLoading(true)
      const response = await api.get('/logradourotipo/index')
      setListLogradouro(response.data)
      setShowLoading(false)
    }
    getTipoLogradouro()
  }, [])

  // Colunas da tabela
  const columns = [
    {
      column: 'Tipo de Logradouro',
    },
  ]

  return (
    <Container>
      <h3>Listagem de Tipo Logradouro</h3>
      <Link to="/usina/tipo-logradouro/novo/">
        <Button title="Novo" type="button" />
      </Link>

      <div className={styles.content}>
        <Table edit columns={columns}>
          {listLogradouro.map(({ id_tipo_logradouro, tipo_logradouro }) => (
            <tr key={id_tipo_logradouro}>
              <td>
                <Link to={`/usina/tipo-logradouro/edit/${id_tipo_logradouro}`}>
                  <i className="fas fa-pencil-alt"></i>
                </Link>
              </td>
              <td>{tipo_logradouro}</td>
            </tr>
          ))}
        </Table>
        <div className={styles.loading}>
          <Loading display={showLoading ? 'block' : 'none'} />
        </div>
      </div>
    </Container>
  )
}
