import styles from '../Tabelas.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import Button from '../../../../components/components-ui/Button/Button'
import { Formik, Form, Field } from 'formik'
export default function CadastrarMotorista() {
  return (
    <Container>
      <h3> Cadastro de Motorista </h3>
      <section className={styles.content}>
        <Formik>
          {({ errors, touched }) => (
            <Form>
              <div className={styles.field_column}>
                <label> Nome Motorista </label>
                <Field type="text" name="nomeMotorista" />
              </div>
              <div className={styles.field_column}>
                <Button title="Salvar" />
              </div>
            </Form>
          )}
        </Formik>
      </section>
    </Container>
  )
}
