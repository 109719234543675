import styles from './Certificado.module.css'
import DatePicker from '../../../components/components-ui/DatePicker/DatePicker'
import Button from '../../../components/components-ui/Button/Button'
import api from '../../../services/Api'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Loading from '../../../components/components-ui/Loading/Loading'
import Container from '../../../components/components-ui/Container/Container'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function Certificado() {
  const [dataInicial, setDataInicial] = useState('')
  const [dataFinal, setDataFinal] = useState('')
  const [geraCertificado, setGeraCertificado] = useState({})
  const [showLink, setShowLink] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [showButton, setShowButton] = useState(false)
  // LINKS
  const API_GERAR_CERTIFICADO = 'mob/coleta/certificadonovo'
  // ALERT PERSONALIZADO
  function showAlert(notify, msg) {
    if (notify === 0) {
      toast.success(msg)
    } else {
      toast.error(msg)
    }
  }
  // GERA CERTIFICADO
  async function getCertificado() {
    setShowLoading(true)
    setShowButton(true)
    if (dataInicial.length !== 0 && dataFinal.length !== 0) {
      const datas = { data_de: dataInicial, data_ate: dataFinal }
      const response = await api.post(API_GERAR_CERTIFICADO, datas)
      if (response.status === 401) {
        showAlert(1, 'Ocorreu algum erro, tente novamente')
      } else {
        const dadosCertificado = await response.data
        setGeraCertificado(dadosCertificado)
        setShowLink(true)
        setShowLoading(false)
      }
    } else {
      showAlert(1, 'Preencha todos os campos')
      setShowLoading(false)
      setShowButton(false)
    }
  }

  return (
    <Container>
      <ToastContainer />
      <h3> Certificado </h3>
      <div className={styles.form}>
        <div className={styles.content}>
          <section>
            <DatePicker
              id="dateStart"
              label="Data Inicial"
              value={dataInicial}
              onChange={(e) => setDataInicial(e.target.value)}
            />
          </section>

          <section>
            <DatePicker
              id="dateFinish"
              label="Data Final"
              value={dataFinal}
              onChange={(e) => setDataFinal(e.target.value)}
            />
          </section>
          <Loading display={showLoading ? 'block' : 'none'} />
          <section>
            <Button
              title="Gerar Certificado"
              onClick={getCertificado}
              display={showButton ? 'none' : 'block'}
              type="submit"
            />

            <Link
              className={showLink ? styles.link : styles.hidden}
              onClick={() => {
                setShowButton(false)
                setShowLink(false)
              }}
              to={{ pathname: geraCertificado.urlcertificado }}
              target="_blank"
            >
              <i className="fas fa-download"></i>
              Clique aqui para baixar
            </Link>
          </section>
        </div>
      </div>
    </Container>
  )
}
