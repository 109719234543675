import styles from './Embaixador.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import Button from '../../../../components/components-ui/Button/Button'
import Loading from '../../../../components/components-ui/Loading/Loading'
import { Table } from '../../../../components/Table'
import api from '../../../../services/Api'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export default function Embaixador() {
  const [dadosEmbaixador, setDadosEmbaixador] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  // LINKS
  const API_EMBAIXADOR = `/embaixador/index/`
  useEffect(() => {
    async function getEmbaixador() {
      setShowLoading(true)
      const response = await api.get(API_EMBAIXADOR)
      setDadosEmbaixador(response.data.data)
      setShowLoading(false)
    }
    getEmbaixador()
  }, [API_EMBAIXADOR])
  // Colunas da tabela
  const columns = [
    {
      column: 'Nome Local',
    },
    {
      column: 'Grupo',
    },
    {
      column: 'Status',
    },
  ]
  return (
    <Container>
      <h3>Embaixador</h3>

      <div className={styles.content}>
        <Link to="/usina/embaixador/novo/">
          <Button title="Novo" type="button" />
        </Link>

        <Table edit columns={columns}>
          {dadosEmbaixador.map((values) => (
            <tr key={values?.id_local}>
              <td>
                <Link to={`/usina/embaixador/edit/${values?.id_local}`}>
                  <i className="fas fa-pencil-alt"></i>
                </Link>
              </td>
              <td>{values?.nome_local}</td>
              <td>{values?.grupo}</td>
              <td>{values?.status}</td>
            </tr>
          ))}
        </Table>
        <div className={styles.loading}>
          <Loading display={showLoading ? 'block' : 'none'} />
        </div>
      </div>
      {/*  */}
    </Container>
  )
}
