import styles from './ListaRoteiros.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import Loading from '../../../../components/components-ui/Loading/Loading'
// import { Table } from '../../../../components/Table'
import UINumber from '../../../../components/components-ui/UINumber/UINumber'
import Button from '../../../../components/components-ui/Button/Button'
import { Grid, Col } from '../../../../components/Grid'
import api from '../../../../services/Api'
import { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../../../Provider/AuthContext'

export default function ListaRoteiros() {
  const { idPreposto } = useContext(AuthContext)
  const [detalheRoteiro, setDetalheRoteiro] = useState([])
  const [filtroStatus, setFiltroStatus] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  const [selectStatus, setSelectStatus] = useState()

  async function getDetalhesRoteiros(id) {
    console.log(idPreposto)
    setShowLoading(true)
    setDetalheRoteiro([])
    let URL = '/prep/motorista/roteirodetalhe'
    let url = `/prep/motorista/roteirodetalhe?id_preposto=${idPreposto}&idstatussub=${id}`
    const response = await api.post(id === '0' || id === undefined ? URL : url)
    setDetalheRoteiro(response.data.data)
    setShowLoading(false)
  }
  useEffect(() => {
    getDetalhesRoteiros()
    // Lista os status do filtro
    async function getStatus() {
      const response = await api.get(`/prep/statussub`)
      setFiltroStatus(response.data)
    }
    getStatus()
  }, [])
  return (
    <Container>
      <div className={styles.content}>
        <h3>Listagem de Coletas</h3>
        <section className={styles.wrap}>
          <form onSubmit={(ev) => ev.preventDefault()}>
            <div className={styles.filter_header}>
              <strong>Filtros</strong>
              <Button
                title="Procurar"
                type="submit"
                onClick={() => getDetalhesRoteiros(selectStatus)}
              />
            </div>
            <Grid>
              <Col md={4}>
                <label>Status</label>
                <select onChange={(ev) => setSelectStatus(ev.target.value)}>
                  <option value="0">Todos</option>
                  {filtroStatus.map((values) => (
                    <option
                      key={values?.id_sub_status}
                      value={values?.id_sub_status}
                    >
                      {values?.status}
                    </option>
                  ))}
                </select>
              </Col>
            </Grid>
          </form>
        </section>

        <div className={styles.loading}>
          <Loading display={showLoading ? 'block' : 'none'} />
        </div>
        {detalheRoteiro.map((values) => (
          // <Link
          //   to={`/prep/motorista/detalhe-coleta/${values?.id_oc_sub}`}
          //   className={styles.wrap_roteiro_tipo1}
          //   key={values?.id_oc_sub}
          // >
          <div className={styles.wrap_roteiro_tipo1} >
            <div>
              <ul>
                <li>
                  <strong>Coleta:</strong> {values?.id_coleta}
                </li>
                <li>
                  <strong>Status: </strong> {values?.status_sub}
                </li>
                <li>
                  <strong>Data Pedido:</strong> {values?.data_pedido}
                </li>
                <li>
                  <strong>Nome Local: </strong> {values?.nome_local}
                </li>
                <li>
                  <strong>Dados Bancários: </strong> 
                  <p>Nome Favorecido: {values?.nome_favorecido}</p>
                  <p>{values?.tipo_pessoa}: {values?.cnpjcpf}</p>
                  <p>Banco: {values?.banco}</p>
                  <p>Tipo Conta: {values?.tipo_conta}</p>
                  <p>Agencia: {values?.numero_agencia}</p>
                  <p>Conta: {values?.numero_conta}</p>
                  <p>Pix: {values?.numero_pix}</p>
                  
                </li>
                <li>

                  <strong>Valor Kg. Óleo: </strong>
                  <UINumber format="0.00">
                    {values?.valor_kg_oleo_pagamento}
                  </UINumber>
                </li>

                <li>
                  <strong>Endereço: </strong> {values?.endereco}
                </li>
                <li>
                  <strong>CEP: </strong>   {values?.cep}
                </li>
                <li>
                  <strong>Campanha: </strong> {values?.campanha}
                </li>
                <li>
                  <strong>Estimativa Ligação: </strong>
                  {/* {values?.quantidade_oleo_ligacao} */}
                  <UINumber format="0.00">
                    {values?.quantidade_oleo_ligacao}
                  </UINumber>
                </li>
                {values?.id_status_sub === 3 ? (
                  <li>
                    <strong>Estimativa motorista: </strong>
                    <UINumber format="0.00">
                      {values?.quantidade_oleo_motorista}
                    </UINumber>
                  </li>
                ) : null}
                <li>
                  <strong>Data/Hora validação: </strong>
                  {values?.data_validacao}
                </li>
                
              </ul>
            </div>
             <Link
             to={`/prep/motorista/detalhe-coleta/${values?.id_oc_sub}`}
             className={styles.wrap_roteiro_tipo1}
             key={values?.id_oc_sub }
           >
            <div className={styles.link_to_nextPage}>
              <span>
                <i className="fas fa-arrow-right"></i>
              </span>
            </div>
            </Link>
            </div>
        
        ))}
      </div>
    </Container>
  )
}
