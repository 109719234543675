import styles from '../Tabelas.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import Button from '../../../../components/components-ui/Button/Button'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import api from '../../../../services/Api'
import Loading from '../../../../components/components-ui/Loading/Loading'

export default function Veiculo() {
  const [listaVeiculos, setListaVeiculo] = useState([])
  const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    async function getVeiculos() {
      setShowLoading(true)
      const response = await api.get('/veiculo/index')
      setListaVeiculo(response.data)
      setShowLoading(false)
    }
    getVeiculos()
  }, [])

  return (
    <Container>
      <h3>Listagem de Veiculos</h3>
      <Link to="/usina/veiculo/novo/">
        <Button title="Novo" type="button" />
      </Link>

      <div className={styles.content}>
        <table>
          <thead>
            <tr>
              <th style={{ width: 10 }}>Editar</th>
              <th>Placa</th>
              <th>Veiculo</th>
            </tr>
          </thead>
          <tbody>
            {listaVeiculos.map((values) => (
              <tr key={values?.id_veiculo}>
                <td>
                  <Link to={`/usina/veiculo/edit/${values?.id_veiculo}`}>
                    <i className="fas fa-pencil-alt"></i>
                  </Link>
                </td>
                <td>{values?.placa}</td>
                <td>{values?.veiculo}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.loading}>
          <Loading display={showLoading ? 'block' : 'none'} />
        </div>
      </div>
    </Container>
  )
}
