import styles from './SideMenu.module.css'
import {
  OptionMenu,
  OptionMenuDropDown,
} from '../../components/components-ui/OptionMenu/OptionMenu'
import { AuthContext } from '../../Provider/AuthContext'
import { useContext } from 'react'
//
function SideMenu({ onClick, leave }) {
  const { idTipoUsuario, preposto, idEmiteMtr, idTipoContrapartida } = useContext(AuthContext)
  //
  let rotasUsuarioTipo1 = {}
  let rotasUsuarioTipo3 = {}
  let rotasUsuarioTipo4 = {}
  let rotasUsuarioTipo5 = {}
  if (preposto === 0) {
    if (idTipoContrapartida === 2) {
      rotasUsuarioTipo1 = {
        option: [
          {
            title: 'Solicitação de Coletas',
            icon: 'fas fa-plus',
            link: '/home/',
          },
          // {
          //   title: 'Validação de Coleta',
          //   icon: 'fas fa-clipboard-check',
          //   link: '/home/validacao-de-coleta',
          // },
          {
            title: 'Comprovante de pagamento',
            icon: 'fas fa-file-invoice-dollar',
            link: '/home/comprovante-de-pagamento',
          },

          {
            isDrop: true,
            title: 'Controle MTR',
            option: [
              {
                title: "Meus MTR's",
                icon: 'fas fa-file',
                link: '/home/meus-mtr',
              },
              {
                title: "CDF's",
                icon: 'fas fa-file',
                link: '/home/cdf',
              },
              {
                title: 'Acesso MTR',
                icon: 'fas fa-file',
                link: '/home/acesso-mtr',
              },
            ],
          },
          //
          {
            title: 'Conta Bancária',
            icon: 'fas fa-university',
            link: '/home/atualizar-conta',
          },
          {
            title: 'Relatórios de coletas',
            icon: 'fas fa-receipt',
            link: '/home/relatorio-de-coleta',
          },
          {
            title: 'Certificados',
            icon: 'fas fa-file',
            link: '/home/certificado',
          },
          {
            title: 'Gestão de Licenças',
            icon: 'fas fa-clipboard-list',
            link: '/home/gestao-de-licenca',
          },
        ],
      }
    } else {
      rotasUsuarioTipo1 = {
        option: [
          {
            title: 'Solicitação de Coletas',
            icon: 'fas fa-plus',
            link: '/home/',
          },
          {
            isDrop: true,
            title: 'Controle MTR',
            option: [
              {
                title: "Meus MTR's",
                icon: 'fas fa-file',
                link: '/home/meus-mtr',
              },
              {
                title: "CDF's",
                icon: 'fas fa-file',
                link: '/home/cdf',
              },
              {
                title: 'Acesso MTR',
                icon: 'fas fa-file',
                link: '/home/acesso-mtr',
              },
            ],
          },
          {
            title: 'Relatórios de coletas',
            icon: 'fas fa-receipt',
            link: '/home/relatorio-de-coleta',
          },
          {
            title: 'Certificados',
            icon: 'fas fa-file',
            link: '/home/certificado',
          },
          {
            title: 'Gestão de Licenças',
            icon: 'fas fa-clipboard-list',
            link: '/home/gestao-de-licenca',
          },
        ],
      }
    }
    rotasUsuarioTipo3 = {
      option: [
        // {
        //   title: 'Analytics',
        //   icon: 'fas fa-chart-line',
        //   link: {
        //     pathname: 'https://www.triangulo.org.br/kpi.html',
        //   },
        //   target: '_blank',
        // },
        {
          title: 'Painel',
          icon: 'fas fa-th',
          link: '/admin/painel'

        },
        {
          title: 'Gestão de Licenças',
          icon: 'fas fa-clipboard-list',
          link: '/admin/gestao-de-licenca',
        },
        {
          title: 'Relatórios de coletas',
          icon: 'fas fa-receipt',
          link: '/admin/relatorio-de-coleta/',
        },
      ],
    }
    rotasUsuarioTipo5 = {
      option: [
        {
          title: 'Locais',
          icon: 'fas fa-map',
          link: '/usina/',
        },
        {
          title: 'Coleta',
          icon: 'fas fa-chart-line',
          link: '/usina/coleta',
        },
      ],
    }
    rotasUsuarioTipo4 = {
      option: [
        {
          title: 'Roteiros',
          icon: 'fas fa-clipboard-check',
          link: '/motorista/',
        },
        {
          title: 'Relatório de Conferência',
          icon: 'fas fa-hand-holding-usd',
          link: '/motorista/relatoriopagamento',
        },
      ],
    }
  }
  if (preposto === 1) {
    rotasUsuarioTipo4 = {
      option: [
        {
          title: 'Coletas',
          icon: 'fas fa-clipboard-check',
          link: '/motorista/',
        },
        {
          title: 'Roteiros',
          icon: 'fas fa-clipboard-check',
          link: '/prep/motorista/roteiros',
        },
        {
          title: 'Relatório',
          icon: 'fas fa-regular fa-file-excel',
          link: '/prep/motorista/relatorio',
        },
      ],
    }
  }
  let rotaUsuario = []
  if (idTipoUsuario === 1) {
    rotaUsuario = rotasUsuarioTipo1
  }
  if (idTipoUsuario === 3) {
    rotaUsuario = rotasUsuarioTipo3
  }
  if (idTipoUsuario === 4) {
    rotaUsuario = rotasUsuarioTipo4
  }
  if (idTipoUsuario === 5) {
    rotaUsuario = rotasUsuarioTipo5
  }

  return (
    <div className={styles.containerLinks}>
      <ul>
        {/* Dropdown */}
        {/* Livre */}
        {rotaUsuario?.option?.map((value, key) =>
          value.isDrop ? (
            idEmiteMtr === 1 ? (
              <OptionMenuDropDown
                key={key}
                onClick={onClick}
                labelDropdown={value?.title}
                iconDropdown="fas fa-file"
                options={value?.option}
              />
            ) : null
          ) : (
            <OptionMenu
              key={key}
              link={value?.link}
              icon={value?.icon}
              title={value?.title}
              onClick={onClick}
              target={value?.target}
            />
          )
        )}

        {/*  Opções Fixas  */}
        {idTipoUsuario !== 4 ? (
          <OptionMenu
            link={{
              pathname: `https://api.whatsapp.com/send?phone=5511983279221&text=Olá,Necessito de um suporte`,
            }}
            icon="fab fa-whatsapp-square"
            title="Suporte"
            onClick={onClick}
            target="_blank"
          />
        ) : null}
        <OptionMenu
          link="/"
          icon="fas fa-sign-out-alt"
          title="Sair"
          onClick={leave}
        />
      </ul>
    </div>
  )
}

export default SideMenu
