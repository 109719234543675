import styles from './Embaixador.module.css'
import Button from '../../../../components/components-ui/Button/Button'
import Container from '../../../../components/components-ui/Container/Container'
import api from '../../../../services/Api'
import { InputMasked } from '../../../../components/components-ui/InputMask/InputMask'
import { Grid, Col } from '../../../../components/Grid'
import * as Yup from 'yup'
import axios from 'axios'
import { Formik, Form, Field } from 'formik'
import { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useHistory, useParams } from 'react-router'

export default function Action() {
  const [dataEmbaixador, setDataEmbaixador] = useState([])
  const [cidade, setCidade] = useState([])
  const [grupo, setGrupo] = useState([])
  const [campanha, setCampanha] = useState([])
  const [estabelecimento, setEstabelecimento] = useState([])
  const [status, setStatus] = useState([])
  const [tipoLogradouro, setTipoLogradouro] = useState([])
  const history = useHistory()
  const { id } = useParams()
  // LINKS
  const API_SHOW_EMBAIXADOR = `/local/show/${id}`
  const API_CIDADE = `/cidade/index`
  const API_GRUPO = `/grupo/index`
  const API_CAMPANHA = `/campanha/index`
  const API_ESTABELECIMENTO = `/estabelecimento/index`
  const API_STATUS = `/local/status/index`
  const API_TIPO_LOGRADOURO = `/logradourotipo/index`
  // ALERT PERSONALIZADO
  function showAlert(notify, msg) {
    if (notify === 0) {
      toast.success(msg)
    } else {
      toast.error(msg)
    }
  }
  // CARREGA DADOS DO EMBAIXADOR NA EDIÇÃO
  useEffect(() => {
    if (id) {
      async function getInfoLocal() {
        const response = await api.get(API_SHOW_EMBAIXADOR)
        setDataEmbaixador(response.data)
      }
      getInfoLocal()
    }
  }, [API_SHOW_EMBAIXADOR, id])
  // CARREGA OS OUTROS CAMPOS DO FORMULÁRIO
  useEffect(() => {
    // Carregar Cidades
    async function getCidade() {
      const response = await api.get(API_CIDADE)
      setCidade(response.data)
    }
    getCidade()
    // Carregar Grupos
    async function getGrupo() {
      const response = await api.get(API_GRUPO)
      setGrupo(response.data)
    }
    getGrupo()
    // Carregar Campanhas
    async function getCampanha() {
      const response = await api.get(API_CAMPANHA)
      setCampanha(response.data)
    }
    getCampanha()
    // Carregar Estabelecimento
    async function getEstabelecimento() {
      const response = await api.get(API_ESTABELECIMENTO)
      setEstabelecimento(response.data)
    }
    getEstabelecimento()
    // Carregar Status
    async function getStatus() {
      const response = await api.get(API_STATUS)
      setStatus(response.data)
    }
    getStatus()
    // Carregar Tipo Logradouro
    async function getTipoLogradouro() {
      const response = await api.get(API_TIPO_LOGRADOURO)
      setTipoLogradouro(response.data)
    }
    getTipoLogradouro()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // EDITA EMBAIXADOR
  async function editarEmbaixador(values) {
    const data = {
      id_local: values.idLocal,
      nome_local: values.nomeLocal,
      cnpj_cpf: values.cpf,
      id_status: values.status,
      cep: values.cep,
      id_tipo_logradouro: values.tipoLogradouro,
      logradouro: values.logradouro,
      numero: values.numero,
      complemento: values.complemento,
      bairro: values.bairro,
      id_cidade: values.cidade,
      uf: values.uf,
      id_campanha: values.campanha,
      id_grupo: values.grupo,
      id_captacao: values.captacao,
      id_estabelecimento: values.tEstabelecimento,
      Email: values.email,
      data_cadastro: values.dataCadastro,
      telefones: values.telefone,
      data_inativacao: values.dataInativacao,
      data_positivacao: values.dataPositivacao,
      observacao: values.observacao,
    }
    console.log(data)
    showAlert(0, 'Embaixador editado')
    /*const response = await api.put(`/campanha/edit/${id}`, {
      campanha: values.nome_campanha,
    })
    if (response.data.error === 0) {
      showAlert(0, response.data.msg)
      return
    }
    showAlert(1, response.data.msg) */
  }
  // CRIA NOVO EMBAIXADOR
  async function criarEmbaixador(values) {
    const data = {
      id_local: values.idLocal,
      nome_local: values.nomeLocal,
      cnpj_cpf: values.cpf,
      id_status: values.status,
      cep: values.cep,
      id_tipo_logradouro: values.tipoLogradouro,
      logradouro: values.logradouro,
      numero: values.numero,
      complemento: values.complemento,
      bairro: values.bairro,
      id_cidade: values.cidade,
      uf: values.uf,
      id_campanha: values.campanha,
      id_grupo: values.grupo,
      id_captacao: values.captacao,
      id_estabelecimento: values.tEstabelecimento,
      Email: values.email,
      data_cadastro: values.dataCadastro,
      telefones: values.telefone,
      data_inativacao: values.dataInativacao,
      data_positivacao: values.dataPositivacao,
      observacao: values.observacao,
    }
    console.log(data)
    showAlert(0, 'Novo embaixador')
    /*const response = await api.put(`/campanha/edit/${id}`, {
      campanha: values.nome_campanha,
    })
    if (response.data.error === 0) {
      showAlert(0, response.data.msg)
      return
    }
    showAlert(1, response.data.msg) */
  }
  // SCHEMA DE VALIDAÇÃO DO FORMULÁRIO
  const ValidationSchema = Yup.object().shape({
    idLocal: Yup.number().positive('*Campo Obrigatório'),
    nomeLocal: Yup.string(),
    status: Yup.string(),
    cep: Yup.string(),
    tipoLogradouro: Yup.string(),
    logradouro: Yup.string(),
    numero: Yup.number().positive('*Campo Obrigatório'),
    complemento: Yup.string(),
    bairro: Yup.string(),
    cidade: Yup.string(),
    uf: Yup.string(),
    campanha: Yup.string(),
    grupo: Yup.string(),
    captacao: Yup.string(),
    tEstabelecimento: Yup.string(),
    email: Yup.string(),
    dataCadastro: Yup.string(),
    telefone: Yup.string(),
    dataInativacao: Yup.string(),
    dataPositivacao: Yup.string(),
    observacao: Yup.string(),
  })
  // PREENCHIMENTO AUTOMATICO DE ENDEREÇO
  async function handleCep(ev, setFieldValue) {
    setFieldValue('cep', ev.value)
    const value = ev.value.replace(/[^0-9]/g, '')
    if (value.length >= 8) {
      const response = await axios.get(
        `https://viacep.com.br/ws/${value}/json/`
      )
      setFieldValue('uf', response.data.uf)
      setFieldValue('cidade', response.data.localidade)
      setFieldValue('bairro', response.data.bairro)
      setFieldValue('logradouro', response.data.logradouro)
      return
    }
  }
  return (
    <Container>
      <ToastContainer />
      <h3> {id ? 'Edição de Embaixador' : 'Novo Embaixador'} </h3>
      <section className={styles.content}>
        <Formik
          initialValues={{
            idLocal: dataEmbaixador?.id_local,
            nomeLocal: dataEmbaixador?.nome_local,
            cpf: dataEmbaixador?.cnpj_cpf,
            status: dataEmbaixador?.id_status,
            cep: dataEmbaixador?.cep,
            tipoLogradouro: dataEmbaixador?.id_tipo_logradouro,
            logradouro: dataEmbaixador?.logradouro,
            numero: dataEmbaixador?.numero,
            complemento: dataEmbaixador?.complemento,
            bairro: dataEmbaixador?.bairro,
            cidade: dataEmbaixador?.id_cidade,
            uf: dataEmbaixador?.uf,
            campanha: dataEmbaixador?.id_campanha,
            tEstabelecimento: dataEmbaixador?.id_estabelecimento,
            captacao: dataEmbaixador?.id_captacao,
            grupo: dataEmbaixador?.id_grupo,
            email: dataEmbaixador?.Email,
            telefone: dataEmbaixador?.telefones,
            dataCadastro: dataEmbaixador?.data_cadastro,
            dataPositivacao: dataEmbaixador?.data_positivacao,
            dataInativacao: dataEmbaixador?.data_inativacao,
            observacao: dataEmbaixador?.observacao,
          }}
          enableReinitialize={true}
          validationSchema={ValidationSchema}
          onSubmit={(values) =>
            id ? editarEmbaixador(values) : criarEmbaixador(values)
          }
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <Grid>
                <Col md={1}>
                  <label> ID Local </label>
                  <Field type="number" name="idLocal" />
                  {errors.idLocal && touched.idLocal ? (
                    <span className={styles.alertError}>{errors.idLocal}</span>
                  ) : null}
                </Col>
                <Col md={4}>
                  <label> Nome Embaixador </label>
                  <Field type="text" name="nomeLocal" />
                  {errors.nomeLocal && touched.nomeLocal ? (
                    <span className={styles.alertError}>
                      {errors.nomeLocal}
                    </span>
                  ) : null}
                </Col>
                <Col md={4}>
                  <label> CPF </label>
                  {/* <Field type="text" name="cpf" /> */}
                  <Field name="cpf">
                    {({ field }) => (
                      <InputMasked {...field} mask="999.999.999-99" />
                    )}
                  </Field>
                  {errors.cpf && touched.cpf ? (
                    <span className={styles.alertError}>{errors.cpf}</span>
                  ) : null}
                </Col>
                <Col md={3}>
                  <label> Status </label>
                  <Field component="select" name="status">
                    <option value="">Selecione</option>
                    {status.map((values) => (
                      <option key={values?.id_status} value={values?.id_status}>
                        {values?.status}
                      </option>
                    ))}
                  </Field>
                  {errors.status && touched.status ? (
                    <span className={styles.alertError}>{errors.status}</span>
                  ) : null}
                </Col>
              </Grid>

              <Grid>
                <Col md={2}>
                  <label> CEP </label>
                  <Field name="cep">
                    {({ field }) => (
                      <InputMasked
                        {...field}
                        mask="99999-999"
                        onChange={(ev) => handleCep(ev.target, setFieldValue)}
                      />
                    )}
                  </Field>
                  {errors.cep && touched.cep ? (
                    <span className={styles.alertError}>{errors.cep}</span>
                  ) : null}
                </Col>
                <Col md={3}>
                  <label> Tipo </label>
                  <Field component="select" name="tipoLogradouro">
                    <option value="">Selecione</option>
                    {tipoLogradouro.map((values) => (
                      <option
                        key={values?.id_tipo_logradouro}
                        value={values?.id_tipo_logradouro}
                      >
                        {values?.tipo_logradouro}
                      </option>
                    ))}
                  </Field>
                  {errors.tipoLogradouro && touched.tipoLogradouro ? (
                    <span className={styles.alertError}>
                      {errors.tipoLogradouro}
                    </span>
                  ) : null}
                </Col>
                <Col md={6}>
                  <label> Logradouro </label>
                  <Field type="text" name="logradouro" />
                  {errors.logradouro && touched.logradouro ? (
                    <span className={styles.alertError}>
                      {errors.logradouro}
                    </span>
                  ) : null}
                </Col>
                <Col md={1}>
                  <label> Número </label>
                  <Field type="number" name="numero" />
                  {errors.numero && touched.numero ? (
                    <span className={styles.alertError}>{errors.numero}</span>
                  ) : null}
                </Col>
              </Grid>
              <Grid>
                <Col md={5}>
                  <label> Complemento </label>
                  <Field type="text" name="complemento" />
                  {errors.complemento && touched.complemento ? (
                    <span className={styles.alertError}>
                      {errors.complemento}
                    </span>
                  ) : null}
                </Col>
                <Col md={3}>
                  <label> Bairro </label>
                  <Field type="text" name="bairro" />
                  {errors.bairro && touched.bairro ? (
                    <span className={styles.alertError}>{errors.bairro}</span>
                  ) : null}
                </Col>
                <Col md={3}>
                  <label> Cidade </label>
                  <Field component="select" type="text" name="cidade">
                    <option value="">Selecione</option>
                    {cidade.map((values) => (
                      <option key={values?.id_cidade} value={values?.id_cidade}>
                        {values?.cidade}
                      </option>
                    ))}
                  </Field>
                  {errors.cidade && touched.cidade ? (
                    <span className={styles.alertError}>{errors.cidade}</span>
                  ) : null}
                </Col>
                <Col md={1}>
                  <label> UF </label>
                  <Field type="text" name="uf" />
                  {errors.uf && touched.uf ? (
                    <span className={styles.alertError}>{errors.uf}</span>
                  ) : null}
                </Col>
              </Grid>
              <Grid>
                <Col md={3}>
                  <label> Campanha </label>
                  <Field component="select" name="campanha">
                    <option value="">Selecione</option>
                    {campanha.map((values) => (
                      <option key={values?.id} value={values?.id}>
                        {values?.campanha}
                      </option>
                    ))}
                  </Field>
                  {errors.campanha && touched.campanha ? (
                    <span className={styles.alertError}>{errors.campanha}</span>
                  ) : null}
                </Col>
                <Col md={3}>
                  <label> Tipo Estabelecimento </label>
                  <Field component="select" name="tEstabelecimento">
                    <option value="">Selecione</option>
                    {estabelecimento.map((values) => (
                      <option
                        key={values?.id_estabelecimento}
                        value={values?.id_estabelecimento}
                      >
                        {values?.estabelecimento}
                      </option>
                    ))}
                  </Field>
                  {errors.tEstabelecimento && touched.tEstabelecimento ? (
                    <span className={styles.alertError}>
                      {errors.tEstabelecimento}
                    </span>
                  ) : null}
                </Col>
                <Col md={3}>
                  <label> Grupo </label>
                  <Field component="select" name="grupo">
                    <option value="">Selecione</option>
                    {grupo.map((values) => (
                      <option key={values?.id} value={values?.id}>
                        {values?.descricao_grupo}
                      </option>
                    ))}
                  </Field>
                  {errors.grupo && touched.grupo ? (
                    <span className={styles.alertError}>{errors.grupo}</span>
                  ) : null}
                </Col>
                <Col md={3}>
                  <label> Captação </label>
                  <Field component="select" name="captacao">
                    <option value=""> Selecione </option>
                    <option value="1"> Captação 1 </option>
                  </Field>
                  {errors.captacao && touched.captacao ? (
                    <span className={styles.alertError}>{errors.captacao}</span>
                  ) : null}
                </Col>
              </Grid>
              <Grid>
                <Col md={12}>
                  <label> E-mail </label>
                  <Field type="email" name="email" />
                  {errors.email && touched.email ? (
                    <span className={styles.alertError}>{errors.email}</span>
                  ) : null}
                </Col>
              </Grid>
              <Grid>
                <Col md={3}>
                  <label> Data de cadastro </label>
                  <Field type="date" name="dataCadastro" />
                  {errors.dataCadastro && touched.dataCadastro ? (
                    <span className={styles.alertError}>
                      {errors.dataCadastro}
                    </span>
                  ) : null}
                </Col>

                <Col md={3}>
                  <label> Telefone </label>
                  <Field name="telefone">
                    {({ field }) => (
                      <InputMasked {...field} mask="99999-9999" />
                    )}
                  </Field>
                  {errors.telefone && touched.telefone ? (
                    <span className={styles.alertError}>{errors.telefone}</span>
                  ) : null}
                </Col>
                <Col md={3}>
                  <label> Data Positivação </label>
                  <Field type="date" name="dataPositivacao" />
                  {errors.dataPositivacao && touched.dataInativacao ? (
                    <span className={styles.alertError}>
                      {errors.dataInativacao}
                    </span>
                  ) : null}
                </Col>
                <Col md={3}>
                  <label> Data Inativação </label>
                  <Field type="date" name="dataInativacao" />
                  {errors.dataInativacao && touched.dataInativacao ? (
                    <span className={styles.alertError}>
                      {errors.dataInativacao}
                    </span>
                  ) : null}
                </Col>
              </Grid>
              <Grid>
                <Col md={12}>
                  <label> Observação </label>
                  <Field component="textarea" name="observacao"></Field>
                  {errors.observacao && touched.observacao ? (
                    <span className={styles.alertError}>
                      {errors.observacao}
                    </span>
                  ) : null}
                </Col>
              </Grid>
              <section className={styles.section_button}>
                <Button title="Salvar" type="submit" />
                <Button
                  color="secondary"
                  title="Voltar"
                  onClick={() => {
                    history.push('/usina/embaixador')
                  }}
                  type="button"
                />
              </section>
            </Form>
          )}
        </Formik>
      </section>
    </Container>
  )
}
