import { Switch, Route } from 'react-router-dom'
import { useContext } from 'react'
import login from './Application/Login/Login'
import Home from './Application/Home/Home'
import { AuthContext } from './Provider/AuthContext'

export default function Routes() {
  const { idTipoUsuario, preposto } = useContext(AuthContext)
  let path = ''
  if (preposto === 0) {
    if (idTipoUsuario === 1) {
      path = '/home'
    }
    if (idTipoUsuario === 3) {
      path = '/admin'
    }
    if (idTipoUsuario === 4) {
      path = '/motorista'
    }
    if (idTipoUsuario === 5) {
      path = '/usina'
    }
  }
  if (preposto === 1) {
    if (idTipoUsuario === 1) {
      path = '/prep/home/'
    }
    if (idTipoUsuario === 3) {
      path = '/prep/admin/'
    }
    if (idTipoUsuario === 4) {
      path = '/prep/motorista/'
    }
    if (idTipoUsuario === 5) {
      path = '/prep/usina/'
    }
  }
  return (
    <Switch>
      <Route exact path="/" component={login} />
      <Route path={path} component={Home} />
      <Route component={() => <div>PAGE 404 - NOT FOUND</div>} />
    </Switch>
  )
}
