import { useEffect, useState} from 'react'
import popup from 'react-alert-confirm'
import styles from './RoteirosDisponiveis.module.css'
import { Link } from 'react-router-dom'
import Container from '../../../components/components-ui/Container/Container'
import api from '../../../services/Api'
import Loading from '../../../components/components-ui/Loading/Loading'
import Modal from '../../../components/Modal/index'
import Button from '../../../components/components-ui/Button/Button'
import { useHistory, useParams } from 'react-router'

function RoteirosDisponiveis() {
  const history = useHistory()
  const [listaRoteiros, setListaRoteiros] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  const [openModalBrinde, setOpenModalBrinde] = useState(false)
  const [kmInicialMotorista, setKmInicialMotorista] = useState('')
  useEffect(() => {
    async function getListaRoteiros() {
      setShowLoading(true)
      const response = await api.post('/motorista/listaroteiro')
      setListaRoteiros(response.data)
      setShowLoading(false)
    }
    getListaRoteiros()
  }, [])

  function teste() {
    setOpenModalBrinde(true)
  }


  async function salvar_km_inicial(id) {
  

    const data = {
      kmInicialMotorista: kmInicialMotorista,
      id_oc: id,
    }


    if (kmInicialMotorista == 0 || kmInicialMotorista == null || kmInicialMotorista == '') {
      return popup.alert({
        title: 'Preencha o seu km inicial !!!',
        footer(dispatch) {
          return <Button onClick={() => dispatch('ok')}>Fechar</Button>
        },
      })
    }
    console.log(data)
    const response = await api.put(`/motorista/kminicialrota/${id}`, data)
    if (response.data.error === 1) {
      //setValorKG('')
      return popup.alert({
        title: 'Erro ao salvar',
        footer(dispatch) {
          return <Button onClick={() => dispatch('ok')}>Fechar</Button>
        },
      })
    }
    popup.alert({
      title: 'Km salvo com sucesso !',
      footer(dispatch) {
        return <Button onClick={() => dispatch('ok')}>Fechar</Button>
      },
    }) 
    let id_oc = id
    history.push(`/motorista/listagem-de-coletas/${id_oc}`)

  }


  return (
    <Container>
      <div className={styles.content}>
        <h3>Roteiros Disponíveis</h3>
        
        <div className={styles.loading}>
          <Loading display={showLoading ? 'block' : 'none'} />
        </div>
        {showLoading === false && listaRoteiros.length === 0 ? (
          <span>Não há roteiros disponíveis</span>
        ) : (
          listaRoteiros.map((values) => (

            values.km_inicial_motorista == null ?
              <div className={styles.wrap_roteiro}>
                <div>
                  <ul>
                    <li>
                      <strong>Data: </strong> {values?.data_oc}
                    </li>
                    <li>
                      <strong>Veículo: </strong> {values?.placa_veiculo}
                    </li>

                    <li>
                      <strong>Motorista: </strong> {values?.motorista}
                    </li>
                    <li>
                      <strong>Qtd Coleta: </strong> {values?.total_coleta}
                    </li>
                    <li>
                      <strong>Total Coletas Validadas: </strong>
                      {values?.total_resumo}
                    </li>
                    <li>
                      <strong>Status:</strong> {values?.status}
                    </li>
                    <li>
                      <strong>Km Inicial: </strong>  {values?.km_inicial_motorista} 
                      <form
                        enctype="multipart/form-data"
                        onSubmit={(ev) => ev.preventDefault()}
                      >
                        <>
                          <input type='number' className={styles.input}
                            //value={KmInicialMotorista}
                            onChange={(ev) => setKmInicialMotorista(ev.target.value)}>
                          </input>
                          
                          <Button title="Salvar Km"

                            onClick={() => salvar_km_inicial(`${values?.id}`)} color="blue" type="button" />
                        </>
                      </form>
                    </li>
                  </ul>
                </div>
                <div className={styles.link_to_nextPage}>
                  <span>
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </div>
              </div>
              :
              <Link
                to={`/motorista/listagem-de-coletas/${values?.id}`}
                className={styles.wrap_roteiro}
                key={values?.id}
              >

                <div>
                  <ul>
                    <li>
                      <strong>Data: </strong> {values?.data_oc}
                    </li>
                    <li>
                      <strong>Veículo: </strong> {values?.placa_veiculo}
                    </li>

                    <li>
                      <strong>Motorista: </strong> {values?.motorista}
                    </li>
                    <li>
                      <strong>Qtd Coleta: </strong> {values?.total_coleta}
                    </li>
                    <li>
                      <strong>Total Coletas Validadas: </strong>
                      {values?.total_resumo}
                    </li>
                    <li>
                      <strong>Status:</strong> {values?.status}
                    </li>
                    <li>
                      <strong>Km Inicial: </strong>  {values?.km_inicial_motorista}
                    </li>
                  </ul>
                </div>
                <div className={styles.link_to_nextPage}>
                  <span>
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </div>
              </Link>
          ))
        )}
      </div>
      <Modal
        width="500px"
        height="200px"
        titleModal="Km Inicial da Rota"
        display={openModalBrinde}
        onClick={setOpenModalBrinde}
      >

      </Modal>
    </Container>
  )
}

export default RoteirosDisponiveis
