// import React, { Component } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'

// const options = [
//   { value: 'chocolate', label: 'Chocolate' },
//   { value: 'strawberry', label: 'Strawberry' },
//   { value: 'vanilla', label: 'Vanilla' },
// ]

export function Selects(props) {
  return <Select {...props} />
}
export function AsyncSelects(props) {
  return <AsyncSelect {...props} />
}

// const MyComponent = () => <Selec options={options} />
