import styles from './Preposto.module.css'
import Button from '../../../../components/components-ui/Button/Button'
import Container from '../../../../components/components-ui/Container/Container'
import api from '../../../../services/Api'
import { InputMasked } from '../../../../components/components-ui/InputMask/InputMask'
import { Grid, Col } from '../../../../components/Grid'

import * as Yup from 'yup'
import axios from 'axios'
import { Formik, Form, Field } from 'formik'
import { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useHistory, useParams } from 'react-router'

export default function Action() {
  const [dadosPreposto, setDadosPreposto] = useState([])
  const [cidade, setCidade] = useState([])
  const [tipoLogradouro, setTipoLogradouro] = useState([])
  const [viewLogotipo, setViewLogotipo] = useState()

  const history = useHistory()
  const { id } = useParams()

  function showAlert(notify, msg) {
    if (notify === 0) {
      toast.success(msg)
    } else {
      toast.error(msg)
    }
  }
  useEffect(() => {
    // Carrega dados do preposto
    async function getDadosPreposto() {
      const response = await api.get(`/preposto/show/${id}`)
      setDadosPreposto(response.data)
    }
    getDadosPreposto()
  }, [id])
  //
  useEffect(() => {
    // Carregar Cidades
    async function getCidade() {
      const response = await api.get(`/cidade/index`)
      setCidade(response.data)
    }
    getCidade()

    // Carregar Tipo Logradouro
    async function getTipoLogradouro() {
      const response = await api.get(`/logradourotipo/index`)
      setTipoLogradouro(response.data)
    }
    getTipoLogradouro()
  }, [])

  const ValidationSchema = Yup.object().shape({
    id: Yup.string(),
    nome_fantasia: Yup.string(),
    sigla: Yup.string(),
    tipo: Yup.string(),
    logotipo: Yup.string(),
    razao_social: Yup.string(),
    status: Yup.string(),
    cnpj: Yup.string(),
    email: Yup.string(),
    data_cadastro: Yup.date(),
    cep: Yup.string(),
    tipo_logradouro: Yup.string(),
    logradouro: Yup.string(),
    numero: Yup.number(),
    complemento: Yup.string(),
    bairro: Yup.string(),
    cidade: Yup.string(),
    uf: Yup.string(),
    telefone: Yup.string(),
  })
  async function handleCep(ev, setFieldValue) {
    setFieldValue('cep', ev.value)
    const value = ev.value.replace(/[^0-9]/g, '')
    if (value.length >= 8) {
      const response = await axios.get(
        `https://viacep.com.br/ws/${value}/json/`
      )
      setFieldValue('uf', response.data.uf)
      setFieldValue('cidade', response.data.localidade)
      setFieldValue('bairro', response.data.bairro)
      setFieldValue('logradouro', response.data.logradouro)
      return
    }
  }
  async function createPreposto(values) {
    console.log(values)
    showAlert(0, 'Novo preposto')
  }
  async function editPreposto(values) {
    console.log(values)
    showAlert(0, 'Preposto editado')
  }
  function previewLogotipo(ev) {
    console.log(ev)
    const url = URL.createObjectURL(ev.target.files[0])
    setViewLogotipo(url)
  }
  return (
    <Container>
      <ToastContainer />
      <h3> {id ? 'Edição de Preposto' : 'Novo Preposto'} </h3>
      <section className={styles.content}>
        <Formik
          initialValues={{
            id: id ? dadosPreposto?.id : 'Novo',
            nome_fantasia: dadosPreposto?.nome_fantasia
              ? dadosPreposto?.nome_fantasia
              : '',
            sigla: dadosPreposto?.sigla ? dadosPreposto?.sigla : '',
            tipo: dadosPreposto?.id_preposto ? dadosPreposto?.id_preposto : '',
            logotipo: '',
            razao_social: dadosPreposto?.nome_fantasia
              ? dadosPreposto?.razao_social
              : '',
            status: dadosPreposto?.id_status ? dadosPreposto?.id_status : '',
            cnpj: dadosPreposto?.cnpj ? dadosPreposto?.cnpj : '',
            email: dadosPreposto?.email ? dadosPreposto?.email : '',
            data_cadastro: dadosPreposto?.data_cadastro
              ? dadosPreposto?.data_cadastro
              : '',
            cep: dadosPreposto?.cep ? dadosPreposto?.cep : '',
            tipo_logradouro: dadosPreposto?.id_tipo_logradouro
              ? dadosPreposto?.id_tipo_logradouro
              : '',
            logradouro: dadosPreposto?.logradouro
              ? dadosPreposto?.logradouro
              : '',
            numero: dadosPreposto?.numero ? dadosPreposto?.numero : '',
            complemento: dadosPreposto?.complemento
              ? dadosPreposto?.complemento
              : '',
            cidade: dadosPreposto?.id_cidade ? dadosPreposto?.id_cidade : '',
            uf: dadosPreposto?.uf ? dadosPreposto?.uf : '',
            telefone: dadosPreposto?.telefones ? dadosPreposto?.telefones : '',
          }}
          enableReinitialize={true}
          validationSchema={ValidationSchema}
          onSubmit={(values) =>
            id ? editPreposto(values) : createPreposto(values)
          }
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <div className={styles.section_form}>
                <div className={styles.form}>
                  <Grid>
                    <Col md={1}>
                      <label> ID </label>
                      <Field type="string" name="id" disabled />
                      {errors.id && touched.id ? (
                        <span className={styles.alertError}>{errors.id}</span>
                      ) : null}
                    </Col>

                    <Col md={viewLogotipo ? 2 : 4}>
                      <label> Nome Fantasia </label>
                      <Field type="text" name="nome_fantasia" />
                      {errors.sigla && touched.sigla ? (
                        <span className={styles.alertError}>
                          {errors.nome_fantasia}
                        </span>
                      ) : null}
                    </Col>

                    <Col md={3}>
                      <label> Tipo </label>
                      <Field component="select" name="tipo">
                        <option value="">Selecione</option>
                        <option value="0">Master</option>
                        <option value="1">Preposto</option>
                      </Field>
                      {errors.tipo && touched.tipo ? (
                        <span className={styles.alertError}>{errors.tipo}</span>
                      ) : null}
                    </Col>
                    <Col md={2}>
                      <label> Sigla </label>
                      <Field type="text" name="sigla" />
                      {errors.sigla && touched.sigla ? (
                        <span className={styles.alertError}>
                          {errors.sigla}
                        </span>
                      ) : null}
                    </Col>

                    <Col md={2}>
                      {/* {viewLogotipo ? (
                        <img src={viewLogotipo} alt="Logotipo" />
                      ) : null} */}
                      {/* <label> </label> */}
                      <label className={styles.labelForImg} htmlFor="selectImg">
                        <i className="fas fa-camera"></i>
                        Logotipo
                      </label>
                      <Field
                        id="selectImg"
                        className={styles.selectImg}
                        type="file"
                        name="logotipo"
                        onChange={(ev) => previewLogotipo(ev)}
                      />
                      {errors.logotipo && touched.logotipo ? (
                        <span className={styles.alertError}>
                          {errors.logotipo}
                        </span>
                      ) : null}
                    </Col>
                  </Grid>

                  <Grid>
                    <Col md={viewLogotipo ? 8 : 10}>
                      <label> Razão Social </label>
                      <Field type="text" name="razao_social" />
                      {errors.razao_social && touched.razao_social ? (
                        <span className={styles.alertError}>
                          {errors.razao_social}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={2}>
                      <label> Status </label>
                      <Field component="select" name="status">
                        <option value="">Selecione</option>
                      </Field>
                      {errors.status && touched.status ? (
                        <span className={styles.alertError}>
                          {errors.status}
                        </span>
                      ) : null}
                    </Col>
                  </Grid>
                  <Grid>
                    <Col md={viewLogotipo ? 3 : 5}>
                      <label> CNPJ </label>
                      <Field name="cnpj">
                        {({ field }) => (
                          <InputMasked {...field} mask="99.999.999/9999-99" />
                        )}
                      </Field>
                      {errors.cnpj && touched.cnpj ? (
                        <span className={styles.alertError}>{errors.cnpj}</span>
                      ) : null}
                    </Col>
                    <Col md={5}>
                      <label> E-mail </label>
                      <Field type="email" name="email" />
                      {errors.email && touched.email ? (
                        <span className={styles.alertError}>
                          {errors.email}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={2}>
                      <label> Data Cadastro </label>
                      {id ? (
                        <Field type="text" name="data_cadastro" disabled />
                      ) : (
                        <Field type="date" name="data_cadastro" />
                      )}
                      {errors.data_cadastro && touched.data_cadastro ? (
                        <span className={styles.alertError}>
                          {errors.data_cadastro}
                        </span>
                      ) : null}
                    </Col>
                  </Grid>
                  <Grid>
                    <Col md={3}>
                      <label> CEP </label>
                      <Field name="cep">
                        {({ field }) => (
                          <InputMasked
                            {...field}
                            mask="99999-999"
                            onChange={(ev) =>
                              handleCep(ev.target, setFieldValue)
                            }
                          />
                        )}
                      </Field>
                      {errors.cep && touched.cep ? (
                        <span className={styles.alertError}>{errors.cep}</span>
                      ) : null}
                    </Col>
                    <Col md={3}>
                      <label> Tipo </label>
                      <Field component="select" name="tipo_logradouro">
                        <option value="">Selecione</option>

                        {tipoLogradouro.map((values) => (
                          <option
                            key={values?.id_tipo_logradouro}
                            value={values?.id_tipo_logradouro}
                          >
                            {values?.tipo_logradouro}
                          </option>
                        ))}
                      </Field>
                      {errors.tipo_logradouro && touched.tipo_logradouro ? (
                        <span className={styles.alertError}>
                          {errors.tipo_logradouro}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={5}>
                      <label> Logradouro </label>

                      <Field type="text" name="logradouro" />
                      {errors.logradouro && touched.logradouro ? (
                        <span className={styles.alertError}>
                          {errors.logradouro}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={1}>
                      <label> Número </label>
                      <Field type="number" name="numero" />
                      {errors.numero && touched.numero ? (
                        <span className={styles.alertError}>
                          {errors.numero}
                        </span>
                      ) : null}
                    </Col>
                  </Grid>

                  <Grid>
                    <Col md={4}>
                      <label> Complemento </label>
                      <Field type="text" name="complemento" />
                      {errors.complemento && touched.complemento ? (
                        <span className={styles.alertError}>
                          {errors.complemento}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={4}>
                      <label> Bairro </label>
                      <Field type="text" name="bairro" />
                      {errors.bairro && touched.bairro ? (
                        <span className={styles.alertError}>
                          {errors.bairro}
                        </span>
                      ) : null}
                    </Col>

                    <Col md={3}>
                      <label> Cidade </label>
                      <Field component="select" name="cidade">
                        <option value="">Selecione</option>
                        {cidade.map((values) => (
                          <option
                            key={values?.id_cidade}
                            value={values?.id_cidade}
                          >
                            {values?.cidade}
                          </option>
                        ))}
                      </Field>
                      {errors.cidade && touched.cidade ? (
                        <span className={styles.alertError}>
                          {errors.cidade}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={1}>
                      <label> UF </label>
                      <Field type="text" name="uf" />
                      {errors.uf && touched.uf ? (
                        <span className={styles.alertError}>{errors.uf}</span>
                      ) : null}
                    </Col>
                  </Grid>
                  <Grid>
                    <Col md={12}>
                      <label> Telefone </label>
                      <Field name="telefone">
                        {({ field }) => (
                          <InputMasked {...field} mask="99999-9999" />
                        )}
                      </Field>
                      {errors.telefone && touched.telefone ? (
                        <span className={styles.alertError}>
                          {errors.telefone}
                        </span>
                      ) : null}
                    </Col>
                  </Grid>
                </div>
                {/*  */}
                <div className={styles.preview_logotipo}>
                  {viewLogotipo ? (
                    <img
                      src={viewLogotipo}
                      alt="Logotipo"
                      width="213px"
                      height="163px"
                    />
                  ) : null}
                </div>
              </div>
              <section className={styles.section_button}>
                <Button title="Salvar" type="submit" />
                <Button
                  color="secondary"
                  title="Voltar"
                  onClick={() => {
                    history.push('/usina/preposto')
                  }}
                  type="button"
                />
              </section>
            </Form>
          )}
        </Formik>
      </section>
    </Container>
  )
}
