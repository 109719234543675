import styles from '../Tabelas.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import Button from '../../../../components/components-ui/Button/Button'
import api from '../../../../services/Api'
import { Grid, Col } from '../../../../components/Grid'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import { useParams, useHistory } from 'react-router'
import { useEffect, useState } from 'react'

export default function Action() {
  const { id } = useParams()
  const [brinde, setBrinde] = useState('')

  function showAlert(notify, msg) {
    if (notify === 0) {
      toast.success(msg)
    } else {
      toast.error(msg)
    }
  }

  async function createBrinde(values) {
    const response = await api.post('/brinde/create/', {
      id_status: values.status,
      brinde: values.nome_brinde,
    })
    if (response.data.error === 0) {
      showAlert(0, response.data.msg)
      return
    }
    showAlert(1, response.data.msg)
  }

  async function editarBrinde(values) {
    const response = await api.put(`/brinde/edit/${id}`, {
      id_status: values.status,
      brinde: values.nome_brinde,
    })
    if (response.data.error === 0) {
      showAlert(0, response.data.msg)
      return
    }
    showAlert(1, response.data.msg)
  }

  useEffect(() => {
    async function getBrinde() {
      const response = await api.get(`/brinde/show/${id}`)
      setBrinde(response.data)
    }
    getBrinde()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const history = useHistory()
  const validadtionSchema = Yup.object().shape({
    nome_brinde: Yup.string().required('*Campo Obrigatório'),
    status: Yup.string().required('*Campo Obrigatório'),
  })
  return (
    <Container>
      <h3> {id ? 'Edição de Brinde' : 'Cadastro de Brinde'}</h3>
      <section className={styles.action_section}>
        <ToastContainer />
        <Formik
          initialValues={{
            nome_brinde: brinde?.brinde,
            status: brinde?.id_status,
          }}
          enableReinitialize={true}
          onSubmit={
            id
              ? (values) => editarBrinde(values)
              : (values) => createBrinde(values)
          }
          validationSchema={validadtionSchema}
        >
          {({ errors, touched }) => (
            <Form>
              <Grid>
                <Col md={9}>
                  <label> Nome Brinde </label>
                  <Field type="text" name="nome_brinde" />
                  {errors.nome_brinde && touched.nome_brinde ? (
                    <span className={styles.alertError}>
                      {errors.nome_brinde}
                    </span>
                  ) : null}
                </Col>
                <Col md={3}>
                  <label> Status </label>
                  <Field component="select" name="status">
                    <option value=""> Selecione </option>
                    <option value="1"> Ativo </option>
                    <option value="2"> Inativo </option>
                  </Field>
                  {errors.status && touched.status ? (
                    <span className={styles.alertError}>{errors.status}</span>
                  ) : null}
                </Col>
              </Grid>
              <Grid>
                <Col md={12}>
                  <Button type="submit" title={!id ? 'Salvar' : 'Editar'} />
                  <Button
                    type="button"
                    title="Voltar"
                    color="secondary"
                    onClick={() => {
                      history.push('/usina/brinde')
                    }}
                  />
                </Col>
              </Grid>
            </Form>
          )}
        </Formik>
      </section>
    </Container>
  )
}
