import styles from './Modal.module.css'
export default function Modal(props) {
  const styleModal = {
    width: props.width ? props.width : '100%',
    height: props.height ? props.height : '100%',
  }
  return (
    <section className={props.display ? styles.modal : styles.hidden}>
      <div className={styles.modal_header}>
        <h3> {props?.titleModal} </h3>
        <span onClick={() => props?.onClick()}>
          <i class="fas fa-times"></i>
        </span>
      </div>
      <div className={styles.modal_content}>
        <div style={styleModal} className={styles.modal_card}>
          {props.children}
        </div>
      </div>
    </section>
  )
}
