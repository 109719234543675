import styles from './Usuarios.module.css'
import Container from '../../../components/components-ui/Container/Container'
import {Table} from '../../../components/Table'
import Loading from '../../../components/components-ui/Loading/Loading'
import api from '../../../services/Api'
import Button from '../../../components/components-ui/Button/Button'
import {Grid, Col} from '../../../components/Grid'
import {Link} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'

export default function Usuarios() {
  const [listaUsuario, setListaUsuario] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    async function getUsuarios() {
      setShowLoading(true)
      setListaUsuario([])
      const response = await api.get(`/usuario/index?page=${currentPage}`)
      setListaUsuario(response.data.data)
      setShowLoading(false)
      setTotalPages(response.data.totalPages)
      setCurrentPage(response.data.currentPageNumber)
    }
    getUsuarios()
  }, [currentPage])

  function previewPage() {
    if (currentPage === 1) {
      setCurrentPage(1)
    } else {
      setCurrentPage(currentPage - 1)
    }
  }

  function nextPage() {
    if (currentPage === totalPages) {
      setCurrentPage(totalPages)
    } else {
      setCurrentPage(currentPage + 1)
    }
  }

  const ValidationSchema = Yup.object().shape({
    usuario: Yup.string(),
    status: Yup.string(),
  })

  // Colunas da tabela
  const columns = [
    {
      column: 'Nome Usuario',
    },
    {
      column: 'Status',
    },
  ]

  return (
    <Container>
      <h3>Listagem de usuarios</h3>
      <div className={styles.content}>
        {/* <Link to="/usina/usuarios/novo/">
          <Button title="Novo" type="button" />
        </Link> */}
        <Formik
          initialValues={{
            usuario: '',
            status: '',
          }}
          validationSchema={ValidationSchema}
          // onSubmit={(values) => filterList(values)}
        >
          {({errors, touched}) => (
            <Form>
              <section className={styles.wrap}>
                <div className={styles.filter_header}>
                  <strong>Filtros</strong>
                  <Button title="Procurar" type="submit" />
                </div>
                <Grid>
                  <Col md={3}>
                    <label>Usuario</label>
                    <Field type="text" name="usuario" />
                    {errors.usuario && touched.usuario ? (
                      <span className={styles.alertError}>
                        {errors.usuario}
                      </span>
                    ) : null}
                  </Col>

                  <Col md={2}>
                    <label>Status</label>
                    <Field component="select" name="status">
                      <option value="">Selecione</option>
                      {/* {status.map((values) => (
                        <option
                          key={values?.id_status}
                          value={values?.id_status}
                        >
                          {values?.status}
                        </option>
                      ))} */}
                    </Field>
                    {errors.status && touched.status ? (
                      <span className={styles.alertError}>{errors.status}</span>
                    ) : null}
                  </Col>
                </Grid>
              </section>
            </Form>
          )}
        </Formik>
        <Table edit columns={columns}>
          {listaUsuario.map((values) => (
            <tr key={values?.id_usuario}>
              <td>
                <Link to="#">
                  <i className="fas fa-pencil-alt"></i>
                </Link>
                {/* <Link to={`/usina/usuarios/edit/${values?.id_usuario}`}>
                  <i className="fas fa-pencil-alt"></i>
                </Link> */}
              </td>
              <td>{values?.usuario}</td>
              <td>{values?.status}</td>
            </tr>
          ))}
        </Table>
        <div className={styles.loading}>
          <Loading display={showLoading ? 'block' : 'none'} />
        </div>

        <div className={styles.table_pagination}>
          <div className={styles.table_pagination_buttons}>
            <Button
              disabled={currentPage === 1 ? true : false}
              onClick={() => previewPage()}
            >
              <i style={{marginRight: 5}} className="fas fa-chevron-left"></i>
              Voltar
            </Button>
            <span>
              {currentPage} De {totalPages}
            </span>
            <Button
              disabled={
                currentPage === totalPages || totalPages === 0 ? true : false
              }
              onClick={() => nextPage()}
            >
              Próxima
              <i style={{marginLeft: 5}} className="fas fa-chevron-right"></i>
            </Button>
          </div>
        </div>
      </div>
    </Container>
  )
}
