import Container from '../../../components/components-ui/Container/Container'

function Wellcome() {
  return (
    <Container>
      <h3>Seja Bem Vindo ! </h3>
    </Container>
  )
}

export default Wellcome
