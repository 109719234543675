import { useState } from 'react';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';

// Can be a string as well. Need to ensure each key-value pair ends with ;

export default function Loading(props) {
	const override = css`
		display: ${props.display};
		margin: 0 auto;
		border-color: #4caf50;
	`;
	/*eslint-disable */
	let [loading, setLoading] = useState(true);
	let [color, setColor] = useState('#4caf50');
	/*eslint-enable */
	return (
		<div className="sweet-loading">
			<BeatLoader color={color} loading={loading} css={override} size={15} />
		</div>
	);
}
