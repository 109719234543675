import styles from './GestaoDeLicenca.module.css'
import Container from '../../components/components-ui/Container/Container'
import Loading from '../../components/components-ui/Loading/Loading'
import { Table } from '../../components/Table'
import Api from '../../services/Api'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export default function GestaoDeLicenca() {
  const [listLicenca, setListaLicenca] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  // Links
  const API_LICENCA_WEB = '/licencaweb'
  useEffect(() => {
    async function getLicencas() {
      setShowLoading(true)
      const response = await Api.get(API_LICENCA_WEB)
      setListaLicenca(response.data)
      setShowLoading(false)
    }
    getLicencas()
  }, [API_LICENCA_WEB])
  const columns_gestao_licenca = [
    {
      column: '',
    },
    {
      column: 'Alvará',
    },
    {
      column: 'Data de Vencimento',
    },
  ]
  return (
    <Container>
      <h3> Gestão de Licença </h3>
      <section className={styles.content}>
        <Table columns={columns_gestao_licenca}>
          {listLicenca.map((values) => (
            <tr key={values?.id}>
              <td>
                <Link to={{ pathname: values?.image_pdf }} target="_blank">
                  <i className="fas fa-download"> </i>
                </Link>
              </td>
              <td>{values?.nome_licenca}</td>
              <td>{values?.data_vencimento}</td>
            </tr>
          ))}
        </Table>
        <Loading display={showLoading ? 'block' : 'none'} />
      </section>
    </Container>
  )
}
