import Button from '../../../components/components-ui/Button/Button'
import styles from './AtualizarConta.module.css'
import { useEffect, useState } from 'react'
import Container from '../../../components/components-ui/Container/Container'
import Loading from '../../../components/components-ui/Loading/Loading'
import api from '../../../services/Api'
//import {InputMasked} from '../../components/components-ui/InputMask/InputMask'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'

export default function AtualizarConta() {
  const [showLoading, setShowLoading] = useState(false)
  const [dadosConta, setDadosConta] = useState([])
  // LINKS
  const API_DADOS_CONTA = '/local/listacontacorrente'
  const API_ATUALIZAR_CONTA = '/local/createcontacorrente'
  // ALERT PERSONALIZADO
  function showAlert(notify, msg) {
    if (notify === 0) {
      toast.success(msg)
    } else {
      toast.error(msg)
    }
  }
  // CARREGA DADOS DA CONTA
  useEffect(() => {
    async function getDataConta() {
      const response = await api.get(API_DADOS_CONTA)
      setDadosConta(response?.data[0])
    }
    getDataConta()
  }, [])
  // SCHEEMA DE VALIDAÇÃO DO FORMULÁRIO
  const validation = Yup.object().shape({
    nomeBanco: Yup.string().required('*Campo obrigatório'),
    idTipoPessoa: Yup.number()
      .positive('Selecione uma das opções')
      .required('*Campo obrigatório'),
    nomeFavorecido: Yup.string().required('*Campo obrigatório'),
    idTipoConta: Yup.string().required('*Campo obrigatório'),
    numeroAgencia: Yup.string().required('*Campo obrigatório'),
    numeroConta: Yup.string().required('*Campo obrigatório'),
  })
  // FUNÇÃO DE ATUALIZAÇÃO DE CONTA
  async function updateConta(values, resetForm) {
    var cpfcpnj = null
    if (values.cpf.length === 0) {
      cpfcpnj = values.cnpj
    } else {
      cpfcpnj = values.cpf
    }
    const data = {
      banco: values.nomeBanco,
      id_tipo_pessoa: values.idTipoPessoa,
      cnpjcpf: cpfcpnj,
      nome_favorecido: values.nomeFavorecido,
      id_tipo_conta: values.idTipoConta,
      numero_agencia: values.numeroAgencia,
      numero_conta: values.numeroConta,
    }
    setShowLoading(true)
    const response = await api.post(API_ATUALIZAR_CONTA, data)
    if (response.data.status === '0') {
      resetForm()
      showAlert(0, 'Atualização de conta feita com sucesso')
      setShowLoading(false)
    } else {
      showAlert(1, 'Ocorreu algum erro, tente novamente')
      setShowLoading(false)
    }
  }
  return (
    <Container>
      <ToastContainer />
      <h3>Atualização de Conta Bancária</h3>
      <section className={styles.content}>
        <Formik
          initialValues={{
            nomeBanco: dadosConta?.banco,
            idTipoPessoa: dadosConta?.id_tipo_pessoa,
            nomeFavorecido: dadosConta?.nome_favorecido,
            idTipoConta: dadosConta?.id_tipo_conta,
            numeroAgencia: dadosConta?.numero_agencia,
            numeroConta: dadosConta?.numero_conta,
            cpf: dadosConta?.id_tipo_conta === 2 ? dadosConta?.cnpjcpf : '',
            cnpj: dadosConta?.id_tipo_conta === 1 ? dadosConta?.cnpjcpf : '',
          }}
          enableReinitialize={true}
          validationSchema={validation}
          onSubmit={(values, { resetForm }) => updateConta(values, resetForm)}
        >
          {({ values, touched, errors }) => (
            <div className={styles.formUpdateConta}>
              <Form>
                <section className={styles.sectionInput}>
                  <label> Nome do Banco </label>
                  <Field type="text" name="nomeBanco" />

                  {errors.nomeBanco && touched.nomeBanco ? (
                    <div>
                      <span className={styles.alertError}>
                        {errors.nomeBanco}
                      </span>
                    </div>
                  ) : null}

                  <label> Tipo Pessoa </label>
                  <Field component="select" name="idTipoPessoa">
                    <option value="0">Selecione </option>
                    <option value="1">CNPJ</option>
                    <option value="2">CPF</option>
                  </Field>
                  {errors.idTipoPessoa && touched.idTipoPessoa ? (
                    <div>
                      <span className={styles.alertError}>
                        {errors.idTipoPessoa}
                      </span>
                    </div>
                  ) : null}
                  <label>{values.idTipoPessoa === '1' ? 'CNPJ' : 'CPF'}</label>
                  {values.idTipoPessoa === '1' ? (
                    <Field
                      name="cnpj"
                      placeholder="11.222.333.444/5555-00"
                      maxLength="22"
                    />
                  ) : (
                    <Field
                      name="cpf"
                      placeholder="111.222.333-44"
                      maxLength="15"
                    />
                  )}

                  {errors.cpf && touched.cpf ? (
                    <div>
                      <span className={styles.alertError}>{errors.cpf}</span>
                    </div>
                  ) : null}
                  {/* Mask here */}

                  <label> Nome do favorecido </label>
                  <Field type="text" name="nomeFavorecido" />
                  {errors.nomeFavorecido && touched.nomeFavorecido ? (
                    <div>
                      <span className={styles.alertError}>
                        {errors.nomeFavorecido}
                      </span>
                    </div>
                  ) : null}

                  <label> Tipo de conta </label>
                  <Field component="select" name="idTipoConta">
                    <option value="0">Selecione </option>
                    <option value="1">Corrente</option>
                    <option value="2">Poupança</option>
                  </Field>

                  {errors.idTipoConta && touched.idTipoConta ? (
                    <div>
                      <span className={styles.alertError}>
                        {errors.idTipoConta}
                      </span>
                    </div>
                  ) : null}

                  <label> Agência </label>
                  <Field type="text" name="numeroAgencia" />

                  {errors.numeroAgencia && touched.numeroAgencia ? (
                    <div>
                      <span className={styles.alertError}>
                        {errors.numeroAgencia}
                      </span>
                    </div>
                  ) : null}

                  <label> Conta </label>
                  <Field type="text" name="numeroConta" />

                  {errors.numeroConta && touched.numeroConta ? (
                    <div>
                      <span className={styles.alertError}>
                        {errors.numeroConta}
                      </span>
                    </div>
                  ) : null}
                  <Button
                    type="submit"
                    title="Salvar"
                    display={showLoading ? 'none' : 'inline-block'}
                  />
                </section>
              </Form>
              <Loading display={showLoading ? 'block' : 'none'} />
            </div>
          )}
        </Formik>
      </section>
    </Container>
  )
}
