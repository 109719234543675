import styles from '../../PainelDeLigacao.module.css'
import Button from '../../../../../../components/components-ui/Button/Button'
import {Grid, Col} from '../../../../../../components/Grid'
import {Formik, Form, Field} from 'formik'

export default function NovoHistorico() {
  return (
    <div className={styles.content}>
      <h3>Detalhe</h3>
      <Formik
        initialValues={{
          codigoPonto: '',
          razaoSocial: '',
          estabelecimento: '',
          status: 3,
          grupo: '',
        }}
        //validationSchema={ValidationSchema}
        onSubmit={(values) => console.log(values)}
      >
        {({errors, touched}) => (
          <Form>
            <Grid>
              <Col md={2}>
                <label>Data</label>
                <Field type="date" name="data" />
                {errors.data && touched.data ? (
                  <span className={styles.alertError}>{errors.data}</span>
                ) : null}
              </Col>
              <Col md={4}>
                <label>Responsável</label>
                <Field component="select" name="responsavel">
                  <option value="">Selecione</option>
                </Field>
                {errors.responsavel && touched.responsavel ? (
                  <span className={styles.alertError}>
                    {errors.responsavel}
                  </span>
                ) : null}
              </Col>
              <Col md={4}>
                <label>Retorno</label>
                <Field component="select" name="retorno">
                  <option value="">Selecione</option>
                </Field>
                {errors.retorno && touched.retorno ? (
                  <span className={styles.alertError}>{errors.retorno}</span>
                ) : null}
              </Col>
              <Col md={2}>
                <label>Status</label>
                <Field component="select" name="status">
                  <option value="">Selecione</option>
                </Field>
                {errors.status && touched.status ? (
                  <span className={styles.alertError}>{errors.status}</span>
                ) : null}
              </Col>
            </Grid>
            <Grid>
              <Col md={6}>
                <label>Local</label>
                <Field component="select" name="local">
                  <option value="">Selecione</option>
                </Field>
                {errors.local && touched.local ? (
                  <span className={styles.alertError}>{errors.local}</span>
                ) : null}
              </Col>

              <Col md={6}>
                <label>Nome Contato</label>
                <Field type="text" name="nomeContato" />
                {errors.nomeContato && touched.nomeContato ? (
                  <span className={styles.alertError}>
                    {errors.nomeContato}
                  </span>
                ) : null}
              </Col>
            </Grid>
            <Grid>
              <Col md={12}>
                <label>Descrição Histórico/Tarefa</label>
                <Field component="textarea" name="descricao" />
                {errors.descricao && touched.descricao ? (
                  <span className={styles.alertError}>{errors.descricao}</span>
                ) : null}
              </Col>
            </Grid>
          </Form>
        )}
      </Formik>
      <div className={styles.button_section}>
        <Button title="Salvar" />
        <Button title="Cancelar" color="secondary" />
      </div>
    </div>
  )
}
