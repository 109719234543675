import style from './Button.module.css'

export default function Button(props) {
  var colorButton = ''
  if (props.color === undefined || props.color === 'primary') {
    colorButton = style.button_primary
  }
  if (props.color === 'secondary') {
    colorButton = style.button_secondary
  }
  if (props.color === 'tertiary') {
    colorButton = style.button_tertiary
  }

  if (props.color === 'blue') {
    colorButton = style.button_blue
  }

  
  return (
    <button
      disabled={props.disabled ? true : false}
      className={colorButton}
      style={{ display: props.display }}
      onClick={props.onClick}
      type={props.type}
    >
      {props.title || props.children}
    </button>
  )
}
