import styles from './Login.module.css'
import Button from '../../components/components-ui/Button/Button'
import Loading from '../../components/components-ui/Loading/Loading'
import { AuthContext } from '../../Provider/AuthContext'
import { isTokenExpired, logout } from '../../services/auth'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

export default function Login() {
  const [showLoading, setShowLoading] = useState(false)
  const { signIn, idTipoUsuario, preposto } = useContext(AuthContext)
  const history = useHistory()
  const expired = isTokenExpired()

  function showAlert(notify, msg) {
    if (notify === 0) {
      toast.success(msg)
    } else {
      toast.error(msg)
    }
  }
  let path = ''
  if (preposto === 0) {
    if (idTipoUsuario === 1) {
      path = '/home'
    }
    if (idTipoUsuario === 3) {
      path = '/admin'
    }
    if (idTipoUsuario === 4) {
      path = '/motorista'
    }
    if (idTipoUsuario === 5) {
      path = '/usina'
    }
  }
  if (preposto === 1) {
    if (idTipoUsuario === 1) {
      path = '/prep/home/'
    }
    if (idTipoUsuario === 3) {
      path = '/prep/admin/'
    }
    if (idTipoUsuario === 4) {
      path = '/prep/motorista/'
    }
    if (idTipoUsuario === 5) {
      path = '/prep/usina/'
    }
  }
  // const path = selectRoute()
  async function handleLogin(values) {
    setShowLoading(true)
    const response = await signIn(values)
    if (response.data.error === 0) {
      history.push(path)
      return
    }
    setShowLoading(false)
    showAlert(1, response.data.msgError)
  }

  useEffect(() => {
    if (expired) {
      return logout()
    }
    // else {
    //   if (path !== null) {
    //     history.push(path)
    //   }
    // }
    history.push(path)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path])

  // Validação Formik
  const SignupSchema = Yup.object().shape({
    login: Yup.string().required('*Campo Obrigatório'),
    password: Yup.string().required('*Campo Obrigatório'),
  })

  return (
    <div className={styles.container}>
      <ToastContainer />

      <section className={styles.section_login}>
        <img src="assets/logo.png" alt="Triangulo Logo" />
        <h3>
          Gerenciamento de <br /> Oléos e Gorduras <br /> Residuais
        </h3>

        <Formik
          initialValues={{
            login: '',
            password: '',
          }}
          validationSchema={SignupSchema}
          onSubmit={async (values) => handleLogin(values)}
        >
          {({ errors, touched }) => (
            <Form>
              <div className={styles.form}>
                <Field name="login" type="text" placeholder="Login" />
                {errors.login && touched.login ? (
                  <span className={styles.alertError}>{errors.login}</span>
                ) : null}
                <Field name="password" type="password" placeholder="Senha" />
                {errors.password && touched.password ? (
                  <span className={styles.alertError}>{errors.password}</span>
                ) : null}
                <Loading display={showLoading ? 'block' : 'none'} />
                <Button
                  type="submit"
                  title="Login"
                  display={showLoading ? 'none' : 'block'}
                />
              </div>
            </Form>
          )}
        </Formik>
        <span className={styles.version}>{process.env.REACT_APP_VERSION}</span>
      </section>
    </div>
  )
}
