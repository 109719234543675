import InputMask from 'react-input-mask'

// export function InputMasked({mask, id, field}) {
//   // return <InputMask id={id} mask={mask} maskChar="_" onChange={rest.onChange} {...rest} />
//   return <InputMask {...field} id={id} mask={mask} maskChar="_" />
// }
export function InputMasked({onChange, ...field}) {
  // return <InputMask id={id} mask={mask} maskChar="_" onChange={rest.onChange} {...rest} />
  return <InputMask {...field} onChange={onChange} />
}
// export function InputMaskeChange({mask, id, field, onChange}) {
//   // return <InputMask id={id} mask={mask} maskChar="_" onChange={rest.onChange} {...rest} />
//   return (
//     <InputMask
//       name={field.name}
//       id={id}
//       mask={mask}
//       maskChar="_"
//       onChange={onChange}
//     />
//   )
// }
