import { BrowserRouter as Router } from 'react-router-dom'
import './style/global.css'
import Routes from './Routes'

export default function App() {
  return (
    <Router>
      <Routes />
    </Router>
  )
}
