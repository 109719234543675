import styles from '../PainelDeLigacao.module.css'
import Button from '../../../../../components/components-ui/Button/Button'
import Modal from '../../../../../components/Modal'
import {useState} from 'react'
import NovoHistorico from './NovoHistorico'
import RelatarOcorrencia from './RelatarOcorrencia'
import {Grid, Col} from '../../../../../components/Grid'
import {Table} from '../../../../../components/Table'

export default function DetalhesDoLocal() {
  const [showModalNovoHistorico, setShowModalNovoHistorico] = useState(false)
  const [showModalRelatarOcorrencia, setShowModalRelatarOcorrencia] =
    useState(false)

  const columnsTableUltimosContatos = [
    {
      column: 'Data Contato',
    },
    {
      column: 'Responsável',
    },
    {
      column: 'Nome Contato',
    },
  ]

  const columnsTableUltimasColetas = [
    {
      column: 'Nº Coleta',
    },
    {
      column: 'Data Entrada',
    },
    {
      column: 'Quantidade oléo',
    },
    {
      column: 'Emissor',
    },
  ]

  const columnsTableOcorrencias = [
    {
      column: 'Data Ocorrência',
    },
    {
      column: 'Descrição',
    },
  ]

  return (
    <div className={styles.content}>
      <section className={styles.wrap}>
        <Grid>
          <Col md={2}>
            <label>Local</label>
            <span>Area 1</span>
          </Col>
          <Col md={2}>
            <label>Hr. Coleta De</label>
            <span>18h</span>
          </Col>
          <Col md={2}>
            <label>Hr. Coleta Até</label>
            <span>19h</span>
          </Col>

          <Col md={2}>
            <label>Grupo</label>
            <span>Grupo1</span>
          </Col>
          <Col md={2}>
            <label>Nome Contato</label>
            <span>Julião</span>
          </Col>

          <Col md={2}>
            <label>Telefone</label>
            <span>98785-7754</span>
          </Col>
        </Grid>
        <Grid>
          <Col md={1}>
            <label>CEP</label>
            <span>18754-000</span>
          </Col>
          <Col md={4}>
            <label>Endereço</label>
            <span>Rua 10</span>
          </Col>

          <Col md={1}>
            <label>Número</label>
            <span>12</span>
          </Col>

          <Col md={4}>
            <label>Complemento</label>
            <span>Próximo...</span>
          </Col>

          <Col md={2}>
            <label>Bombas</label>
            <span>Bomba1</span>
          </Col>
        </Grid>
        <Grid>
          <Col md={2}>
            <label>Brindes</label>
            <span>Brinde</span>
          </Col>

          <Col md={2}>
            <label>Bairro</label>
            <span>São João</span>
          </Col>

          <Col md={2}>
            <label>Cidade</label>
            <span>Pedra Caída</span>
          </Col>

          <Col md={2}>
            <label>UF</label>
            <span>MA</span>
          </Col>

          <Col md={2}>
            <label>email</label>
            <span>teste@gmail.com</span>
          </Col>

          <Col md={2}>
            <label>Preferência Contato</label>
            <span>Carta</span>
          </Col>
        </Grid>
        <Grid>
          <Col md={12}>
            <label>Observação</label>
            <span>Nenhuma</span>
          </Col>
        </Grid>
      </section>

      <div className={styles.table_content}>
        <Table title="Últimos Contatos" columns={columnsTableUltimosContatos}>
          <tr>
            <td>14/01/2021</td>
            <td>Felipe</td>
            <td>José</td>
          </tr>
        </Table>
        <Table title="Últimas Coletas" columns={columnsTableUltimasColetas}>
          <tr>
            <td>123</td>
            <td>14/01/2021</td>
            <td>12</td>
            <td>João</td>
          </tr>
        </Table>
        <Table title="Ocorrências" columns={columnsTableOcorrencias}>
          <tr>
            <td>14/01/2021</td>
            <td>Description</td>
          </tr>
        </Table>
      </div>
      <div className={styles.button_section}>
        <Button
          title="Registrar Ligação"
          onClick={() => setShowModalNovoHistorico(true)}
        />
        <Button
          title="Registrar Ocorrência"
          color="secondary"
          onClick={() => setShowModalRelatarOcorrencia(true)}
        />
      </div>
      <Modal
        titleModal="Novo Histórico"
        display={showModalNovoHistorico}
        onClick={() => setShowModalNovoHistorico(false)}
      >
        <NovoHistorico />
      </Modal>

      <Modal
        titleModal="Relatar Ocorrência"
        display={showModalRelatarOcorrencia}
        onClick={() => setShowModalRelatarOcorrencia(false)}
      >
        <RelatarOcorrencia />
      </Modal>
    </div>
  )
}
