import styles from './DashLigacao.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import {Table} from '../../../../components/Table'
import {Grid, Col} from '../../../../components/Grid'
import {Formik, Form, Field} from 'formik'

function DashLigacao() {
  // Colunas da tabela
  const columnsTable1 = [
    {
      column: 'Menu',
    },
    {
      column: 'Atendente',
    },
  ]

  const columnsTable2 = [
    {
      column: 'Data Contato',
    },
    {
      column: 'Local',
    },
    {
      column: 'Retorno',
    },
    {
      column: 'Nome Contato',
    },
  ]
  return (
    <Container>
      <h3> Painel de Relacionamento </h3>
      <div className={styles.content}>
        <Formik
          initialValues={{
            codigoPonto: '',
            razaoSocial: '',
            estabelecimento: '',
            status: 3,
            grupo: '',
          }}
          //validationSchema={ValidationSchema}
          onSubmit={(values) => console.log(values)}
        >
          {({errors, touched}) => (
            <Form>
              <section className={styles.wrap}>
                <div className={styles.filter_header}>
                  <strong>Filtros</strong>
                </div>
                <Grid>
                  <Col md={4}>
                    <label>Data Atend. De</label>
                    <Field type="date" name="dataAtenDe" />
                    {errors.dataAtenDe && touched.dataAtenDe ? (
                      <span className={styles.alertError}>
                        {errors.dataAtenDe}
                      </span>
                    ) : null}
                  </Col>

                  <Col md={4}>
                    <label>Data Atend. Até</label>
                    <Field type="date" name="dataAtenAte" />
                    {errors.dataAtenAte && touched.dataAtenAte ? (
                      <span className={styles.alertError}>
                        {errors.dataAtenAte}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={4}>
                    <label>Atendente</label>
                    <Field component="select" name="atendente">
                      <option value="">Selecione</option>
                    </Field>
                    {errors.atendente && touched.atendente ? (
                      <span className={styles.alertError}>
                        {errors.atendente}
                      </span>
                    ) : null}
                  </Col>
                </Grid>
              </section>
            </Form>
          )}
        </Formik>

        <Table columns={columnsTable1}>
          <tr>
            <td>Menu 1</td>
            <td>Felipe</td>
          </tr>
        </Table>

        <Table columns={columnsTable2}>
          <tr>
            <td>10/05/2021</td>
            <td>Local 1</td>
            <td>Retorno 1</td>
            <td>Felipe</td>
          </tr>
        </Table>
      </div>
    </Container>
  )
}

export default DashLigacao
