import Container from '../../../../../components/components-ui/Container/Container'
import { Table } from '../../../../../components/Table'
import api from '../../../../../services/Api'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
export default function Ligacao() {
  const [apiDadosLigacao, setApiDadosLigacao] = useState([])
  const { id } = useParams()
  // LINKS
  const API_DADOS_LOCAL = `/local/show/`
  // CARREGA DADOS DE LIGAÇÃO
  useEffect(() => {
    async function getDadosApiLigacao() {
      if (id) {
        const response = await api.get(`${API_DADOS_LOCAL}${id}`)
        setApiDadosLigacao(response.data.ligacao)
      }
      return
    }
    getDadosApiLigacao()
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [])
  // COLUNA DAS TABELAS
  const columns = [
    {
      column: 'Data Contato',
    },
    {
      column: 'Responsável',
    },
    {
      column: 'Nome Contato',
    },
    {
      column: 'Retorno',
    },
    {
      column: 'Observação',
    },
  ]
  return (
    <Container>
      <Table columns={columns}>
        {apiDadosLigacao.map((values, index) => (
          <tr key={index}>
            <td>{values?.data_contato}</td>
            <td>{values?.responsavel}</td>
            <td>{values?.nome_contato}</td>
            <td>{values?.retorno}</td>
            <td>{values?.observacao}</td>
          </tr>
        ))}
      </Table>
    </Container>
  )
}
