import Container from '../../../../../components/components-ui/Container/Container'
import { Table } from '../../../../../components/Table'
import api from '../../../../../services/Api'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export default function Coletas() {
  const [apiDadosColeta, setApiDadosColeta] = useState([])
  const { id } = useParams()
  // LINKS
  const API_DADOS_LOCAL = `/local/show/`
  // CARREGA DADOS DO LOCAL
  useEffect(() => {
    if (id) {
      async function getDadosApiColeta() {
        const response = await api.get(`${API_DADOS_LOCAL}${id}`)
        setApiDadosColeta(response.data.coleta)
      }
      getDadosApiColeta()
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [])
  // COLUNAS DAS TABELAS
  const columns = [
    {
      column: 'Nº Coleta',
    },
    {
      column: 'Data Pedido',
    },
    {
      column: 'Item',
    },
    {
      column: 'Quantidade Óleo',
    },
    {
      column: 'Quantidade Banha',
    },
    {
      column: 'Quantidade Coletada',
    },
  ]
  return (
    <Container>
      <Table columns={columns}>
        {apiDadosColeta.map((values) => (
          <tr key={values?.id_coleta}>
            <td>{values?.id_coleta}</td>
            <td>{values?.data_pedido}</td>
            <td>{values?.item}</td>
            <td>{values?.quantidade_oleo}</td>
            <td>{values?.quantidade_banha}</td>
            <td>{values?.quantidade_coleta}</td>
          </tr>
        ))}
      </Table>
    </Container>
  )
}
