import styles from '../Tabelas.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import { Table } from '../../../../components/Table'
import Button from '../../../../components/components-ui/Button/Button'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import api from '../../../../services/Api'
import Loading from '../../../../components/components-ui/Loading/Loading'

export default function Estabelecimento() {
  const [listaEstabelecimento, setListaEstabelecimento] = useState([])
  const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    async function getEstabelecimento() {
      setShowLoading(true)
      const response = await api.get('/estabelecimento/index')
      setListaEstabelecimento(response.data)
      setShowLoading(false)
    }
    getEstabelecimento()
  }, [])

  // Colunas da tabela
  const columns = [
    {
      column: 'Estabelecimento',
    },
  ]

  return (
    <Container>
      <h3>Listagem de Estabelecimento</h3>
      <Link to="/usina/estabelecimento/novo/">
        <Button title="Novo" type="button" />
      </Link>

      <div className={styles.content}>
        <Table edit columns={columns}>
          {listaEstabelecimento.map(
            ({ id_estabelecimento, estabelecimento }) => (
              <tr key={id_estabelecimento}>
                <td>
                  <Link
                    to={`/usina/estabelecimento/edit/${id_estabelecimento}`}
                  >
                    <i className="fas fa-pencil-alt"></i>
                  </Link>
                </td>
                <td>{estabelecimento}</td>
              </tr>
            )
          )}
        </Table>
        <div className={styles.loading}>
          <Loading display={showLoading ? 'block' : 'none'} />
        </div>
      </div>
    </Container>
  )
}
