import styles from './DetalhesColeta.module.css'
import Container from '../../../components/components-ui/Container/Container'
import Button from '../../../components/components-ui/Button/Button'
import api from '../../../services/Api'
// import Loading from '../../../components/components-ui/Loading/Loading'
import { BlockScreenLoading } from '../../../components/BlockScreenLoading'

import convert from 'image-file-resize'
import { Link } from 'react-router-dom'
import { useHistory, useParams } from 'react-router'
import { useEffect, useState, Fragment } from 'react'
import 'react-alert-confirm/dist/index.css'
import popup from 'react-alert-confirm'

// import { ToastContainer, toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'

function DetalhesColeta() {
  const history = useHistory()
  const { id } = useParams()
  const [infoDetalheColeta, setInfoDetalheColeta] = useState([])
  const [valorKG, setValorKG] = useState('')
  const [observacao, setObservacao] = useState('')
  // const [showLoading, setShowLoading] = useState(false)
  const [img, setImg] = useState()
  const [previewPhoto, setPreviewPhoto] = useState()
  const [listaDeImagens, setListaDeImagens] = useState([])
  const [blockScreen, setBlockScren] = useState(false)
  const [idColeta, setIdColeta] = useState('')
  const [idOcSub, setIdOcSub] = useState('')
  const [idOc, setIdOc] = useState('')

  function back() {
    const [{ id_oc }] = infoDetalheColeta
    history.push(`/motorista/listagem-de-coletas/${id_oc}`)
  }
  useEffect(() => {
    async function getDetalheColeta() {
      // setShowLoading(true)
      const response = await api.post(`/motorista/roteirodetalhecoleta/${id}`)
      setInfoDetalheColeta(response.data)
      console.log(response.data[0].id_coleta)
      setIdColeta(response.data[0].id_coleta)
      setIdOcSub(response.data[0].id_oc_sub)
      setIdOc(response.data[0].id_oc)
      // setShowLoading(false)
    }
    getDetalheColeta()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  async function getImagensColeta() {
    setBlockScren(true)
    let id_oc_sub = infoDetalheColeta[0]?.id_oc_sub
    if (id_oc_sub !== undefined) {
      const response = await api.post(
        `motorista/roteirodetalhecoletaimg/${id_oc_sub}`
      )
      setBlockScren(false)
      return setListaDeImagens(response.data)
    }
  }
  async function salvarColeta() {
    const data = {
      qtd_oleo_motorista: valorKG,
      observacao_motorista: observacao,
    }
    if (valorKG <= 0) {
      return popup.alert({
        title: 'Preencha o campo de quantidade de óleo !!!',
        footer(dispatch) {
          return <Button onClick={() => dispatch('ok')}>Fechar</Button>
        },
      })
    }
    const response = await api.put(`/motorista/editcoletaroteiro/${id}`, data)
    if (response.data.error === 1) {
      setValorKG('')
      return popup.alert({
        title: 'Erro ao salvar',
        footer(dispatch) {
          return <Button onClick={() => dispatch('ok')}>Fechar</Button>
        },
      })
    }
    popup.alert({
      title: 'Salvo com sucesso !',
      footer(dispatch) {
        return <Button onClick={() => dispatch('ok')}>Fechar</Button>
      },
    })
    let id_oc = infoDetalheColeta[0]?.id_oc
    history.push(`/motorista/listagem-de-coletas/${id_oc}`)
  }

  async function avisoOcorrenciaColeta() {
    const data = {
      id_coleta: idColeta,
      id_oc_sub: idOcSub,
      id_oc: idOc
    }

    console.log(data)

    const response = await api.post(`/motorista/avisaocorrencia`, data)
    if (response.data.error === 1) {

      return popup.alert({
        title: 'Erro ao salvar',
        footer(dispatch) {
          return <Button onClick={() => dispatch('ok')}>Fechar</Button>
        },
      })

    } else {


      let id_oc = infoDetalheColeta[0]?.id_oc
      history.push(`/motorista/listagem-de-coletas/${id_oc}`)
      window.open("https://chat.whatsapp.com/IYFm1MzQKK0AOTcH5F0tHo", '_blank')
    }
    // popup.alert({
    //   title: 'Salvo com sucesso !',
    //   footer(dispatch) {
    //     return <Button onClick={(


    //     ) => dispatch('ok')}>Fechar</Button>
    //   },
    // })


  }


  async function submitPhoto() {
    setBlockScren(true)
    let id_oc_sub = infoDetalheColeta[0]?.id_oc_sub
    const formData = new FormData()
    const resizeImg = convert({
      file: img,
      //width: 800,
      //height: 600,
      type: 'jpeg',
    })
    let minImg = await resizeImg
    formData.append('file', minImg)
    const response = await api.post(
      `/motoristauploadcoleta?id_oc_sub=${id_oc_sub}`,
      formData
    )
    setBlockScren(false)
    if (response.data.error === 0) {
      setImg(null)
      getImagensColeta()
      return popup.alert({
        title: response.data.status,
        footer(dispatch) {
          return <Button onClick={() => dispatch('ok')}>Fechar</Button>
        },
      })
    }
    setImg(null)
    return popup.alert({
      title: response.data.status,
      footer(dispatch) {
        return <Button onClick={() => dispatch('ok')}>Fechar</Button>
      },
    })
  }
  function previewImg(ev) {
    setImg(ev.target.files[0])
    const url = URL.createObjectURL(ev.target.files[0])
    setPreviewPhoto(url)
  }
  useEffect(() => {
    getImagensColeta()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoDetalheColeta])






  async function coletaNãoRealizada() {
    // id = id_oc_sub
    const response = await api.post(`/motorista/coletanaorealizada/${id}`)
    console.log(response)
    if (response.data.error === 1) {
      popup.alert({
        title: 'Erro na ação !',
        footer(dispatch) {
          return <Button onClick={() => dispatch('ok')}>Fechar</Button>
        },
      })
      return
    }
    back()
    // popup.alert({
    //   title: 'Ação executada com sucesso !',
    //   footer(dispatch) {
    //     return <Button onClick={() => dispatch('ok')}>Fechar</Button>
    //   },
    // }
  }

  async function EntregaRetiraMaterialColeta() {
    // id = id_oc_sub
    const response = await api.post(`/motorista/validaentregaretiramaterial/${id}`)
    console.log(response)
    if (response.data.error === 1) {
      popup.alert({
        title: 'Erro na ação !',
        footer(dispatch) {
          return <Button onClick={() => dispatch('ok')}>Fechar</Button>
        },
      })
      return
    }
    back()
    // popup.alert({
    //   title: 'Ação executada com sucesso !',
    //   footer(dispatch) {
    //     return <Button onClick={() => dispatch('ok')}>Fechar</Button>
    //   },
    // })
  }

  return (
    <Container>
      <BlockScreenLoading loading display={blockScreen}>
        <strong> Carregando... </strong>
      </BlockScreenLoading>

      <div className={styles.content}>
        <div className={styles.btn_footer}>
          <Link to="#" onClick={back} className={styles.backButton}>
            <i className="fas fa-arrow-left"></i> Voltar
          </Link>
        </div>
        {infoDetalheColeta.map((values, index) => (
          <Fragment key={index}>
            <h3>Detalhes da Coleta: {values?.id_coleta}</h3>
            <section className={styles.wrap_coleta}>
              {/* Listagem */}
              <ul>
                <li>
                  <strong>Ordem</strong>: {values?.id_ordem}
                </li>
                <li>
                  <strong>Coleta</strong>: {values?.id_coleta}
                </li>
                <li>
                  <strong>Nome Local</strong>: {values?.nome_local}
                </li>
                <li>
                  <strong>Endereço</strong>: {values?.endereco}
                </li>
                <li>
                  <strong>CEP</strong>: {values?.cep}
                </li>
              </ul>
              <div className={styles.detalhe_coleta}>
                {/* Formulario */}
                <form
                  enctype="multipart/form-data"
                  onSubmit={(ev) => ev.preventDefault()}
                >
                  {/* {values?.bloqueado === 0 ? ( */}
                    <>
                      <input
                        type="number"
                        placeholder="Estimativa óleo "
                        value={valorKG}
                        required
                        onChange={(e) => setValorKG(e.target.value)}
                        name="estimativaOleo"
                      />
                      <textarea
                        placeholder="Observação"
                        onChange={(e) => setObservacao(e.target.value)}
                        style={{ marginTop: 10 }}
                      />
                    </>
                  {/* ) : null} */}

                  {values?.bloqueado === 1 ? (
                    <span>
                      <strong>Aviso de ocorrência: </strong>
                      {values?.descricao_ocorrencia}
                    </span>
                  ) : null}

                  {values?.bloqueado === 0 ? (
                    <section className={styles.wrap_btn_coleta}>
                      <Button title="Validar Coleta" onClick={salvarColeta} />
                      <Button title="Entr./Ret. Material" onClick={EntregaRetiraMaterialColeta}  color="blue" type="button"/>
                      {/*  */}
                      <Button
                        title="Não Realizada"
                        onClick={coletaNãoRealizada}
                        color="tertiary"
                        type="button"
                      />
                      {/*  */}
                      <Button type="button" title="Aviso de Ocorrência" color="secondary"
                        onClick={avisoOcorrenciaColeta}>

                        {/* <Link
                          to={{
                            pathname:
                              'https://chat.whatsapp.com/IYFm1MzQKK0AOTcH5F0tHo',
                          }}
                          target="_blank"
                        > 
                          Aviso de Ocorrência
                        </Link>*/}
                      </Button>
                      {/* <Link
                        to={{
                          pathname:
                            'https://chat.whatsapp.com/IYFm1MzQKK0AOTcH5F0tHo',
                        }}
                        target="_blank"
                      >
                        <Button
                          color="secondary"
                          title="Aviso de Ocorrência"
                          type="button"
                        />
                      </Link> */}
                      {/*  */}
                      <div className={styles.imgBtn}>
                        <label
                          className={styles.labelForImg}
                          htmlFor="selectImg"
                        >
                          <i className="fas fa-camera"></i>
                          Capturar Imagem
                        </label>
                        <input
                          id="selectImg"
                          className={styles.selectImg}
                          type="file"
                          onChange={(ev) => previewImg(ev)}
                          accept="image/*"
                        />
                      </div>
                      {/* <div className={styles.left_btn}>
                        <Button title="Salvar" onClick={salvarColeta} />
                      </div>

                      <div className={styles.right_btn}>
                        <Link
                          to={{
                            pathname:
                              'https://chat.whatsapp.com/IYFm1MzQKK0AOTcH5F0tHo',
                          }}
                          target="_blank"
                        >
                          <Button
                            color="secondary"
                            title="Aviso de Ocorrência"
                            type="button"
                          />
                        </Link>
                      </div> */}
                    </section>
                  ) : (


                    <div className={styles.imgBtn}>


                      <section className={styles.wrap_btn_coleta}>
                        <Button title="Validar" onClick={salvarColeta} />
                        {/*  */}
                        <Button
                          title="Não Realizada"
                          onClick={coletaNãoRealizada}
                          color="tertiary"
                          type="button"
                        />

                        <label className={styles.labelForImg} htmlFor="selectImg">
                          <i className="fas fa-camera"></i>
                          Capturar Imagem
                        </label>
                        <input
                          id="selectImg"
                          className={styles.selectImg}
                          type="file"
                          onChange={(ev) => previewImg(ev)}
                          accept="image/*"
                        />

                      </section>
                    </div>

                  )}
                  {/*  */}
                  {/* <div className={styles.imgBtn}>
                    <label className={styles.labelForImg} htmlFor="selectImg">
                      <i className="fas fa-camera"></i>
                      Capturar Imagem
                    </label>
                    <input
                      id="selectImg"
                      className={styles.selectImg}
                      type="file"
                      onChange={(ev) => previewImg(ev)}
                      accept="image/*"
                    />
                  </div> */}
                  {/*  */}
                </form>
                {img ? (
                  <div className={styles.previewImg}>
                    <img src={previewPhoto} alt="Preview" width="100%" />
                    <Button
                      color="secondary"
                      title="Salvar Captura"
                      onClick={submitPhoto}
                    />
                  </div>
                ) : null}
              </div>
            </section>
          </Fragment>
        ))}

        {/* <div className={styles.loading}>
          <Loading display={showLoading ? 'block' : 'none'} />
        </div> */}

        {listaDeImagens.length !== 0 ? (
          <section className={styles.wrap_coleta}>
            {listaDeImagens.map((values) => (
              <img
                src={values?.image_url}
                key={values?.id}
                alt="Photos"
                width="100%"
              />
            ))}
          </section>
        ) : null}
      </div>
    </Container>
  )
}

export default DetalhesColeta
