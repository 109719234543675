import styles from './DetalhesColeta.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import Button from '../../../../components/components-ui/Button/Button'
import DatePicker from '../../../../components/components-ui/DatePicker/DatePicker'
import api from '../../../../services/Api'
import { BlockScreenLoading } from '../../../../components/BlockScreenLoading'
import { AuthContext } from '../../../../Provider/AuthContext'
import convert from 'image-file-resize'
import { Link } from 'react-router-dom'
import { useHistory, useParams } from 'react-router'
import { useEffect, useState, Fragment, useContext } from 'react'
import 'react-alert-confirm/dist/index.css'
import popup from 'react-alert-confirm'

function DetalhesColeta() {
  const { link_whatsapp } = useContext(AuthContext)
  const history = useHistory()
  const { id } = useParams()
  const [infoDetalheColeta, setInfoDetalheColeta] = useState([])
  const [valorKG, setValorKG] = useState('')
  const [observacao, setObservacao] = useState('')
  const [dataColeta, setDataColeta] = useState('')
  const [idColeta, setIdColeta] = useState('')
  const [valorPagamentoPreposto, setValorPagamentoPreposto] = useState('')
  const [img, setImg] = useState()
  const [previewPhoto, setPreviewPhoto] = useState()
  const [listaDeImagens, setListaDeImagens] = useState([])
  const [blockScreen, setBlockScren] = useState(false)
  let id_oc_sub = infoDetalheColeta[0]?.id_oc_sub
  // LINKS
  const API_SHOW_DETALHE_COLETA = `/motorista/roteirodetalhecoleta/${id}`
  const API_ROTEIRO_DETALHE_COLETA_IMG = `motorista/roteirodetalhecoletaimg/${id_oc_sub}`
  const API_EDITAR_COLETA_ROTEIRO = `/motorista/editcoletaroteiro/${id}`
  const API_UPLOAD_COLETA = `/motoristauploadcoleta?id_oc_sub=${id_oc_sub}`

  useEffect(() => {
    async function getDetalheColeta() {
      const response = await api.post(API_SHOW_DETALHE_COLETA)
      setInfoDetalheColeta(response.data)
    }
    getDetalheColeta()
  }, [API_SHOW_DETALHE_COLETA])
  async function getImagensColeta() {
    setBlockScren(true)
    // let id_oc_sub = infoDetalheColeta[0]?.id_oc_sub
    if (id_oc_sub !== undefined) {
      const response = await api.post(API_ROTEIRO_DETALHE_COLETA_IMG)
      setBlockScren(false)
      return setListaDeImagens(response.data)
    }
  }
  async function salvarColeta() {
    const data = {
      qtd_oleo_motorista: valorKG,
      observacao_motorista: observacao,
      valor_pago_preposto: valorPagamentoPreposto
    }

    // if (dataColeta.length <= 0) {
    //   return popup.alert({
    //     title: 'Preencha a data da coleta !!!',
    //     footer(dispatch) {
    //       return <Button onClick={() => dispatch('ok')}>Fechar</Button>
    //     },
    //   })
    // }

    if (valorKG.length <= 0) {
      return popup.alert({
        title: 'Preencha o campo de quantidade de óleo !!!',
        footer(dispatch) {
          return <Button onClick={() => dispatch('ok')}>Fechar</Button>
        },
      })
    }

    if (valorPagamentoPreposto.length <= 0) {
      return popup.alert({
        title: 'Preencha o campo de valor pagamento do óleo!!!',
        footer(dispatch) {
          return <Button onClick={() => dispatch('ok')}>Fechar</Button>
        },
      })
    }


    const response = await api.put(API_EDITAR_COLETA_ROTEIRO, data)
    if (response.data.error === 1) {
      setValorKG('')
      return popup.alert({
        title: 'Erro ao salvar',
        footer(dispatch) {
          return <Button onClick={() => dispatch('ok')}>Fechar</Button>
        },
      })
    }
    popup.alert({
      title: 'Salvo com sucesso !',
      footer(dispatch) {
        return <Button onClick={() => dispatch('ok')}>Fechar</Button>
      },
    })
    let id_oc = infoDetalheColeta[0]?.id_oc
    history.push(`/motorista/listagem-de-coletas/${id_oc}`)
  }

  async function submitPhoto() {
    setBlockScren(true)
    const formData = new FormData()
    const resizeImg = convert({
      file: img,
      //width: 800,
      //height: 600,
      type: 'jpeg',
    })
    let minImg = await resizeImg
    formData.append('file', minImg)
    formData.append('id_coleta', infoDetalheColeta[0].id_coleta)

    console.log(infoDetalheColeta[0].id_coleta)

    const response = await api.post(API_UPLOAD_COLETA, formData)
    setBlockScren(false)
    if (response.data.error === 0) {
      setImg(null)
      getImagensColeta()
      return popup.alert({
        title: response.data.status,
        footer(dispatch) {
          return <Button onClick={() => dispatch('ok')}>Fechar</Button>
        },
      })
    }
    setImg(null)
    return popup.alert({
      title: response.data.status,
      footer(dispatch) {
        return <Button onClick={() => dispatch('ok')}>Fechar</Button>
      },
    })
  }
  function previewImg(ev) {
    setImg(ev.target.files[0])
    const url = URL.createObjectURL(ev.target.files[0])
    setPreviewPhoto(url)
  }
  useEffect(() => {
    getImagensColeta()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoDetalheColeta])
  return (
    <Container>
      {blockScreen ? (
        <BlockScreenLoading loading>
          <strong> Carregando... </strong>
        </BlockScreenLoading>
      ) : null}
      <div className={styles.content}>
        <div className={styles.btn_footer}>
          <Link to={`/prep/motorista/`} className={styles.backButton}>
            <i className="fas fa-arrow-left"></i> Voltar
          </Link>
        </div>
        {infoDetalheColeta.map((values, index) => (
          <Fragment key={index}>
            <h3>Detalhes da Coleta: {values?.id_coleta}</h3>
            <section className={styles.wrap_coleta}>
              {/* Listagem */}
              <ul>
                <li>
                  <strong>Coleta: </strong> {values?.id_coleta}
                </li>
                <li>
                  <strong>Nome Local: </strong> {values?.nome_local}
                </li>
                <li>
                  <strong>Campanha: </strong> {values?.campanha}
                </li>
                <li>
                  <strong>Endereço: </strong> {values?.endereco}
                </li>
                <li>
                  <strong>Estimativa Ligação: </strong>
                  {values?.estimativa_ligacao}
                </li>
                <li>
                  <strong>CEP: </strong> {values?.cep}
                </li>
              </ul>
              <div className={styles.detalhe_coleta}>
                {/* Formulario */}
                <form
                  encType="multipart/form-data"
                  onSubmit={(ev) => ev.preventDefault()}
                >
                  {values?.bloqueado === 0 ? (
                    <>
                    
                      <input
                        type="number"
                        placeholder="Estimativa óleo "
                        value={valorKG}
                        onChange={(e) => setValorKG(e.target.value)}
                        required
                        name="estimativaOleo"
                      />
                      <input
                        type="number"
                        placeholder="Vlr. Pago"
                        value={valorPagamentoPreposto}
                        onChange={(e) => setValorPagamentoPreposto(e.target.value)}
                        required
                        name="valorPagamentoPreposto"
                      />
                      <textarea
                        placeholder="Observação"
                        onChange={(e) => setObservacao(e.target.value)}
                        style={{ marginTop: 10 }}
                      />
                    </>
                  ) : null}

                  {values?.bloqueado === 1 ? (
                    <span>
                      <strong>Aviso de ocorrência: </strong>
                      {values?.descricao_ocorrencia}
                    </span>
                  ) : null}

                  {values?.bloqueado !== 1 ? (
                    <section className={styles.wrap_btn_coleta}>
                      <div className={styles.left_btn}>
                        <Button title="Salvar" onClick={salvarColeta} />
                      </div>


                      <div className={styles.right_btn}>
                        <Link
                          to={{
                            pathname: link_whatsapp,
                          }}
                          target="_blank"
                        >
                          <Button
                            color="secondary"
                            title="Aviso de Ocorrência"
                            type="button"
                          />
                        </Link>
                      </div>
                    </section>
                  ) : null}

                  {/*  */}
                  <div className={styles.imgBtn}>
                    <label className={styles.labelForImg} htmlFor="selectImg">
                      <i className="fas fa-camera"></i>
                      Capturar Imagem
                    </label>
                    <input
                      id="selectImg"
                      className={styles.selectImg}
                      type="file"
                      onChange={(ev) => previewImg(ev)}
                      accept="image/*"
                    />
                  </div>
                  {/*  */}

                </form>
                {img ? (
                  <div className={styles.previewImg}>
                    <img src={previewPhoto} alt="Preview" width="100%" />
                    <Button
                      color="secondary"
                      title="Salvar Captura"
                      onClick={submitPhoto}
                    />
                  </div>
                ) : null}
              </div>
            </section>
          </Fragment>
        ))}
        {listaDeImagens.length !== 0 ? (
          <section className={styles.wrap_coleta}>
            {listaDeImagens.map((values) => (
              <img
                src={values?.image_url}
                key={values?.id}
                alt="Photos"
                width="100%"
              />
            ))}
          </section>
        ) : null}
      </div>
    </Container>
  )
}

export default DetalhesColeta
