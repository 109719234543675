import styles from './Roteiros.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import Button from '../../../../components/components-ui/Button/Button'
import Loading from '../../../../components/components-ui/Loading/Loading'
// import { Table } from '../../../../components/Table'
import api from '../../../../services/Api'
import { Link, useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'

export default function Roteiros() {
  const [listaRoteiros, setListaRoteiros] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  const history = useHistory()
  useEffect(() => {
    async function getListaRoteiros() {
      setShowLoading(true)
      const response = await api.post('/motorista/listaroteiro')
      setListaRoteiros(response.data)
      setShowLoading(false)
    }
    getListaRoteiros()
  }, [])
  // const columnsListaColetas = [
  //   {
  //     column: 'Coleta',
  //   },
  // ]
  return (
    <Container>
      <div className={styles.content}>
        <h3>Roteiros Disponíveis</h3>
        <div className={styles.loading}>
          <Loading display={showLoading ? 'block' : 'none'} />
        </div>
        {showLoading === false && listaRoteiros.length === 0 ? (
          <span>Não há roteiros disponíveis</span>
        ) : (
          listaRoteiros.map((values) => (
            <Link
              to={`/motorista/listagem-de-coletas/${values?.id}`}
              className={styles.wrap_roteiro}
              key={values?.id}
            >
              <div>
                <ul>
                  <li>
                    <strong>Data: </strong> {values?.data_oc}
                  </li>
                  <li>
                    <strong>Veículo: </strong> {values?.placa_veiculo}
                  </li>
                  <li>
                    <strong>Motorista: </strong> {values?.motorista}
                  </li>
                  <li>
                    <strong>Qtd Coleta: </strong> {values?.total_coleta}
                  </li>
                  <li>
                    <strong>Total Coletas Validadas: </strong>
                    {values?.total_resumo}
                  </li>
                  <li>
                    <strong>Status:</strong> {values?.status}
                  </li>
                </ul>
              </div>
              <div className={styles.link_to_nextPage}>
                <span>
                  <i className="fas fa-arrow-right"></i>
                </span>
              </div>
            </Link>
          ))
        )}
        <br />

        <Button
          title="Novo Roteiro"
          onClick={() => history.push('/prep/motorista/novo-roteiro')}
        />
      </div>
    </Container>
  )
}
