import styles from './ComprovanteDePagamento.module.css'
import Container from '../../../components/components-ui/Container/Container'
import UINumber from '../../../components/components-ui/UINumber/UINumber'
import Loading from '../../../components/components-ui/Loading/Loading'
import { Table } from '../../../components/Table'
import api from '../../../services/Api'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export default function ComprovanteDePagamento() {
  const [listLicenca, setListaLicenca] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  // LINKS
  const API_COMPROVANTE_DE_PAGAMENTO = '/coletarecibo'
  // CARREGA OS COMPROVANTE DE PAGAMENTO
  useEffect(() => {
    async function getLicenses() {
      setShowLoading(true)
      const response = await api.get(API_COMPROVANTE_DE_PAGAMENTO)
      setListaLicenca(response.data)
      setShowLoading(false)
    }
    getLicenses()
  }, [])
  // COLUNAS DAS TABELAS
  const columns_comprovante_pagamento = [
    {
      column: 'Comprovante',
    },
    {
      column: 'Nº Coleta',
    },
    // {
    //   column: 'Data do Recibo',
    //   hiden: true,
    // },
    {
      column: 'Data do Pagamento',
    },
    {
      column: 'Valor',
    },
  ]
  return (
    <Container>
      <h3>Comprovante de Pagamento</h3>
      <section className={styles.content}>
        <Table columns={columns_comprovante_pagamento}>
          {listLicenca.map((values) => (
            <tr key={values?.id}>
              <td>
                {values?.data_pagamento !== null ? (
                  <Link
                    to={{ pathname: values?.nome_comprovante }}
                    target="_blank"
                  >
                    <i className="fas fa-download"> </i>
                  </Link>
                ) : null}
              </td>
              <td>{values?.id_coleta}</td>
              {/* <td className={styles.th_tr_Responsive}>{values?.data_recibo}</td> */}
              <td>{values?.data_pagamento}</td>
              <td>
                <UINumber format="$0.00">{values?.valor_pagamento}</UINumber>
              </td>
            </tr>
          ))}
        </Table>
        <Loading display={showLoading ? 'block' : 'none'} />
      </section>
    </Container>
  )
}
