import React from 'react'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import Button from '../components/components-ui/Button/Button'

const ExportCSV = ({ csvData, fileName, nameButton }) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  return (
    <div>
      <Button title={nameButton} onClick={(e) => exportToCSV(csvData, fileName)} />
    </div>
  )
}

export default ExportCSV
