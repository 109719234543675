import styles from '../Locais.module.css'
import { Grid, Col } from '../../../../../components/Grid'
import Button from '../../../../../components/components-ui/Button/Button'
import api from '../../../../../services/Api'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

export function ModalBrinde({
  setOpenModalBrinde,
  adicionaBrindeAPI,
  adicionaBrindeLocalmente,
}) {
  const [brinde, setBrinde] = useState([])
  const [nomeBrinde, setNomeBrinde] = useState('')
  const { id } = useParams()
  // LINKS
  const API_LISTA_DE_BRINDE = `/brinde/index`
  // CARREGA OS LISTA DE BRINDES
  useEffect(() => {
    async function getListaBrinde() {
      const response = await api.get(API_LISTA_DE_BRINDE)
      setBrinde(response.data)
    }
    getListaBrinde()
  }, [API_LISTA_DE_BRINDE])
  // ADICIONA O ITEM LOCAL
  function adicionaLocalmente(val) {
    adicionaBrindeLocalmente(val)
    setOpenModalBrinde(false)
  }
  // ADICIONA O ITEM DIRETO NA API
  function adicionaNaApi(val) {
    adicionaBrindeAPI({
      id_brinde: val?.id_brinde,
      id_local: id,
    })
    setOpenModalBrinde(false)
  }
  // VALIDAÇÃO PRA ADIÇÃO DE BRINDE
  const adicionaBrinde = () => {
    // Validação se foi selecionado alguma opção
    if (nomeBrinde.value === '' || nomeBrinde.value === undefined) {
      return window.alert('Selecione alguma opção')
    }
    // Prepara o formato do dado que vai ser mandado pro array
    const id_brinde = nomeBrinde.value.split('-')[0].trim()
    const dataInput = {
      id_brinde: id_brinde,
      brinde: nomeBrinde.value,
    }
    return id ? adicionaNaApi(dataInput) : adicionaLocalmente(dataInput)
  }
  return (
    <section className={styles.content}>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className={styles.wrap}>
          <Grid>
            <Col md={12}>
              <label> Brindes </label>
              <select name="brinde" onChange={(ev) => setNomeBrinde(ev.target)}>
                <option value="">Selecione</option>
                {brinde.map((values) => (
                  <option
                    key={values?.id_brinde}
                    value={`${values?.id_brinde} - ${values?.brinde}`}
                  >
                    {values?.brinde}
                  </option>
                ))}
              </select>
            </Col>
          </Grid>
          <Grid>
            <Col md={12}>
              <Button
                onClick={adicionaBrinde}
                type="submit"
                title="Salvar"
                color="secondary"
              />
            </Col>
          </Grid>
        </div>
      </form>
    </section>
  )
}
