import styles from '../Tabelas.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import Button from '../../../../components/components-ui/Button/Button'
import api from '../../../../services/Api'
import { Grid, Col } from '../../../../components/Grid'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import 'react-toastify/dist/ReactToastify.css'
import { useHistory, useParams } from 'react-router'
import { ToastContainer, toast } from 'react-toastify'
import { useEffect, useState } from 'react'

export default function Action() {
  const { id } = useParams()
  const [nomeBombona, setNomeBombona] = useState('')

  function showAlert(notify, msg) {
    if (notify === 0) {
      toast.success(msg)
    } else {
      toast.error(msg)
    }
  }

  async function createBombona(values) {
    const response = await api.post('/bombona/create/', {
      bombona: values.bombona,
    })
    if (response.data.error === 0) {
      showAlert(0, response.data.msg)
      return
    }
    showAlert(1, response.data.msg)
  }

  async function editarBombona(values) {
    const response = await api.put(`/bombona/edit/${id}`, {
      bombona: values.bombona,
    })
    if (response.data.error === 0) {
      showAlert(0, response.data.msg)
      return
    }
    showAlert(1, response.data.msg)
  }

  useEffect(() => {
    async function getNomeBombona() {
      const response = await api.get(`/bombona/show/${id}`)
      setNomeBombona(response.data.bombona)
    }
    getNomeBombona()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const history = useHistory()
  const validadtionSchema = Yup.object().shape({
    bombona: Yup.string().required('*Campo Obrigatório'),
  })
  return (
    <Container>
      <ToastContainer />
      <h3> {id ? 'Edição de Bombona' : 'Cadastro de Bombona'} </h3>
      <section className={styles.action_section}>
        <Formik
          enableReinitialize={true}
          initialValues={{ bombona: nomeBombona }}
          onSubmit={
            id
              ? (values) => editarBombona(values)
              : (values) => createBombona(values)
          }
          validationSchema={validadtionSchema}
        >
          {({ errors, touched }) => (
            <Form>
              <Grid>
                <Col md={12}>
                  <label> Bombona </label>
                  <Field type="text" name="bombona" />
                  {errors.bombona && touched.bombona ? (
                    <span className={styles.alertError}>{errors.bombona}</span>
                  ) : null}
                </Col>
              </Grid>
              <Grid>
                <Col md={12}>
                  <Button type="submit" title={!id ? 'Salvar' : 'Editar'} />
                  <Button
                    type="button"
                    title="Voltar"
                    color="secondary"
                    onClick={() => {
                      history.push('/usina/bombona')
                    }}
                  />
                </Col>
              </Grid>
            </Form>
          )}
        </Formik>
      </section>
    </Container>
  )
}
