import styles from './Painel.module.css'
import Button from '../../../components/components-ui/Button/Button'
import DatePicker from '../../../components/components-ui/DatePicker/DatePicker'
import api from '../../../services/Api'
import { useState, useEffect, Fragment } from 'react'
import Loading from '../../../components/components-ui/Loading/Loading'
// import Select from '../../../components/components-ui/Select/Select'
import Container from '../../../components/components-ui/Container/Container'
import { Link } from 'react-router-dom'
import { Table } from '../../../components/Table'



import ExportCSV from '../../../services/ExportCSV'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function Painel() {
  const [dataInicial, setDataInicial] = useState('')
  const [dataFinal, setDataFinal] = useState('')
  const [idSelect, setIdSelect] = useState(0)
  const [groups, setGroups] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  const [showLink, setShowLink] = useState(false)
  const [download, setDownload] = useState([])
  const [grupo, setGrupo] = useState([])
  const [garrafa, setgarrafa] = useState([])
  const [coletores, setColetores] = useState([])


  function showAlert(notify, msg) {
    if (notify === 0) {
      toast.success(msg)
    } else {
      toast.error(msg)
    }
  }
  // Preenche o select
  // useEffect(() => {
  //   const loadOption = async () => {
  //     const response = await api.get('/local/lista')
  //     setGroups(response.data)
  //   }
  //   loadOption()
  // }, [])

  async function getListaRelatorio() {

    setGrupo([]);
    setgarrafa([]);
    setColetores([]);

    setShowLoading(true)
    if (dataInicial.length !== 0 && dataFinal !== 0) {

      const data = {
        data_de: dataInicial,
        data_ate: dataFinal
      }
      const response = await api.post(
        'mob/coleta/painelbunge',
        data
      )
      if (response.status === 401) {
        showAlert(1, 'Ocorreu algum erro')
        setShowLoading(false)
        return
      }
      setShowLoading(false)
      setShowLink(true)
      setDownload(response.data)

      console.log(response.data)

      var marvelHeroes = response.data.filter(function (hero) {
        return hero.grupo == 'GRANEL';
      });


      // const isAlimento = download => download.grupo === 'GRANEL';
      // const alimentos = download.filter(isAlimento);
      setGrupo(marvelHeroes);




      var marvelHeroes02 = response.data.filter(function (hero) {
        return hero.grupo == 'GARRAFA';
      });
      setgarrafa(marvelHeroes02)

      var coletor = response.data.filter(function (hero) {
        return hero.grupo == 'COLETORES';
      });
      setColetores(coletor)


      return
    }
    showAlert(1, 'Preencha todos os campos')
    setShowLoading(false)
  }


  function ListGranel(props) {
    if (props.data > 0) {
      return <h1>GRANEL</h1>;
    } else {
      return '';
    }
  }

  function ListGarrafa(props) {
    return <h1>GARRAFA</h1>;
  }





  return (
    <Container>
      <ToastContainer />
      <div className={styles.content}>
        <h3>Painel</h3>
        <div className={styles.form}>
          <form onSubmit={(ev) => ev.preventDefault()}>
            <section>
              {/* <Select
                options={groups}
                onChange={(e) => {
                  setIdSelect(e.value)
                }}
              /> */}
              {/* <datalist
                onChange={(e) => {
                  setIdSelect(e.value)
                }}>
                {groups.map((value) => (
                  <option value={value?.value} />
                ))}
              </datalist> */}
              {/* <input
                list="grupos"
                onChange={(ev) => {
                  setIdSelect(ev.target.value)
                }}
              /> */}
              {/* <datalist id="grupos">
                {groups.map((value) => (
                  <option
                    key={value?.id_local}
                    value={`${value?.nome_local} - ${value?.id_local}`}
                  />
                ))}
              </datalist> */}
            </section>
            <section>
              <DatePicker
                id="dateStart"
                label="Data Inicial"
                value={dataInicial}
                onChange={(e) => setDataInicial(e.target.value)}
              />
            </section>
            <section>
              <DatePicker
                id="dateFinish"
                label="Data Final"
                value={dataFinal}
                onChange={(e) => setDataFinal(e.target.value)}
              />
            </section>
            {/* <div className={styles.loading}>
              <Loading display={showLoading ? 'block' : 'none'} />
            </div> */}
            <section className={styles.sectionButton}>
              {
                <Button
                  onClick={getListaRelatorio}
                  // display={showLoading || showLink ? 'none' : 'block'}
                  title="Pesquisar"
                  type="submit"
                />
              }
            </section>
            <div className={styles.campanha}>

              <h1>Granel</h1>
              <div className={styles.loading}>
              <Loading display={showLoading ? 'block' : 'none'} />
            </div>

              {grupo.map((values, index) => (

                <ol>

                  <ul>
                    <li>
                      <strong>{values?.descricao}</strong>: {values?.valor}
                    </li>

                  </ul>
                </ol>
              ))}
            </div>

            <div className={styles.campanha}>
              <h1>Garrafa</h1>
              <div className={styles.loading}>
              <Loading display={showLoading ? 'block' : 'none'} />
            </div>

              {garrafa.map((values, index) => (

                <ol>

                  <ul>
                    <li>
                      <strong>{values?.descricao}</strong>: {values?.valor}
                    </li>

                  </ul>
                </ol>
              ))}
            </div>
            <div>
              <h1>Coletores</h1>
              {coletores.map((values, index) => (

                <ol>

                  <ul>
                    <li>
                      <strong>{values?.descricao}</strong>: {values?.valor}
                    </li>

                  </ul>
                </ol>
              ))}
            </div>
            <div className={styles.loading}>
              <Loading display={showLoading ? 'block' : 'none'} />
            </div>
          </form>
        </div>
      </div>
    </Container>
  )
}
