import styles from './Usuarios.module.css'
import Container from '../../../components/components-ui/Container/Container'
import Button from '../../../components/components-ui/Button/Button'
import api from '../../../services/Api'
import {Grid, Col} from '../../../components/Grid'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import 'react-toastify/dist/ReactToastify.css'
import {useHistory, useParams} from 'react-router'
import {ToastContainer, toast} from 'react-toastify'
import {useEffect, useState} from 'react'

export default function Action() {
  const {id} = useParams()
  const [nomeUsuario, setNomeUsuario] = useState('')

  function showAlert(notify, msg) {
    if (notify === 0) {
      toast.success(msg)
    } else {
      toast.error(msg)
    }
  }

  async function createUsuario(values) {
    const response = await api.post('/bombona/create/', {
      bombona: values.bombona,
    })
    if (response.data.error === 0) {
      showAlert(0, response.data.msg)
      return
    }
    showAlert(1, response.data.msg)
  }

  async function editarUsuario(values) {
    const response = await api.put(`/bombona/edit/${id}`, {
      bombona: values.bombona,
    })
    if (response.data.error === 0) {
      showAlert(0, response.data.msg)
      return
    }
    showAlert(1, response.data.msg)
  }

  useEffect(() => {
    async function getNomeUsuario() {
      const response = await api.get(`/usuario/show/${id}`)
      setNomeUsuario(response.data.bombona)
    }
    getNomeUsuario()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const history = useHistory()
  const validadtionSchema = Yup.object().shape({
    nomeUsuario: Yup.string().required('*Campo Obrigatório'),
  })
  return (
    <Container>
      <ToastContainer />
      <h3> {id ? 'Edição de Usuario' : 'Cadastro de Usuario'} </h3>
      <section className={styles.action_section}>
        <Formik
          enableReinitialize={true}
          initialValues={{nomeUsuario: nomeUsuario}}
          onSubmit={
            id
              ? (values) => editarUsuario(values)
              : (values) => createUsuario(values)
          }
          validationSchema={validadtionSchema}
        >
          {({errors, touched}) => (
            <Form>
              <Grid>
                <Col md={12}>
                  <label> Usuario </label>
                  <Field type="text" name="nomeUsuario" />
                  {errors.nomeUsuario && touched.nomeUsuario ? (
                    <span className={styles.alertError}>
                      {errors.nomeUsuario}
                    </span>
                  ) : null}
                </Col>
              </Grid>
              <Grid>
                <Col md={12}>
                  <Button type="submit" title={!id ? 'Salvar' : 'Editar'} />
                  <Button
                    type="button"
                    title="Voltar"
                    color="secondary"
                    onClick={() => {
                      history.push('/usina/usuarios')
                    }}
                  />
                </Col>
              </Grid>
            </Form>
          )}
        </Formik>
      </section>
    </Container>
  )
}
