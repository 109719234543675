import styles from './ModalColeta.module.css'
import Container from '../../../../../../components/components-ui/Container/Container'

export default function ModalColeta() {
  return (
    <Container>
      <div className={styles.content}>
        <h3>Novo Roteiro</h3>
        <div className={styles.wrap_coleta}>
          <h4> Coleta 1 </h4>
          <div>
            <input type="checkbox" /> <label> Selecionar </label>
          </div>
        </div>
      </div>
    </Container>
  )
}
