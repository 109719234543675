import { Redirect, Switch } from 'react-router-dom'
import { useContext } from 'react'
import Privateroute from '../services/privatesroute'
import { AuthContext } from '../Provider/AuthContext'

//ID_TIPO 1
import SolicitacaoDeColeta from './SolicitacaoDeColeta/SolicitacaoDeColeta'
import ValidacaoDeColeta from './RotaUserLocal/ValidacaoDeColeta/ValidacaoDeColeta'
import ComprovanteDePagamento from './RotaUserLocal/ComprovanteDePagamento/ComprovanteDePagamento'
import AtualizarConta from './RotaUserLocal/AtualizarConta/AtualizarConta'
import RelatorioDeColeta from './RotaUserLocal/RelatorioDeColeta/RelatorioDeColeta'
import Certificado from './RotaUserLocal/Certificado/Certificado'
import GestaoDeLicenca from './GestaoDeLicenca/GestaoDeLicenca'
import NovaColeta from './RotaUserLocal/subPages/NovaColeta/NovaColeta'
import AcessoMtr from './RotaUserLocal/DropdownControleMTR/AcessoMTR'
import Cdf from './RotaUserLocal/DropdownControleMTR/CDF'
import MeusMtr from './RotaUserLocal/DropdownControleMTR/MeusMTR'

// ID_TIPO 3
import RelatorioDeColetaAdmin from './RotasAdmin/RelatorioDeColetaAdmin/RelatorioDeColetaAdmin'
import WellcomeAdmin from './RotasAdmin/Wellcome'

// ID_TIPO 4 || PREPOSTO 0
import RoteirosDisponiveis from './RotasMotorista/RoteirosDisponiveis'
import ListagemDeColetas from './RotasMotorista/ListagemDeColetas'
import DetalhesColeta from './RotasMotorista/DetalhesColeta'
import Relatorio from './Preposto/RotasMotorista/Relatorios/EmitidoVsRealizado/RelatorioDeColeta'

// ID_TIPO 4 || PREPOSTO 1
import ListagemDeColetasPreposto from './Preposto/RotasMotorista/ListagemDeColetas'
import DetalhesColetaPreposto from './Preposto/RotasMotorista/DetalhesColeta'
import Roteiros from './Preposto/RotasMotorista/Roteiros'
import NovoRoteiro from './Preposto/RotasMotorista/NovoRoteiro'
// ID_TIPO 5
//Rota Principal
import WellcomeUsina from './RotasUsina/Wellcome'

//Links sem dropdown
import Usuarios from './RotasUsina/Usuarios'
import UsuariosAction from './RotasUsina/Usuarios/Action'
//Dropdown Cadastro
import Embaixador from './RotasUsina/Cadastro/Embaixador'
import EmbaixadorAction from './RotasUsina/Cadastro/Embaixador/Action'
import Prospect from './RotasUsina/Cadastro/Prospect'
import Local from './RotasUsina/Cadastro/Locais'
import TabsLocal from './RotasUsina/Cadastro/Locais/Tabs1'
// import TabsLocal from './RotasUsina/Cadastro/Locais/Tabs/Action'
import Preposto from './RotasUsina/Cadastro/Preposto'
import PrepostoAction from './RotasUsina/Cadastro/Preposto/Action'
// import LocalAction from './RotasUsina/Cadastro/Locais/Action'

//Dropdown Ligação
import PainelDeLigacao from './RotasUsina/Ligacao/PainelDeLigacao'
import DashLigacao from './RotasUsina/Ligacao/DashLigacao'

// Dropdow Coletas
import EmissaoDeColeta from './RotasUsina/Coletas/EmissaoDeColeta'
// import EmissaoDeColetaAdmin from './RotasUsina/Coletas/EmissaoDeColeta/Action'
import TabsColeta from './RotasUsina/Coletas/EmissaoDeColeta/Tabs'

import Ocorrencia from './RotasUsina/Coletas/Ocorrencia'
import OcorrenciaAdmin from './RotasUsina/Coletas/Ocorrencia/Action'

//Dropdown Material
import Grupo from './RotasUsina/Tabelas/Grupo'
import GrupoAction from './RotasUsina/Tabelas/Grupo/Action'

import Material from './RotasUsina/Tabelas/Material'
import MaterialAction from './RotasUsina/Tabelas/Material/Action'

import Campanha from './RotasUsina/Tabelas/Campanha'
import CampanhaAction from './RotasUsina/Tabelas/Campanha/Action'

import Brinde from './RotasUsina/Tabelas/Brinde'
import BrindeAction from './RotasUsina/Tabelas/Brinde/Action'

import Bombona from './RotasUsina/Tabelas/Bombona'
import BombonaAction from './RotasUsina/Tabelas/Bombona/Action'

import Estabelecimento from './RotasUsina/Tabelas/Estabelecimento'
import EstabelecimentoAction from './RotasUsina/Tabelas/Estabelecimento/Action'

import PreferenciaContato from './RotasUsina/Tabelas/PreferenciaContato'
import PreferenciaContatoAction from './RotasUsina/Tabelas/PreferenciaContato/Action'

import CadastrarMotorista from './RotasUsina/Tabelas/Motorista'

import Cidade from './RotasUsina/Tabelas/Cidade'
import CidadeAction from './RotasUsina/Tabelas/Cidade/Action'

import TipoLogradouro from './RotasUsina/Tabelas/TipoLogradouro'
import TipoLogradouroAction from './RotasUsina/Tabelas/TipoLogradouro/Action'

import Veiculo from './RotasUsina/Tabelas/Veiculo'
import VeiculoAction from './RotasUsina/Tabelas/Veiculo/Action'

// Rota Generica
import AtualizarUsuario from './AtualizarUsuario/AtualizarUsuario'

import PainelAdm from './RotasAdmin/Painel/index'

import MotoristaRelatorioPagamento from './RotasMotorista/RelatorioPagamento/index'
import RotaValidatKm from './RotasMotorista/RoteiroValidarKm/index'



//

export default function Routes() {
  const { idTipoUsuario, preposto } = useContext(AuthContext)
  let rotasUsuarioTipo1 = {}
  let rotasUsuarioTipo3 = {}
  let rotasUsuarioTipo4 = {}
  let rotasUsuarioTipo5 = {}
  // Valida rota pelo preposto
  if (preposto === 0) {
    rotasUsuarioTipo1 = [
      {
        component: SolicitacaoDeColeta,
        path: '/home/',
      },
      // {
      //   component: ValidacaoDeColeta,
      //   path: '/home/validacao-de-coleta',
      // },
      {
        component: ComprovanteDePagamento,
        path: '/home/comprovante-de-pagamento',
      },
      {
        component: AtualizarConta,
        path: '/home/atualizar-conta',
      },
      {
        component: RelatorioDeColeta,
        path: '/home/relatorio-de-coleta',
      },
      {
        component: Certificado,
        path: '/home/certificado',
      },
      {
        component: GestaoDeLicenca,
        path: '/home/gestao-de-licenca',
      },
      {
        component: AtualizarUsuario,
        path: '/home/atualizar-usuario',
      },
      {
        component: NovaColeta,
        path: '/home/nova-coleta',
      },
      {
        component: AcessoMtr,
        path: '/home/acesso-mtr',
      },
      {
        component: Cdf,
        path: '/home/cdf',
      },
      {
        component: MeusMtr,
        path: '/home/meus-mtr',
      },
    ]
    rotasUsuarioTipo3 = [
      {
        component: WellcomeAdmin,
        path: '/admin/',
      },
      {
        component: GestaoDeLicenca,
        path: '/admin/gestao-de-licenca',
      },
      {
        component: PainelAdm,
        path: '/admin/painel',
      },
      {
        component: RelatorioDeColetaAdmin,
        path: '/admin/relatorio-de-coleta/',
      },
      {
        component: AtualizarUsuario,
        path: '/admin/atualizar-usuario',
      },
    ]
    rotasUsuarioTipo4 = [
      {
        component: RoteirosDisponiveis,
        path: '/motorista/',
      },
      {
        component: MotoristaRelatorioPagamento,
        path: '/motorista/relatoriopagamento',
      },
      {
        component: ListagemDeColetas,
        path: '/motorista/listagem-de-coletas/:id',
      },
      {
        component: DetalhesColeta,
        path: '/motorista/detalhe-coleta/:id',
      },
      {
        component: AtualizarUsuario,
        path: '/motorista/atualizar-usuario',
      },
      {
        component: RotaValidatKm,
        path: '/motorista/validarkminicial',
      },
    ]
    rotasUsuarioTipo5 = [
      //Rota Principal
      {
        component: WellcomeUsina,
        path: '/usina/',
      },
      //Links sem dropdowns
      {
        component: Usuarios,
        path: '/usina/usuarios',
      },
      {
        component: UsuariosAction,
        path: '/usina/usuarios/novo/',
      },
      {
        component: UsuariosAction,
        path: '/usina/usuarios/edit/:id',
      },
      //Dropdown Cadastro
      {
        component: Embaixador,
        path: '/usina/embaixador',
      },
      {
        component: EmbaixadorAction,
        path: '/usina/embaixador/novo/',
      },
      {
        component: EmbaixadorAction,
        path: '/usina/embaixador/edit/:id',
      },
      {
        component: TabsLocal,
        path: '/usina/action-painel/edit/:id',
      },
      {
        component: TabsLocal,
        path: '/usina/action-painel/',
      },
      {
        component: Prospect,
        path: '/usina/prospect',
      },
      {
        component: Local,
        path: '/usina/local',
      },
      {
        component: Preposto,
        path: '/usina/preposto',
      },
      {
        component: PrepostoAction,
        path: '/usina/preposto/novo',
      },
      {
        component: PrepostoAction,
        path: '/usina/preposto/edit/:id',
      },

      // {
      //   component: LocalAction,
      //   path: '/usina/local/novo/',
      // },
      // {
      //   component: LocalAction,
      //   path: '/usina/local/edit/:id',
      // },
      // Dropdow Ligação
      {
        component: PainelDeLigacao,
        path: '/usina/painel-de-ligacao',
      },
      {
        component: DashLigacao,
        path: '/usina/dash-ligacao',
      },
      // Dropdown Coletas
      {
        component: EmissaoDeColeta,
        path: '/usina/emissao-de-coleta',
      },
      // {
      //   component: EmissaoDeColetaAdmin,
      //   path: '/usina/emissao-de-coleta/novo',
      // },
      // {
      //   component: EmissaoDeColetaAdmin,
      //   path: '/usina/emissao-de-coleta/edit/:id',
      // },

      {
        component: TabsColeta,
        path: '/usina/action-painel-coleta/novo',
      },
      {
        component: TabsColeta,
        path: '/usina/action-painel-coleta/edit/:id',
      },

      {
        component: Ocorrencia,
        path: '/usina/ocorrencia',
      },
      {
        component: OcorrenciaAdmin,
        path: '/usina/ocorrencia/novo',
      },
      {
        component: OcorrenciaAdmin,
        path: '/usina/ocorrencia/edit/:id',
      },

      // Dropdown Tabela
      {
        component: Material,
        path: '/usina/material',
      },
      {
        component: MaterialAction,
        path: '/usina/material/novo/',
      },
      {
        component: MaterialAction,
        path: '/usina/material/edit/:id',
      },
      {
        component: Grupo,
        path: '/usina/grupo',
      },
      {
        component: GrupoAction,
        path: '/usina/grupo/novo/',
      },
      {
        component: GrupoAction,
        path: '/usina/grupo/edit/:id',
      },
      {
        component: Campanha,
        path: '/usina/campanha/',
      },
      {
        component: CampanhaAction,
        path: '/usina/campanha/novo',
      },
      {
        component: CampanhaAction,
        path: '/usina/campanha/edit/:id',
      },
      {
        component: Brinde,
        path: '/usina/brinde/',
      },
      {
        component: BrindeAction,
        path: '/usina/brinde/novo',
      },
      {
        component: BrindeAction,
        path: '/usina/brinde/edit/:id',
      },
      {
        component: Bombona,
        path: '/usina/bombona/',
      },
      {
        component: BombonaAction,
        path: '/usina/bombona/novo/',
      },
      {
        component: BombonaAction,
        path: '/usina/bombona/edit/:id',
      },
      {
        component: AtualizarUsuario,
        path: '/usina/atualizar-usuario',
      },

      {
        component: CadastrarMotorista,
        path: '/usina/cadastrar-motorista',
      },

      {
        component: Estabelecimento,
        path: '/usina/estabelecimento',
      },
      {
        component: EstabelecimentoAction,
        path: '/usina/estabelecimento/novo',
      },
      {
        component: EstabelecimentoAction,
        path: '/usina/estabelecimento/edit/:id',
      },

      {
        component: Cidade,
        path: '/usina/cidade',
      },
      {
        component: CidadeAction,
        path: '/usina/cidade/novo',
      },
      {
        component: CidadeAction,
        path: '/usina/cidade/edit/:id',
      },
      {
        component: PreferenciaContato,
        path: '/usina/preferencia-contato',
      },
      {
        component: PreferenciaContatoAction,
        path: '/usina/preferencia-contato/novo',
      },
      {
        component: PreferenciaContatoAction,
        path: '/usina/preferencia-contato/edit/:id',
      },
      {
        component: TipoLogradouro,
        path: '/usina/tipo-logradouro',
      },
      {
        component: TipoLogradouroAction,
        path: '/usina/tipo-logradouro/novo',
      },
      {
        component: TipoLogradouroAction,
        path: '/usina/tipo-logradouro/edit/:id',
      },

      {
        component: Veiculo,
        path: '/usina/veiculo',
      },
      {
        component: VeiculoAction,
        path: '/usina/veiculo/novo',
      },
      {
        component: VeiculoAction,
        path: '/usina/veiculo/edit/:id',
      },
    ]
  }
  if (preposto === 1) {
    rotasUsuarioTipo4 = [
      {
        component: ListagemDeColetasPreposto,
        path: '/prep/motorista',
      },
      {
        component: DetalhesColetaPreposto,
        path: '/prep/motorista/detalhe-coleta/:id',
      },
      {
        component: Roteiros,
        path: '/prep/motorista/roteiros',
      },
      {
        component: NovoRoteiro,
        path: '/prep/motorista/novo-roteiro',
      },
      {
        component: Relatorio,
        path: '/prep/motorista/relatorio',
      }
    ]
  }
  // Validação de rota
  let rotaUsuario = []
  if (idTipoUsuario === 1) {
    rotaUsuario = rotasUsuarioTipo1
  }
  if (idTipoUsuario === 3) {
    rotaUsuario = rotasUsuarioTipo3
  }
  if (idTipoUsuario === 4) {
    rotaUsuario = rotasUsuarioTipo4
  }
  if (idTipoUsuario === 5) {
    rotaUsuario = rotasUsuarioTipo5
  }
  return (
    <Switch>
      {rotaUsuario?.map((values, index) => (
        <Privateroute
          key={index}
          exact
          path={values?.path}
          component={values?.component}
        />
      ))}
      {rotaUsuario?.map((values, index) => (
        <Redirect key={index} to={values?.path} />
      ))}
    </Switch>
  )
}
