import styles from './EmissaoDeColeta.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import Loading from '../../../../components/components-ui/Loading/Loading'
import Button from '../../../../components/components-ui/Button/Button'
import { Table } from '../../../../components/Table'
import { Grid, Col } from '../../../../components/Grid'
import api from '../../../../services/Api'
import { InfoSeachContext } from '../../../../Provider/InfoSearch'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export default function EmissaoDeColeta() {
  const [listaColeta, setListaColeta] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [status, setStatus] = useState([])
  const [idEmissor, setIdEmissor] = useState([])
  const { setDataColetaSearch, dataColetaSearch } = useContext(InfoSeachContext)

  // LINKS
  const API_LISTA_DE_COLETA = '/coleta/index?page='
  const API_DADOS_STATUS = `/coleta/status/index`
  const API_LISTA_EMISSOR = `/usuario/view`
  //
  async function getListaDeColeta() {
    setShowLoading(true)
    setListaColeta([])
    const response = await api.get(
      `${API_LISTA_DE_COLETA}${currentPage}&id_status=1`
    )
    setTotalPages(response.data.totalPages)
    setListaColeta(response.data.data)
    setCurrentPage(response.data.currentPageNumber)
    setShowLoading(false)
  }
  async function getListaDeColetaFiltrada(values) {
    let data = `${
      values?.dataEmissaoDe ? `&data_emissao_de=${values?.dataEmissaoDe}` : ''
    }`
    data =
      data +
      `${
        values?.dataEmissaoAte
          ? `&data_emissao_ate=${values?.dataEmissaoAte}`
          : ''
      }`
    data =
      data +
      `${values?.numeroColeta ? `&id_coleta=${values?.numeroColeta}` : ''}`
    data = data + `${values?.local ? `&nome_local=${values?.local}` : ''}`
    data = data + `${values?.status ? `&id_status=${values?.status}` : ''}`
    data =
      data + `${values?.codigoLocal ? `&id_local=${values?.codigoLocal}` : ''}`
    data =
      data + `${values?.idEmissor ? `&id_emissor=${values?.idEmissor}` : ''}`

    // setCurrentPage(1)
    // getListaDeColetaFiltrada(data)
    // setFilterTable(data)
    setDataColetaSearch({
      dataEmissaoDe: values?.dataEmissaoDe,
      dataEmissaoAte: values?.dataEmissaoAte,
      numeroColeta: values?.numeroColeta,
      local: values?.local,
      status: values?.status,
      codigoLocal: values?.codigoLocal,
      idEmissor: values?.idEmissor,
    })
    setShowLoading(true)
    setListaColeta([])
    //
    const response = await api.get(`/coleta/index?page=${currentPage}${data}`)
    setTotalPages(response.data.totalPages)
    setListaColeta(response.data.data)
    setCurrentPage(response.data.currentPageNumber)
    setShowLoading(false)
  }
  // CARREGA DADOS DO FILTRO
  useEffect(() => {
    // Carrega Status
    async function getStatus() {
      const response = await api.get(API_DADOS_STATUS)
      setStatus(response.data.coleta_status)
    }
    getStatus()
    // Carrega ID emissor
    async function getIdEmissor() {
      const response = await api.get(API_LISTA_EMISSOR)
      setIdEmissor(response.data)
    }
    getIdEmissor()
  }, [API_DADOS_STATUS, API_LISTA_EMISSOR])

  // CARREGA DADOS DO FILTRO
  useEffect(() => {
    if (dataColetaSearch.length !== 0) {
      getListaDeColetaFiltrada(dataColetaSearch)
      return
    }
    getListaDeColeta()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  function previewPage() {
    if (currentPage === 1) {
      setCurrentPage(1)
    } else {
      setCurrentPage(currentPage - 1)
    }
  }
  function nextPage() {
    if (currentPage === totalPages) {
      setCurrentPage(totalPages)
    } else {
      setCurrentPage(currentPage + 1)
    }
  }

  const ValidationSchema = Yup.object().shape({
    numeroColeta: Yup.number(),
    dataEmissaoDe: Yup.string(),
    dataEmissaoAte: Yup.string(),
    codigoLocal: Yup.number(),
    local: Yup.string(),
    status: Yup.string(),
    idEmissor: Yup.string(),
  })
  // Colunas da tabela
  const columns = [
    {
      column: 'ID Coleta',
    },
    {
      column: 'Data Emissão',
    },
    {
      align: 'left',
      column: 'Nome Local',
    },
    {
      column: 'Etapa',
    },
    {
      column: 'Status',
    },
  ]
  return (
    <Container>
      <h3>Emissão de Coleta</h3>
      <div className={styles.content}>
        <Link to="/usina/action-painel-coleta/novo">
          <Button title="Novo" type="button" />
        </Link>
        <Formik
          initialValues={{
            numeroColeta: dataColetaSearch?.numeroColeta
              ? dataColetaSearch?.numeroColeta
              : '',
            dataEmissaoDe: dataColetaSearch?.dataEmissaoDe
              ? dataColetaSearch?.dataEmissaoDe
              : '',
            dataEmissaoAte: dataColetaSearch?.dataEmissaoAte
              ? dataColetaSearch?.dataEmissaoAte
              : '',
            codigoLocal: dataColetaSearch?.codigoLocal
              ? dataColetaSearch?.codigoLocal
              : '',
            local: dataColetaSearch?.local ? dataColetaSearch?.local : '',
            status: dataColetaSearch?.status ? dataColetaSearch?.status : 1,
            idEmissor: dataColetaSearch?.idEmissor
              ? dataColetaSearch?.idEmissor
              : '',
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values) => getListaDeColetaFiltrada(values)}
        >
          {({ errors, touched }) => (
            <Form>
              <section className={styles.wrap}>
                <div className={styles.filter_header}>
                  <strong>Filtros</strong>
                  <Button title="Procurar" type="submit" />
                </div>
                <Grid>
                  <Col md={1}>
                    <label>Nº Coleta</label>
                    <Field type="number" name="numeroColeta" />
                    {errors.numeroColeta && touched.numeroColeta ? (
                      <span className={styles.alertError}>
                        {errors.numeroColeta}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Data Emissão De</label>
                    <Field type="date" name="dataEmissaoDe" />
                    {errors.dataEmissaoDe && touched.dataEmissaoDe ? (
                      <span className={styles.alertError}>
                        {errors.dataEmissaoDe}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Data Emissão Até</label>
                    <Field type="date" name="dataEmissaoAte" />
                    {errors.dataEmissaoAte && touched.dataEmissaoAte ? (
                      <span className={styles.alertError}>
                        {errors.dataEmissaoAte}
                      </span>
                    ) : null}
                  </Col>

                  <Col md={1}>
                    <label>ID Local</label>
                    <Field type="number" name="codigoLocal" />
                    {errors.codigoLocal && touched.codigoLocal ? (
                      <span className={styles.alertError}>
                        {errors.codigoLocal}
                      </span>
                    ) : null}
                  </Col>

                  <Col md={2}>
                    <label>Nome local</label>
                    <Field type="text" name="local" />
                    {errors.local && touched.local ? (
                      <span className={styles.alertError}>{errors.local}</span>
                    ) : null}
                  </Col>

                  <Col md={2}>
                    <label>Status</label>
                    <Field component="select" name="status">
                      <option value="">Todos</option>
                      {status.map((values) => (
                        <option
                          key={values?.id_status}
                          value={values?.id_status}
                        >
                          {values?.status}
                        </option>
                      ))}
                    </Field>
                    {errors.status && touched.status ? (
                      <span className={styles.alertError}>{errors.status}</span>
                    ) : null}
                  </Col>

                  <Col md={2}>
                    <label>Emissor</label>
                    <Field component="select" name="idEmissor">
                      <option value="">Todos</option>
                      {idEmissor.map((values) => (
                        <option
                          key={values.id_usuario}
                          value={values.id_usuario}
                        >
                          {values.nome_usuario}
                        </option>
                      ))}
                    </Field>
                    {errors.idEmissor && touched.idEmissor ? (
                      <span className={styles.alertError}>
                        {errors.idEmissor}
                      </span>
                    ) : null}
                  </Col>
                </Grid>
              </section>
            </Form>
          )}
        </Formik>
        <Table edit columns={columns}>
          {listaColeta.map((values) => (
            <tr key={values?.id_coleta}>
              <td>
                <Link
                  to={`/usina/action-painel-coleta/edit/${values?.id_coleta}`}
                >
                  <i className="fas fa-pencil-alt"></i>
                </Link>
              </td>
              <td>{values?.id_coleta}</td>
              <td>{values?.data_emissao}</td>
              <td style={{ textAlign: 'left' }}>{values?.nome_local}</td>
              <td>{values?.etapa}</td>
              <td>{values?.status}</td>
            </tr>
          ))}
        </Table>
        <div className={styles.loading}>
          <Loading display={showLoading ? 'block' : 'none'} />
        </div>

        <div className={styles.table_pagination}>
          <div className={styles.table_pagination_buttons}>
            <Button
              disabled={currentPage === 1 ? true : false}
              onClick={() => previewPage()}
            >
              <i style={{ marginRight: 5 }} className="fas fa-chevron-left"></i>
              Voltar
            </Button>
            <span>
              {currentPage} De {totalPages}
            </span>

            <Button
              disabled={
                currentPage === totalPages || totalPages === 0 ? true : false
              }
              onClick={() => nextPage()}
            >
              Próxima
              <i style={{ marginLeft: 5 }} className="fas fa-chevron-right"></i>
            </Button>
          </div>
        </div>
      </div>
    </Container>
  )
}
