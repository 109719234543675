import styles from '../Tabelas.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import Button from '../../../../components/components-ui/Button/Button'
import api from '../../../../services/Api'
import { Grid, Col } from '../../../../components/Grid'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import { useParams, useHistory } from 'react-router'
import { useEffect, useState } from 'react'

function Action() {
  const [campanha, setCampanha] = useState('')

  const { id } = useParams()
  function showAlert(notify, msg) {
    if (notify === 0) {
      toast.success(msg)
    } else {
      toast.error(msg)
    }
  }

  async function createCampanha(values) {
    const response = await api.post('/campanha/create/', {
      campanha: values.nome_campanha,
    })
    if (response.data.error === 0) {
      showAlert(0, response.data.msg)
      return
    }
    showAlert(1, response.data.msg)
  }

  async function editarCampanha(values) {
    const response = await api.put(`/campanha/edit/${id}`, {
      campanha: values.nome_campanha,
    })
    if (response.data.error === 0) {
      showAlert(0, response.data.msg)
      return
    }
    showAlert(1, response.data.msg)
  }

  useEffect(() => {
    async function getCampanha() {
      const response = await api.get(`/campanha/show/${id}`)
      setCampanha(response.data.campanha)
    }
    getCampanha()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const history = useHistory()
  const validadtionSchema = Yup.object().shape({
    nome_campanha: Yup.string().required('*Campo Obrigatório'),
  })
  return (
    <Container>
      <ToastContainer />
      <h3> {id ? 'Edição de Campanha' : 'Cadastro de Campanha'} </h3>
      <section className={styles.action_section}>
        <Formik
          initialValues={{ nome_campanha: campanha }}
          enableReinitialize={true}
          onSubmit={
            id
              ? (values) => editarCampanha(values)
              : (values) => createCampanha(values)
          }
          validationSchema={validadtionSchema}
        >
          {({ errors, touched }) => (
            <Form>
              <Grid>
                <Col md={12}>
                  <label> Nome da Campanha </label>
                  <Field type="text" name="nome_campanha" />
                  {errors.nome_campanha && touched.nome_campanha ? (
                    <span className={styles.alertError}>
                      {errors.nome_campanha}
                    </span>
                  ) : null}
                </Col>
              </Grid>
              <Grid>
                <Col md={12}>
                  <Button type="submit" title={!id ? 'Salvar' : 'Editar'} />
                  <Button
                    type="button"
                    title="Voltar"
                    color="secondary"
                    onClick={() => {
                      history.push('/usina/campanha')
                    }}
                  />
                </Col>
              </Grid>
            </Form>
          )}
        </Formik>
      </section>
    </Container>
  )
}

export default Action
