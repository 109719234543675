import React from 'react';
import numeral from 'numeral';
import 'numeral/locales';

numeral.locale('pt-br');


const IUNumber = ({format, children}) =>{



numeral.locale('pt-br');
    return(
        <span>
           {numeral(children).format(format)}
            
        </span>
    )
}

export default IUNumber;
