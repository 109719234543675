import React from 'react'
import {isTokenExpired} from './auth'
import {Route, Redirect} from 'react-router'
const privateroute = (props) => {
  const isExpired = isTokenExpired()
  // const isLogged = !!localStorage.getItem('app-token')
  return isExpired ? <Redirect to="/" /> : <Route {...props} />
}

export default privateroute
