import mainStyle from './Main.module.css'
import Routes from '../../pages/Routes'
function Main() {
  return (
    <main className={mainStyle.main}>
      <Routes />
    </main>
  )
}

export default Main
