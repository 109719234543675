import styles from './Ocorrencia.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import Button from '../../../../components/components-ui/Button/Button'
import {Grid, Col} from '../../../../components/Grid'
import api from '../../../../services/Api'
import * as Yup from 'yup'
import {Formik, Form, Field} from 'formik'
import 'react-toastify/dist/ReactToastify.css'
import {ToastContainer, toast} from 'react-toastify'
import {useParams} from 'react-router'
import {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'

function Action() {
  const {id} = useParams()
  const [veiculo, setVeiculo] = useState([])
  const history = useHistory()

  function showAlert(notify, msg) {
    if (notify === 0) {
      toast.success(msg)
    } else {
      toast.error(msg)
    }
  }

  useEffect(() => {
    //Receber nomes dos veiculos
    async function getVeiculo() {
      const response = await api.get(`/veiculo/index`)
      setVeiculo(response.data)
    }
    getVeiculo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function createOcorrencia(values) {
    showAlert(0, 'Chegou')
    console.log(values)
  }

  const validadtionSchema = Yup.object().shape({
    codigoOcorrencia: Yup.date(),
    dataDaOcorrencia: Yup.date(),
    ordemDeColeta: Yup.string(),
    dataDaColeta: Yup.date(),
    dataLigacao: Yup.date(),
    codLocal: Yup.number(),
    nomeLocal: Yup.string(),
    telefone: Yup.string(),
    veiculo: Yup.string(),
    motorista: Yup.string(),
    emissor: Yup.string(),
    captador: Yup.string(),
    contatoLigacao: Yup.string(),
    responsavel: Yup.string(),
    descricao: Yup.string(),
    retorno: Yup.string(),
    dataRetorno: Yup.string(),
    ocorrenciaEncerrada: Yup.boolean(),
    renegada: Yup.boolean(),
  })

  return (
    <Container>
      <ToastContainer />
      <h3> {id ? 'Edição de Ocorrência' : 'Cadastro de Ocorrência'} </h3>
      <section className={styles.content}>
        <Formik
          initialValues={{
            codigoOcorrencia: '',
            dataDaOcorrencia: '',
            ordemDeColeta: '',
            dataDaColeta: '',
            dataLigacao: '',
            codLocal: '',
            nomeLocal: '',
            telefone: '',
            veiculo: '',
            motorista: '',
            emissor: '',
            captador: '',
            contatoLigacao: '',
            responsavel: '',
            descricao: '',
            retorno: '',
            dataRetorno: '',
            ocorrenciaEncerrada: '',
            renegada: '',
          }}
          enableReinitialize={true}
          onSubmit={(values) => createOcorrencia(values)}
          validationSchema={validadtionSchema}
        >
          {({errors, touched}) => (
            <Form>
              <Grid>
                <Col md={2}>
                  <label> Cód. Ocorrência </label>
                  <Field type="text" name="codigoOcorrencia" />
                  {errors.codigoOcorrencia && touched.codigoOcorrencia ? (
                    <span className={styles.alertError}>
                      {errors.codigoOcorrencia}
                    </span>
                  ) : null}
                </Col>
                <Col md={2}>
                  <label> Data da Ocorrência </label>
                  <Field type="date" name="dataDaOcorrencia"></Field>
                  {errors.dataDaOcorrencia && touched.dataDaOcorrencia ? (
                    <span className={styles.alertError}>
                      {errors.dataDaOcorrencia}
                    </span>
                  ) : null}
                </Col>
                <Col md={4}>
                  <label> Ordem de Coleta </label>
                  <Field name="ordemDeColeta" component="select">
                    <option value=""> Selecione </option>
                  </Field>
                  {errors.ordemDeColeta && touched.ordemDeColeta ? (
                    <span className={styles.alertError}>
                      {errors.ordemDeColeta}
                    </span>
                  ) : null}
                </Col>
                <Col md={2}>
                  <label> Data da Coleta </label>
                  <Field type="date" name="dataDaColeta"></Field>
                  {errors.dataDaColeta && touched.dataDaColeta ? (
                    <span className={styles.alertError}>
                      {errors.dataDaColeta}
                    </span>
                  ) : null}
                </Col>
                <Col md={2}>
                  <label> Data Ligação </label>
                  <Field type="date" name="dataLigacao"></Field>
                  {errors.dataLigacao && touched.dataLigacao ? (
                    <span className={styles.alertError}>
                      {errors.dataLigacao}
                    </span>
                  ) : null}
                </Col>
              </Grid>
              <Grid>
                <Col md={1}>
                  <label>Cod. Local</label>
                  <Field type="number" name="codLocal" />
                  {errors.codLocal && touched.codLocal ? (
                    <span className={styles.alertError}>{errors.codLocal}</span>
                  ) : null}
                </Col>
                <Col md={4}>
                  <label> Nome Local </label>
                  <Field type="text" name="nomeLocal" />
                  {errors.nomeLocal && touched.nomeLocal ? (
                    <span className={styles.alertError}>
                      {errors.nomeLocal}
                    </span>
                  ) : null}
                </Col>
                <Col md={3}>
                  <label> Telefone </label>
                  <Field type="text" name="telefone" />
                  {errors.telefone && touched.telefone ? (
                    <span className={styles.alertError}>{errors.telefone}</span>
                  ) : null}
                </Col>

                <Col md={2}>
                  <label> Veículo </label>
                  <Field name="veiculo" component="select">
                    <option value=""> Selecione </option>
                    {veiculo.map((values) => (
                      <option value={values?.id_veiculo}>
                        {values?.veiculo}
                      </option>
                    ))}
                  </Field>
                  {errors.veiculo && touched.veiculo ? (
                    <span className={styles.alertError}>{errors.veiculo}</span>
                  ) : null}
                </Col>

                <Col md={2}>
                  <label> Motorista </label>
                  <Field name="motorista" component="select">
                    <option value=""> Selecione </option>
                  </Field>
                  {errors.motorista && touched.motorista ? (
                    <span className={styles.alertError}>
                      {errors.motorista}
                    </span>
                  ) : null}
                </Col>
              </Grid>
              <Grid>
                <Col md={3}>
                  <label> Emissor </label>
                  <Field name="emissor" component="select">
                    <option value=""> Selecione </option>
                  </Field>
                  {errors.emissor && touched.emissor ? (
                    <span className={styles.alertError}>{errors.emissor}</span>
                  ) : null}
                </Col>

                <Col md={3}>
                  <label> Captador </label>
                  <Field name="captador" component="select">
                    <option value=""> Selecione </option>
                  </Field>
                  {errors.captador && touched.captador ? (
                    <span className={styles.alertError}>{errors.captador}</span>
                  ) : null}
                </Col>

                <Col md={3}>
                  <label> Contato Ligação </label>
                  <Field type="text" name="contatoLigacao" />
                  {errors.contatoLigacao && touched.contatoLigacao ? (
                    <span className={styles.alertError}>
                      {errors.contatoLigacao}
                    </span>
                  ) : null}
                </Col>

                <Col md={3}>
                  <label> Responsável </label>
                  <Field name="responsavel" component="select">
                    <option value=""> Selecione </option>
                  </Field>
                  {errors.responsavel && touched.responsavel ? (
                    <span className={styles.alertError}>
                      {errors.responsavel}
                    </span>
                  ) : null}
                </Col>
              </Grid>

              <Grid>
                <Col md={12}>
                  <label>Descrição</label>
                  <Field component="textarea" name="descricao"></Field>
                </Col>
              </Grid>
              <Grid>
                <Col md={12}>
                  <label>Retorno</label>
                  <Field component="textarea" name="retorno"></Field>
                </Col>
              </Grid>
              <Grid>
                <Col md={2}>
                  <label> Data Retorno </label>
                  <Field type="text" name="dataRetorno" />
                  {errors.dataRetorno && touched.dataRetorno ? (
                    <span className={styles.alertError}>
                      {errors.dataRetorno}
                    </span>
                  ) : null}
                </Col>
                <Col md={6}>
                  <label>Ação</label>
                  <div>
                    <Field
                      type="checkbox"
                      name="ocorrenciaEncerrada"
                      id="ocorrenciaEncerrada"
                    />
                    <label htmlFor="ocorrenciaEncerrada">
                      Ocorrência Encerrada
                    </label>
                  </div>
                  <div>
                    <Field type="checkbox" name="renegada" id="renegada" />
                    <label htmlFor="renegada"> Renegada </label>
                  </div>
                </Col>
              </Grid>
              <section className={styles.section_button}>
                <Button title="Salvar" type="submit" />
                <Button
                  type="button"
                  color="secondary"
                  title="Voltar"
                  onClick={() => {
                    history.push('/usina/ocorrencia')
                  }}
                />
              </section>
            </Form>
          )}
        </Formik>
      </section>
    </Container>
  )
}

export default Action
